import React, { useState } from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { CompanyLogo } from '../../../components/pro/project/CompanyLogo';
import { TemplateCompany } from '../../../components/pro/project/TemplateCompany';
import Tabs, {
  TabItem,
  TabItemManager,
  TabsStyles,
} from '../../../components/nav/Tabs';
import { CoachesTable } from '../../../components/pro/project/CoachesTable';
import { Checklist } from '../../../components/task/Checklist';
import { IllustratedExplanationMessage } from '../../../components/generic/IllustratedExplanationMessage';
import loginIllustration from '../../../assets/images/illustrations/login.png';
import { CreateChecklistButton } from '../../../components/task/CreateChecklistButton';

const enum TEMPLATE_TABS {
  METHODOLOGY = 'METHODOLOGY',
  SETTINGS = 'SETTINGS',
  USERS = 'USERS',
}

export function ProProjectTemplateScreen() {
  const proContext = useProContext();
  const tabs: TabItem[] = [
    { key: TEMPLATE_TABS.METHODOLOGY, name: 'Méthodologie' },
    { key: TEMPLATE_TABS.USERS, name: 'Administrateurs' },
    { key: TEMPLATE_TABS.SETTINGS, name: 'Paramètres' },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };
  return (
    <>
      <PageTitle title="Gestion du modèle de projet" />
      <div className="py-12 px-16">
        <ProTemplateHeader />
      </div>

      <div className="px-16 border-b border-gray-100 w-full">
        <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
      </div>
      <>
        {currentTab.key === TEMPLATE_TABS.METHODOLOGY && (
          <ProTemplateChecklist />
        )}
        {currentTab.key === TEMPLATE_TABS.SETTINGS && (
          <div className="w-full py-8 px-16 max-w-7xl">
            {proContext?.company && (
              <TemplateCompany company={proContext.company} />
            )}
          </div>
        )}
        {currentTab.key === TEMPLATE_TABS.USERS && (
          <div className="w-full py-8 px-16 max-w-7xl">
            {proContext?.company && (
              <CoachesTable company={proContext.company} />
            )}
          </div>
        )}
      </>
    </>
  );
}

function ProTemplateChecklist() {
  const proContext = useProContext();

  if (!proContext?.company?.checklist?.id) {
    return (
      <div className="grow w-full py-8 px-16">
        <IllustratedExplanationMessage
          illustration={
            <img className="w-1/2" src={loginIllustration} alt="" />
          }
          title="Guidez vos projets avec une liste de tâches"
          description="Créez une liste de tâches pour guider vos collaborateurs dans la réalisation de leurs projets."
        >
          <CreateChecklistButton companyId={proContext?.company?.id || ''} />
        </IllustratedExplanationMessage>
      </div>
    );
  }

  return (
    <div className="grow bg-gray-50 w-full py-8 px-16">
      {proContext?.company?.checklist?.id && (
        <div className="max-w-4xl">
          <Checklist checklistId={proContext.company.checklist.id} />
        </div>
      )}
    </div>
  );
}

function ProTemplateHeader() {
  const proContext = useProContext();
  return (
    <div className="flex items-center gap-4 justify-start">
      {proContext?.company && (
        <CompanyLogo companyId={proContext.company.id} size={'big'} />
      )}
      <div>
        <div className="text-gray-500 first-letter:capitalize text-sm">
          {new Date().toLocaleDateString('fr', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })}{' '}
          · Tableau de bord {proContext?.company?.name}
        </div>
        {
          <h1 className="text-5xl">
            Gestion du modèle de projet {proContext?.company?.name}
          </h1>
        }
      </div>
    </div>
  );
}
