import React from 'react';
import { sanitizeTypeformString } from '../../../../services/SurveyService';
import { ExportableToImage } from '../../../generic/ExportableToImage';
import { useProContext } from '../../../../providers/ProContextProvider';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { ChoicesBarChart } from './ChoicesBarChart';
import {
  MultipleChoiceChart_AnswerFragment,
  MultipleChoiceChart_QuestionFragment,
} from '../../../../graphql/generated';

type Choice = {
  label: string;
  count: number;
};

export function MultipleChoiceChart({
  question,
  answers,
  isThumbnail,
}: {
  question: MultipleChoiceChart_QuestionFragment;
  answers: MultipleChoiceChart_AnswerFragment[];
  isThumbnail?: boolean;
}) {
  const proContext = useProContext();
  const currentUser = useCurrentUser();

  // Detect if single or multiple choice
  const isMultipleChoice = detectIfIsMultipleChoice(question);

  const availableChoices: Choice[] = isMultipleChoice
    ? getAvailableMultipleChoices(question, answers)
    : getAvailableSingleChoice(question, answers);

  const otherChoices: Choice[] = isMultipleChoice
    ? getOtherMultipleChoices(answers)
    : getOtherSingleChoice(answers);

  const sumOfAllCounts = getSumOfAllCounts(answers);

  return (
    <div className="bg-white rounded-2xl p-4 shadow-sm relative">
      {isThumbnail ? (
        <ChoicesBarChart
          availableChoices={availableChoices}
          otherChoices={otherChoices}
          sumOfAllCounts={sumOfAllCounts}
        />
      ) : (
        <ExportableToImage name="Choix multiples">
          <div className="font-bold mb-4 w-11/12">
            {sanitizeTypeformString(question.title, proContext, currentUser)}
          </div>
          <ChoicesBarChart
            availableChoices={availableChoices}
            otherChoices={otherChoices}
            sumOfAllCounts={sumOfAllCounts}
          />
        </ExportableToImage>
      )}

      {!isThumbnail && otherChoices.length > 0 && (
        <div className="rounded-xl bg-gray-50 p-4 mt-8">
          <div className="font-bold text-sm mb-1">
            Détail des réponses "Autre"
          </div>
          <ul className="flex flex-col gap-2 py-2">
            {otherChoices.map((choice, index) => (
              <li
                className="px-4 py-2 text-sm text-gray-900 bg-white rounded-xl shadow-sm"
                key={`other_${index}`}
              >
                {choice.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

function getSumOfAllCounts(
  answers: MultipleChoiceChart_AnswerFragment[],
): number {
  return (answers || []).length;
}

function detectIfIsMultipleChoice(
  question: MultipleChoiceChart_QuestionFragment,
): boolean {
  return question.canSelectMultipleChoices || false;
}

function getAvailableMultipleChoices(
  question: MultipleChoiceChart_QuestionFragment,
  answers: MultipleChoiceChart_AnswerFragment[],
): Choice[] {
  return (
    question.choices
      ?.map((questionChoice) => ({
        label: questionChoice.label,
        count:
          answers?.filter((answer) =>
            answer.choices?.some(
              (answerChoice) => answerChoice.id === questionChoice.id,
            ),
          ).length || 0,
      }))
      // Sort by count
      .toSorted((a, b) => b.count - a.count) || []
  );
}

function getAvailableSingleChoice(
  question: MultipleChoiceChart_QuestionFragment,
  answers: MultipleChoiceChart_AnswerFragment[],
): Choice[] {
  return (
    question.choices
      ?.map((questionChoice) => ({
        label: questionChoice.label,
        count:
          answers?.filter((answer) => answer.choice?.id === questionChoice.id)
            .length || 0,
      }))
      // Sort by count
      .toSorted((a, b) => b.count - a.count) || []
  );
}

function getOtherMultipleChoices(
  answers: MultipleChoiceChart_AnswerFragment[],
): Choice[] {
  return (answers || [])
    .filter((answer) => answer.comment)
    .map((answer) => ({
      label: answer.comment || '',
      count: 1,
    }));
}

function getOtherSingleChoice(
  answers: MultipleChoiceChart_AnswerFragment[],
): Choice[] {
  return (answers || [])
    .filter((answer) => answer.comment)
    .map((answer) => ({
      label: answer.comment || '',
      count: 1,
    }));
}
