import React, { useState } from 'react';
import { FinancialMaterialityMatrix } from '../../../../components/pro/publications/doubleMateriality/FinancialMaterialityMatrix';
import { ImpactMaterialityMatrix } from '../../../../components/pro/publications/doubleMateriality/ImpactMaterialityMatrix';
import Tabs, {
  TabItem,
  TabItemManager,
  TabsStyles,
} from '../../../../components/nav/Tabs';
import { MaterialityTypes } from '../../../../graphql/generated';
import { UtopiesStakesRankings } from '../../../../components/pro/publications/doubleMateriality/custom/UtopiesStakesRankings';
import { UtopiesStakesQuality } from '../../../../components/pro/publications/doubleMateriality/custom/UtopiesStakesQuality/UtopiesStakesQuality';
import { MatrixProvider } from '../../../../components/pro/publications/doubleMateriality/MatrixContext';

const enum DOUBLE_MATERIALITY_TABS {
  QUALITY_DATAVIZ_RISKS_OPPORTUNITIES = 'QUALITY_DATAVIZ_RISKS_OPPORTUNITIES',
  QUALITY_DATAVIZ_IMPACTS = 'QUALITY_DATAVIZ_IMPACTS',
  IMPORTANCE_PROBABILITY_MATRIX = 'IMPORTANCE_PROBABILITY_MATRIX',
  IMPACT_PROBABILITY_MATRIX = 'IMPACT_PROBABILITY_MATRIX',
  STAKES_RANKINGS = 'STAKES_RANKINGS',
}

export function UtopiesProDoubleMaterialityMatrixScreen() {
  const tabs: TabItem[] = [
    {
      key: DOUBLE_MATERIALITY_TABS.IMPORTANCE_PROBABILITY_MATRIX,
      name: 'Matérialité financière',
    },
    {
      key: DOUBLE_MATERIALITY_TABS.IMPACT_PROBABILITY_MATRIX,
      name: "Matérialité d'impact",
    },
    {
      key: DOUBLE_MATERIALITY_TABS.QUALITY_DATAVIZ_RISKS_OPPORTUNITIES,
      name: 'Risque / Opportunité',
    },
    {
      key: DOUBLE_MATERIALITY_TABS.QUALITY_DATAVIZ_IMPACTS,
      name: 'Impacts +/-',
    },
    {
      key: DOUBLE_MATERIALITY_TABS.STAKES_RANKINGS,
      name: "Classements d'enjeux",
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="main-content pt-8 w-full pb-16">
      <div className="space-y-8">
        <div>
          <div className="flex justify-between items-center border-b border-gray-100">
            <h2>Détails de l'analyse</h2>
            <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
          </div>
        </div>
        <div className="min-h-[800px]">
          <MatrixProvider initialStakeholderSegments={[]}>
            <Tab currentTab={currentTab} />
          </MatrixProvider>
        </div>
      </div>
    </div>
  );
}

function Tab({ currentTab }: { currentTab: TabItem }) {
  switch (currentTab.key) {
    case DOUBLE_MATERIALITY_TABS.QUALITY_DATAVIZ_IMPACTS:
      return <UtopiesStakesQuality materialityType={MaterialityTypes.Impact} />;
    case DOUBLE_MATERIALITY_TABS.QUALITY_DATAVIZ_RISKS_OPPORTUNITIES:
      return (
        <UtopiesStakesQuality materialityType={MaterialityTypes.Financial} />
      );
    case DOUBLE_MATERIALITY_TABS.IMPORTANCE_PROBABILITY_MATRIX:
      return <FinancialMaterialityMatrix />;
    case DOUBLE_MATERIALITY_TABS.IMPACT_PROBABILITY_MATRIX:
      return <ImpactMaterialityMatrix />;
    case DOUBLE_MATERIALITY_TABS.STAKES_RANKINGS:
      return <UtopiesStakesRankings />;
  }
  return null;
}
