import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import React from 'react';
import { CmsComponentGuidance_GuidanceFragment } from '../../../graphql/cms/generated';
import { BlocksRenderer } from '../renderers/BlocksRenderer';

export function Guidance({
  guidance,
}: {
  guidance: CmsComponentGuidance_GuidanceFragment;
}) {
  return (
    <MessageBox type={MessageBoxType.Info}>
      <BlocksRenderer content={guidance.content} />
    </MessageBox>
  );
}
