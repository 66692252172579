import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { PageTitle } from '../../../components/nav/PageTitle';
import React from 'react';
import { useCompanyQuery } from '../../../graphql/generated';
import { ProBreadcrumb } from '../../../components/nav/ProBreadcrumb';
import { ProCompany } from '../../../components/pro/project/ProCompany';

export function ProCompanyScreen() {
  const currentUser = useCurrentUser();

  // Get full company data
  const { data, loading } = useCompanyQuery({
    variables: {
      id: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.company) {
    return (
      <div className="">
        <PageTitle title="Gérer l'entreprise" />
        <ProBreadcrumb />
        <div className="max-w-5xl main-content">
          <ProCompany company={data.company} />
        </div>
      </div>
    );
  } else {
    return <LoaderFullscreen />;
  }
}
