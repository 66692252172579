import React from 'react';
import { VisiativLayout } from '../../components/layout/VisiativLayout';
import { VisiativIntegrationLoginScreen } from '../integrations/visiativ/VisiativIntegrationLoginScreen';
import { VisiativLoggedInOnlyGuard } from '../../guards/VisiativLoggedInOnlyGuard';
import { ContentOnlyLayout } from '../../components/layout/ContentOnlyLayout';
import { HomeScreen } from '../customer/HomeScreen';
import { SurveyListScreen } from '../customer/surveys/SurveyListScreen';
import { PrioritizationMatrixScreen } from '../customer/publications/PrioritizationMatrixScreen';
import { RoadmapScreen } from '../customer/publications/RoadmapScreen';
import { ActionScreen } from '../customer/ActionScreen';
import { SamiIntegrationLoginScreen } from '../integrations/sami/SamiIntegrationLoginScreen';

export const useIntegrationsRouteConfig = () => {
  return [
    {
      path: '/integrations',
      children: [
        // ====================
        // Sami integration routes
        // ====================
        {
          path: '/integrations/sami',
          element: <VisiativLayout />,
          children: [
            {
              path: '/integrations/sami/login',
              element: <SamiIntegrationLoginScreen />,
            },
          ],
        },
        // ====================
        // Visiativ integration routes
        // ====================
        {
          path: '/integrations/visiativ',
          element: <VisiativLayout />,
          children: [
            {
              path: '/integrations/visiativ/login',
              element: <VisiativIntegrationLoginScreen />,
            },
            {
              path: '/integrations/visiativ/login/:email',
              element: <VisiativIntegrationLoginScreen />,
            },
            {
              path: '/integrations/visiativ',
              element: (
                <VisiativLoggedInOnlyGuard>
                  <ContentOnlyLayout />
                </VisiativLoggedInOnlyGuard>
              ),
              children: [
                {
                  index: true,
                  element: <HomeScreen />,
                },
                {
                  path: '/integrations/visiativ/diag/survey',
                  breadcrumb: 'Consultations',
                  element: <SurveyListScreen />,
                },
                {
                  path: '/integrations/visiativ/diag/diagnostic',
                  breadcrumb: 'Diagnostic',
                  element: <PrioritizationMatrixScreen />,
                },
                {
                  path: '/integrations/visiativ/diag/roadmap',
                  children: [
                    {
                      index: true,
                      breadcrumb: 'Feuille de route',
                      element: <RoadmapScreen />,
                    },
                    {
                      path: '/integrations/visiativ/diag/roadmap/action/:actionId',
                      breadcrumb: null,
                      element: <ActionScreen />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
