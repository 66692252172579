import {
  RoadmapFieldsFragment,
  usePublishRoadmapMutation,
  useUnpublishRoadmapMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useModal } from '../../layout/Modal';
import resultIllustration from 'assets/images/illustrations/result.png';
import { EyeIcon, EyeStrikeThroughIcon } from '../../icons';

export function PublishRoadmapButton({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  const modal = useModal();
  const openPublishConfirmationModal = () => {
    modal.openModalWithComponent(
      <ConfirmPublicationModal roadmapId={roadmap.id} />,
    );
  };
  const openUnpublishConfirmationModal = () => {
    modal.openModalWithComponent(
      <ConfirmUnpublicationModal roadmapId={roadmap.id} />,
    );
  };

  return (
    <div className="flex items-center gap-2">
      {roadmap.publishedAt && (
        <button
          className="secondary purple"
          onClick={() => openUnpublishConfirmationModal()}
        >
          <EyeStrikeThroughIcon className="w-4 h-4" />
          <span>Dépublier</span>
        </button>
      )}

      {!roadmap.publishedAt && (
        <button
          className="primary purple"
          onClick={() => openPublishConfirmationModal()}
        >
          <EyeIcon className="w-4 h-4" />
          <span>Publier</span>
        </button>
      )}
    </div>
  );
}

function ConfirmPublicationModal({ roadmapId }: { roadmapId: string }) {
  const toast = useToast();
  const modal = useModal();
  const [publishRoadmap] = usePublishRoadmapMutation();
  const [isPublishing, setIsPublishing] = useState(false);
  const publish = () => {
    setIsPublishing(true);
    publishRoadmap({
      variables: {
        id: roadmapId,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Feuille de route publiée 🥳');
        modal.closeModal();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsPublishing(false));
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      <img src={resultIllustration} className="w-28" alt="" />
      <h2>Publier la feuille de route ?</h2>
      <p className="max-w-xl text-center">
        Une publiée, les clients y auront immédiatement accès. N'oubliez pas de
        les informer de cette publication !
      </p>
      <button
        className="primary purple"
        disabled={isPublishing}
        onClick={() => publish()}
      >
        {isPublishing ? <Loader /> : <EyeIcon className="w-4 h-4" />}
        <span>Publier</span>
      </button>
    </div>
  );
}

function ConfirmUnpublicationModal({ roadmapId }: { roadmapId: string }) {
  const toast = useToast();
  const modal = useModal();
  const [unpublishRoadmap] = useUnpublishRoadmapMutation();
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  const unpublish = () => {
    setIsUnpublishing(true);
    unpublishRoadmap({
      variables: {
        id: roadmapId,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Feuille de route dé-publiée');
        modal.closeModal();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsUnpublishing(false));
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      <img src={resultIllustration} className="w-28" alt="" />
      <h2>Dé-publier la feuille de route ?</h2>
      <p className="max-w-xl text-center">
        Dépublier la feuille de route la rendra inaccessible aux clients.
      </p>
      <button
        className="primary purple"
        disabled={isUnpublishing}
        onClick={() => unpublish()}
      >
        {isUnpublishing ? (
          <Loader />
        ) : (
          <EyeStrikeThroughIcon className="w-4 h-4" />
        )}
        <span>Dépublier</span>
      </button>
    </div>
  );
}
