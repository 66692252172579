import React from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ProBreadcrumb } from '../../../components/nav/ProBreadcrumb';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { ImportXlsFile } from '../../../components/pro/maturity/ImportXlsFile';

export function ProMaturityEvaluationImportScreen() {
  const proContext = useProContext();

  if (proContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  return (
    <>
      <PageTitle title="Import d'une évaluation de maturité" />
      <ProBreadcrumb />
      <div className="main-content w-full py-8 space-y-8">
        <ImportXlsFile />
      </div>
    </>
  );
}
