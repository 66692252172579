import React, { useMemo } from 'react';
import {
  ThemeIcon,
  useGroupedStakeholderSegmentDropdownQuery,
} from '../../../../graphql/generated';
import { ThemeIcon as ThemeIconComponent } from '../../../generic/icon/ThemeIcon';
import { useTranslation } from '@hooks/useTranslation';
import { GroupedStringDropdownItem } from '../../../generic/form/GroupedStringDropdown';

export const useGroupedStakeholderSegments = (companyId: string) => {
  const { translateProperty } = useTranslation();
  const { data, loading, error } = useGroupedStakeholderSegmentDropdownQuery({
    variables: {
      companyId,
      order: [
        { field: 'i18n.name', direction: 'ASC' },
        { field: 'segments.i18n.name', direction: 'ASC' },
      ],
    },
    skip: companyId === '',
    fetchPolicy: 'network-only',
  });

  const availableItems: GroupedStringDropdownItem[] = useMemo(() => {
    if (data?.stakeholderGroups) {
      return data.stakeholderGroups.flatMap(
        (group) =>
          group?.segments?.map((segment) => ({
            id: segment.id,
            label: translateProperty(segment, 'name'),
            group: {
              icon: (
                <ThemeIconComponent
                  themeIcon={group.icon || ThemeIcon.Collaborators}
                />
              ),
              name: translateProperty(group, 'name'),
            },
          })) ?? [],
      );
    }
    return [];
  }, [data, translateProperty]);

  return {
    availableItems,
    loading,
    error,
  };
};
