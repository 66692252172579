import React, { Fragment } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '../icons';
import { useRouteConfig } from '../../screens/routeConfigs/useRouteConfig';

export function Breadcrumb({
  currentPageTitle,
}: {
  currentPageTitle?: string;
}) {
  const breadcrumbs = useBreadcrumbs(useRouteConfig(), {
    excludePaths: ['/'],
  });

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-1 title-h5 items-center flex-wrap">
      {breadcrumbs.map(({ match, breadcrumb }, i) => {
        return i === breadcrumbs.length - 1 && !currentPageTitle ? (
          <span key={match.pathname}>{breadcrumb}</span>
        ) : (
          <Fragment key={match.pathname}>
            <NavLink to={match.pathname} className="text-gray-500">
              {breadcrumb}
            </NavLink>
            <ChevronRightIcon className="shrink-0" />
          </Fragment>
        );
      })}
      {currentPageTitle && <span>{currentPageTitle}</span>}
    </div>
  );
}
