import {
  ConsolidatedMaterialityArrays_StakeFragment,
  ConsolidatedMaterialityArrays_SurveyFragment,
  SurveyQuestionType,
} from '../../../../graphql/generated';
import React from 'react';
import { SimpleMaterialityQuestionsArray } from '../../../pro/publications/simpleMateriality/SimpleMaterialityQuestionsArray';
import { DoubleMaterialitySeverityArrays } from '../../../pro/publications/doubleMateriality/DoubleMaterialitySeverityArrays';

export function ConsolidatedMaterialityArrays({
  stake,
  surveys,
}: {
  stake: ConsolidatedMaterialityArrays_StakeFragment;
  surveys: ConsolidatedMaterialityArrays_SurveyFragment[];
}) {
  // Materiality can be:
  // - simple (SurveyQuestionType.Matrix)
  // - double (SurveyQuestionType.StakeDoubleMateriality)
  //   - financial
  //   - impact
  // - double for custom question types (for example: SurveyQuestionType.UtopiesStakeDoubleMateriality)
  //   - financial
  //   - impact

  const materialityArrays: React.ReactNode[] = [];

  const simpleMaterialityQuestionIds = surveys
    .flatMap((survey) => survey.questions)
    .filter((question) => question?.type === SurveyQuestionType.Matrix)
    .map((question) => question?.id || '');

  if (simpleMaterialityQuestionIds.length > 0) {
    materialityArrays.push(
      <SimpleMaterialityQuestionsArray
        stakes={[stake]}
        questionIds={simpleMaterialityQuestionIds}
      />,
    );
  }

  const doubleMaterialityQuestionIds = surveys
    .flatMap((survey) => survey.questions)
    .filter(
      (question) =>
        question &&
        [
          SurveyQuestionType.StakeDoubleMateriality,
          SurveyQuestionType.UtopiesStakeDoubleMateriality,
        ].includes(question.type),
    )
    .map((question) => question?.id || '');

  if (doubleMaterialityQuestionIds.length > 0) {
    materialityArrays.push(
      <DoubleMaterialitySeverityArrays
        stakes={[stake]}
        questionIds={doubleMaterialityQuestionIds}
      />,
    );
  }

  return <div className="space-y-4">{materialityArrays}</div>;
}
