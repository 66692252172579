import {
  GetPillarColorFragment,
  GetReferentialIllustrationFragment,
  MaturityLevel,
  PriorityLevel,
  ThemeColor,
} from '../graphql/generated';
import React from 'react';
import iso26000Logo from '../assets/images/iso26000.png';
import csrdLogo from '../assets/images/csrd.png';
import { LogoIcon } from '../components/icons';

export function getPillarColor(
  pillar: GetPillarColorFragment | null | undefined,
): string {
  return getThemeLightColor(pillar?.color);
}

export function getThemeLightColor(
  themeColor: ThemeColor | null | undefined,
): string {
  switch (themeColor) {
    case ThemeColor.Gray:
      return 'bg-gray-50';
    case ThemeColor.Green:
      return 'bg-green-50';
    case ThemeColor.Purple:
      return 'bg-purple-50';
    case ThemeColor.Blue:
      return 'bg-blue-50';
    case ThemeColor.Red:
      return 'bg-red-50';
    case ThemeColor.Yellow:
      return 'bg-yellow-50';
    default:
      return 'bg-gray-50';
  }
}

export function getMaturityLevelFromComputedValue(
  value: number | null | undefined,
): MaturityLevel | null {
  if (value === null || value === undefined) {
    return null;
  }
  if (value >= 4) {
    return MaturityLevel.High;
  }
  if (value >= 3) {
    return MaturityLevel.Medium;
  }
  if (value >= 2) {
    return MaturityLevel.Low;
  }
  if (value > 1) {
    return MaturityLevel.None;
  }
  return null;
}

export function getPriorityLevelFromComputedValue(
  computedValue: number,
): PriorityLevel | null {
  if (computedValue && computedValue >= 0) {
    const truncatedValue = Math.trunc(computedValue || 0);
    if (truncatedValue > 3) {
      return PriorityLevel.High;
    }
    if (truncatedValue > 2) {
      return PriorityLevel.Medium;
    }
    if (truncatedValue > 1) {
      return PriorityLevel.Low;
    }
    return PriorityLevel.None;
  }
  return null;
}

export function getReferentialIllustration(
  referential: GetReferentialIllustrationFragment,
) {
  const referentialOrigin = referential.origin;
  switch (referentialOrigin?.name) {
    case 'ISO 26000':
      return <img src={iso26000Logo} alt="Logo ISO 26000" />;
    case 'Efrag ESRS':
      return <img src={csrdLogo} alt="Logo CSRD" />;
    case 'Good Steps':
      return <LogoIcon className="w-14 h-14" />;
    default:
      return null;
  }
}
