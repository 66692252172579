import React from 'react';
import {
  Repository,
  StakeStandardTopicsPickerModal_CsrStandardTopicChildFragment,
  StakeStandardTopicsPickerModal_CsrStandardTopicFragment,
  StakeStandardTopicsPickerModal_CsrStandardTopicGrandChildFragment,
  StakeStandardTopicsPickerModal_StakeFragment,
  useStakeStandardTopicsPickerModal_UpdateMutation,
  useStakeStandardTopicsPickerModalQuery,
} from '../../../graphql/generated';
import { LoaderFullscreen } from '../../layout/Loader';
import { RepositoryIllustration } from './RepositoryIllustration';
import { Checkbox } from '../../form/Checkbox';
import { useModal } from '../../layout/Modal';
import { CsrStandardTopicTag } from './CsrStandardTopicTag';
import { RepositoryDropdown } from './RepositoryDropdown';
import { useTranslation } from '@hooks/useTranslation';
import { ChevronDownIcon } from '../../icons';
import clsx from 'clsx';

export function StakeStandardTopicsPickerModal({
  stake,
}: {
  stake: StakeStandardTopicsPickerModal_StakeFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();
  const query = useStakeStandardTopicsPickerModalQuery({
    variables: {
      type: Repository.EfragEsrs,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const initialExpandedIds =
        arrayOfItemsHavingChildrenOrGrandChildrenSelected(
          data.latestCsrStandardByType?.topics || [],
          selectedTopicIds,
        );
      setExpandedIds(initialExpandedIds);
    },
  });

  const [repository, setRepository] = React.useState<Repository>(
    Repository.EfragEsrs,
  );
  const pickRepository = (repo: Repository) => {
    setRepository(repo);
    query.refetch({ type: repo }).catch((e) => console.error(e));
  };

  const [updateStakeMutation] =
    useStakeStandardTopicsPickerModal_UpdateMutation();
  const updateStakeStandardTopics = () => {
    updateStakeMutation({
      variables: {
        input: {
          id: stake.id,
          csrStandardTopics: selectedTopicIds.map((id) => ({ id })),
        },
      },
    })
      .then(() => modal.closeModal())
      .catch((e) => console.error(e));
  };

  const [selectedTopicIds, setSelectedTopicIds] = React.useState<string[]>(
    (stake.csrStandardTopics || []).map((t) => t.id),
  );
  const [expandedIds, setExpandedIds] = React.useState<string[]>([]);

  const csrStandard = query.data?.latestCsrStandardByType;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <div className="w-8">
          <RepositoryIllustration repository={repository} />
        </div>
        <RepositoryDropdown
          initialRepository={repository}
          updateRepository={pickRepository}
        />
      </div>
      {query.loading ? (
        <LoaderFullscreen />
      ) : (
        csrStandard && (
          <ul className="space-y-1 max-h-[50vh] overflow-y-scroll">
            {(csrStandard?.topics || [])
              .filter((topic) => !topic.parent) // TODO: Remove this filter when the API returns only top level topics
              .map((topic) => (
                <StandardTopicItem
                  key={topic.id}
                  topic={topic}
                  selectedTopicIds={selectedTopicIds}
                  setSelectedTopicIds={setSelectedTopicIds}
                  expandedIds={expandedIds}
                  setExpandedIds={setExpandedIds}
                />
              ))}
          </ul>
        )
      )}

      <div className="flex items-center justify-between">
        <button className="secondary" onClick={() => modal.closeModal()}>
          {t('global:cancel')}
        </button>
        <button className="primary" onClick={updateStakeStandardTopics}>
          {t('global:edit')}
        </button>
      </div>
    </div>
  );
}

type T_StakeStandardTopicTree =
  | StakeStandardTopicsPickerModal_CsrStandardTopicFragment
  | StakeStandardTopicsPickerModal_CsrStandardTopicChildFragment
  | StakeStandardTopicsPickerModal_CsrStandardTopicGrandChildFragment;

function StandardTopicItem({
  topic,
  selectedTopicIds,
  setSelectedTopicIds,
  expandedIds,
  setExpandedIds,
}: {
  topic: T_StakeStandardTopicTree;
  selectedTopicIds: string[];
  setSelectedTopicIds: (ids: string[]) => void;
  expandedIds: string[];
  setExpandedIds: (ids: string[]) => void;
}) {
  const toggleTopic = (id: string) => {
    const newIds = selectedTopicIds.includes(id)
      ? selectedTopicIds.filter((t) => t !== id)
      : [...selectedTopicIds, id];
    setSelectedTopicIds(newIds);
  };
  const isChecked = selectedTopicIds.includes(topic.id);
  const children = getChildrenOrEmptyArray(topic) ? topic.children || [] : [];

  const isExpanded = expandedIds.includes(topic.id);
  const toggleExpandTopic = () => {
    if (!expandedIds.includes(topic.id)) {
      const newIds = [...expandedIds, topic.id];
      setExpandedIds(newIds);
    }
  };

  return (
    <li className="">
      <div className="flex items-center">
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={() => toggleTopic(topic.id)}
        >
          <Checkbox
            checked={isChecked}
            onChange={() => toggleTopic(topic.id)}
          />
          <CsrStandardTopicTag csrStandardTopic={topic} />
        </div>
        {children.length > 0 && (
          <button className="unstyled" onClick={() => toggleExpandTopic()}>
            <ChevronDownIcon
              className={clsx(
                'w-4 h-4 text-gray-500',
                isExpanded && 'rotate-180',
              )}
            />
          </button>
        )}
      </div>
      {children.length > 0 && isExpanded && (
        <ul className="ml-3 pl-2 mt-0.5 mb-2 space-y-0.5">
          {children.map((child) => (
            <StandardTopicItem
              key={child.id}
              topic={child}
              selectedTopicIds={selectedTopicIds}
              setSelectedTopicIds={setSelectedTopicIds}
              expandedIds={expandedIds}
              setExpandedIds={setExpandedIds}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

function getChildrenOrEmptyArray(
  topic: T_StakeStandardTopicTree,
): topic is StakeStandardTopicsPickerModal_CsrStandardTopicFragment {
  return (
    (topic as StakeStandardTopicsPickerModal_CsrStandardTopicFragment)
      .children !== undefined
  );
}

function arrayOfItemsHavingChildrenOrGrandChildrenSelected(
  topics: T_StakeStandardTopicTree[],
  selectedTopicIds: string[],
): string[] {
  return topics.reduce((acc, topic) => {
    // If the topic has children selected, add it to the list
    const selectedChildrenIds = getSelectedChildrenIds(topic, selectedTopicIds);
    if (selectedChildrenIds.length > 0) {
      acc.push(topic.id);
      // Also include selected grand children
      acc.push(...selectedChildrenIds);
    }

    return acc;
  }, [] as string[]);
}

function getSelectedChildrenIds(
  topic: T_StakeStandardTopicTree,
  selectedTopicIds: string[],
): string[] {
  const children = getChildrenOrEmptyArray(topic) ? topic.children || [] : [];
  return children
    .filter((child) => selectedTopicIds.includes(child.id))
    .map((child) => child.id);
}
