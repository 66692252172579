import { RoadmapFieldsFragment } from '../../../graphql/generated';
import React from 'react';
import { DownloadRoadmapButton } from './DownloadRoadmapButton';
import { useRightDrawer } from '../../layout/RightDrawer';
import { ImportRoadmapJsonFileForm } from './ImportRoadmapJsonFileForm';

export function RoadmapSettingsDrawer({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  const drawer = useRightDrawer();
  return (
    <div className="flex flex-col h-full">
      <h1 className="px-8 py-10  bg-gray-50 title-h3 border-b border-gray-100">
        Paramètres
      </h1>
      <div className="p-8 grow divide-y divide-gray-100 space-y-4">
        <div>
          <p className="text-base font-bold mb-4">
            Exporter la feuille de route
          </p>
          <DownloadRoadmapButton roadmap={roadmap} />
        </div>
        <div className="pt-4">
          <p className="text-base font-bold mb-4">
            Importer une feuille de route (JSON)
          </p>
          <ImportRoadmapJsonFileForm roadmap={roadmap} />
        </div>
      </div>
      <div className="p-8">
        <button className="w-full" onClick={() => drawer.closeDrawer()}>
          Fermer
        </button>
      </div>
    </div>
  );
}
