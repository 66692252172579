import { useParams } from 'react-router-dom';
import {
  ActionFieldsFragment,
  useActionQuery,
  useCompanyAbstractQuery,
} from '../../../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { UpdateAction } from '../../../../components/pro/roadmap/UpdateAction';
import { useProContext } from '../../../../providers/ProContextProvider';

export function ProActionEditScreen() {
  const { actionId } = useParams();

  const { data, loading } = useActionQuery({
    variables: {
      id: actionId || '',
    },
    skip: !actionId,
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.action) {
    return <ProActionEditScreenInner action={data.action} />;
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>Une erreur est survenue lors de la récupération de l'action.</p>
      </MessageBox>
    );
  }
}

export function ProActionEditScreenInner({
  action,
}: {
  action: ActionFieldsFragment;
}) {
  // Select and load automatically the corresponding company
  const proContext = useProContext();
  const { data } = useCompanyAbstractQuery({
    variables: {
      id: action.roadmap?.company?.id || '',
    },
    skip: !action.roadmap?.company,
    fetchPolicy: 'network-only',
  });
  if (proContext && !proContext?.company && data?.company) {
    proContext.updateCompany(data?.company.id, false);
  }

  return <UpdateAction action={action} />;
}
