import { createContext, ReactNode, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const VisiativContext = createContext<string | null>(null);

export const useVisiativ = () => useContext(VisiativContext);

export function VisiativProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const [loginHint] = useState<string | null>(searchParams.get('login_hint'));

  return (
    <VisiativContext.Provider value={loginHint}>
      {children}
    </VisiativContext.Provider>
  );
}
