import React, { useEffect, useState } from 'react';
import {
  ApplyMaterialityThresholdsInput,
  DoubleMaterialityStaticMatrixDocument,
  useMaterialityThresholdModal_CompanyQuery,
  useMaterialityThresholdModalMutation,
  useSimulateMaterialityThresholdLazyQuery,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { LoaderFullscreen } from '../../../layout/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { DoubleMaterialityStaticMatrix } from '../../publications/doubleMateriality/DoubleMaterialityStaticMatrix';
import { useDebounce } from '@hooks/useDebounce';
import { MatrixProvider } from '../../publications/doubleMateriality/MatrixContext';

export function MaterialityThresholdModal({
  companyId,
}: {
  companyId: string;
}) {
  const modal = useModal();
  const { t } = useTranslation();

  const [updateMaterialityThresholdMutation] =
    useMaterialityThresholdModalMutation();

  const [impactMaterialityThreshold, setImpactMaterialityThreshold] = useState<
    number | null
  >(null);
  const [financialMaterialityThreshold, setFinancialMaterialityThreshold] =
    useState<number | null>(null);

  const companyQuery = useMaterialityThresholdModal_CompanyQuery({
    variables: {
      companyId,
    },
    onCompleted: (data) => {
      setImpactMaterialityThreshold(
        data.company.impactMaterialityThreshold ?? null,
      );
      setFinancialMaterialityThreshold(
        data.company.financialMaterialityThreshold ?? null,
      );
    },
    fetchPolicy: 'no-cache',
  });

  const updateMaterialityThreshold = (
    input: Partial<ApplyMaterialityThresholdsInput>,
  ) => {
    updateMaterialityThresholdMutation({
      variables: {
        input: {
          referentialId: companyQuery.data?.company?.referential?.id ?? '',
          ...input,
        },
      },
      refetchQueries: [
        {
          query: DoubleMaterialityStaticMatrixDocument,
          variables: {
            referentialId: companyQuery.data?.company?.referential?.id,
            companyId: companyId,
          },
        },
      ],
    })
      .then(() => {
        modal.closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [simulateMaterialityThresholds] =
    useSimulateMaterialityThresholdLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [materialStakesCount, setMaterialStakesCount] = useState<number>();

  const debouncedImpactMaterialityThreshold = useDebounce(
    impactMaterialityThreshold,
  );
  const debouncedFinancialMaterialityThreshold = useDebounce(
    financialMaterialityThreshold,
  );

  useEffect(() => {
    if (
      debouncedFinancialMaterialityThreshold !== null &&
      debouncedImpactMaterialityThreshold !== null
    ) {
      void simulateMaterialityThresholds({
        variables: {
          input: {
            referentialId: companyQuery.data?.company?.referential?.id ?? '',
            financialMaterialityThreshold:
              debouncedFinancialMaterialityThreshold,
            impactMaterialityThreshold: debouncedImpactMaterialityThreshold,
          },
        },
        onCompleted: (data) => {
          if (data?.simulateMaterialityThresholds) {
            setMaterialStakesCount(
              data?.simulateMaterialityThresholds.filter(
                (item) => !item.isDisabled,
              ).length,
            );
          }
        },
      });
    }
  }, [
    debouncedFinancialMaterialityThreshold,
    debouncedImpactMaterialityThreshold,
    simulateMaterialityThresholds,
    companyQuery.data?.company?.referential?.id,
  ]);

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      <div className="space-y-4 divide-y divide-gray-100">
        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-1">
            <label className="form-input-label">
              {t('materiality.threshold.form.financialThreshold')}
            </label>
            <input
              className="form-input-text"
              type="number"
              min={0}
              max={4}
              step={0.1}
              value={financialMaterialityThreshold ?? ''}
              onChange={(e) => {
                setFinancialMaterialityThreshold(parseFloat(e.target.value));
              }}
            />
            <p className="text-gray-500 text-sm">
              {t('materiality.threshold.form.threshold_limit', {
                min: 0,
                max: 4,
              })}
            </p>
          </div>
          <div className="space-y-1">
            <label className="form-input-label">
              {t('materiality.threshold.form.impactThreshold')}
            </label>
            <input
              className="form-input-text"
              type="number"
              min={0}
              max={4}
              step={0.1}
              value={impactMaterialityThreshold ?? ''}
              onChange={(e) => {
                setImpactMaterialityThreshold(parseFloat(e.target.value));
              }}
            />
            <p className="text-gray-500 text-sm">
              {t('materiality.threshold.form.threshold_limit', {
                min: 0,
                max: 4,
              })}
            </p>
          </div>
          <div className="space-y-1 flex flex-col items-end">
            <div className="grid grid-cols-2 gap-y-0.5 gap-x-2 items-center">
              <div className="text-sm text-gray-500">
                {t('materiality.threshold.stats.material_stakes')}
              </div>
              <div className="font-bold">{materialStakesCount}</div>
              <div className="text-sm text-gray-500">
                {t('materiality.threshold.stats.material_esrs')}
              </div>
              <div className="tag gray w-16">
                {t('materiality.threshold.stats.soon')}
              </div>
              <div className="text-sm text-gray-500">
                {t('materiality.threshold.stats.material_datapoints')}
              </div>
              <div className="tag gray w-16">
                {t('materiality.threshold.stats.soon')}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <MatrixProvider initialStakeholderSegments={[]}>
            <DoubleMaterialityStaticMatrix
              {...{ financialMaterialityThreshold, impactMaterialityThreshold }}
            />
          </MatrixProvider>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button className="secondary" onClick={() => modal.closeModal()}>
          {t('global:cancel')}
        </button>
        <button
          className="primary"
          onClick={() => {
            updateMaterialityThreshold({
              financialMaterialityThreshold: financialMaterialityThreshold,
              impactMaterialityThreshold: impactMaterialityThreshold,
            });
            modal.closeModal();
          }}
        >
          {t('materiality.threshold.form.cta', {
            count: materialStakesCount || 0,
          })}
        </button>
      </div>
    </div>
  );
}
