import React, { ReactNode, useEffect } from 'react';
import { useFirebaseAuth } from 'providers/FirebaseProvider';
import { useCurrentAuth } from 'providers/CurrentAuthProvider';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import {
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { Auth } from '@firebase/auth';
import logo from '../../../assets/images/logo-with-name.svg';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';

// francois+test@goodsteps.io
// Bn3gWakRGryBFsNECQ3bvFNfpAfujeJYmGZkMim7hjiR

function getSamiOAuthProvider() {
  const samiProvider = new OAuthProvider('oidc.sami');
  // const scopes = samiProvider.getScopes();
  // console.log('scopes', scopes);
  // samiProvider.addScope('email');
  return samiProvider;
}

function initiateSamiLoginWithPopup(firebaseAuth: Auth) {
  console.log('initiateSamiLogin');
  const samiOAuthProvider = getSamiOAuthProvider();

  if (samiOAuthProvider) {
    console.log('signInWithPopup');
    signInWithPopup(firebaseAuth, samiOAuthProvider)
      .then(function (result) {
        console.log('signInWithPopup result', result);
      })
      .catch(function (error) {
        console.error('signInWithPopup error', error);
      });
  }
}

function initiateSamiLoginWithRedirect(firebaseAuth: Auth) {
  console.log('initiateSamiLogin');
  const samiOAuthProvider = getSamiOAuthProvider();
  if (samiOAuthProvider) {
    console.log('signInWithRedirect');
    signInWithRedirect(firebaseAuth, samiOAuthProvider)
      .then(function (result) {
        console.log('signInWithRedirect SUCCESS', result);
      })
      .catch(function (error) {
        console.log('signInWithRedirect error', error);
      });
  }
}

export function SamiIntegrationLoginScreen() {
  const currentAuth = useCurrentAuth();
  const currentUser = useCurrentUser();
  const firebaseAuth = useFirebaseAuth();

  useEffect(() => {
    if (currentAuth && currentUser) {
      console.log('Already logged in');
      console.log(currentAuth);
      console.log(currentUser);
    }
  }, [currentAuth, currentUser]);

  if (currentAuth && currentUser) {
    return (
      <CenteredLayout>
        <MessageBox type={MessageBoxType.Info}>
          <p>Vous êtes déjà connecté en tant que {currentUser.authId}</p>
          <pre className="text-xs">{JSON.stringify(currentUser, null, 2)}</pre>
        </MessageBox>
      </CenteredLayout>
    );
  }

  return (
    <CenteredLayout>
      <h1>Bienvenue !</h1>
      <p className="">Vous avez déjà un compte Sami ?</p>
      <button
        className="primary"
        onClick={() => initiateSamiLoginWithPopup(firebaseAuth)}
      >
        Login Sami (Popup method)
      </button>
      <button
        className="primary"
        onClick={() => initiateSamiLoginWithRedirect(firebaseAuth)}
      >
        Login Sami (WithRedirect method)
      </button>
    </CenteredLayout>
  );
}

function CenteredLayout({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full bg-white ">
      <div className="w-full p-8">
        <img className="h-10" src={logo} alt="Good Steps logo" />
      </div>
      <div className="grow max-w-lg flex flex-col gap-8 items-center justify-center">
        <div className="flex flex-col gap-4">{children}</div>
      </div>
    </div>
  );
}
