import React, { FC, useState } from 'react';
import {
  AdminUserList_UserFragment,
  useAdminUserListQuery,
} from '../../../../graphql/generated';
import { withAdminNavigation } from '../../withAdminNavigation';
import { AdminNavRoutes } from '../../AdminNav';
import { AdminUserListItem } from './AdminUserListItem';
import { useTranslation } from '@hooks/useTranslation';

const AdminUsersList: FC = () => {
  const [users, setUsers] = useState<AdminUserList_UserFragment[]>([]);
  useAdminUserListQuery({
    onCompleted: (data) => {
      setUsers(data.users);
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
  });
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('admin:users.list.title')}</h1>
      <ul className="mt-4 space-y-2">
        {users.map((user, index) => (
          <AdminUserListItem user={user} index={index} key={user.id} />
        ))}
      </ul>
    </div>
  );
};

export const AdminUsersListScreen = withAdminNavigation(
  AdminUsersList,
  AdminNavRoutes.Users,
);
