import React from 'react';
import { BlocksRenderer as StrapiBlocksRenderer } from '@strapi/blocks-react-renderer';

export function BlocksRenderer({
  ...blocksRendererProps
}: React.ComponentProps<typeof StrapiBlocksRenderer>) {
  // Strapi and/or Strapi Blocks Renderer is not handling null content
  // Strapi GraphQL API returns "any" or nullable types
  // -> in any case, Strapi documentation is wrong, "any" should not be allowed here
  // Strapi Blocks Renderer expects RootNode[];

  // Make sure rest.content is not null
  if (!blocksRendererProps.content) {
    return null;
  }
  return <StrapiBlocksRenderer {...blocksRendererProps} />;
}
