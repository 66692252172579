import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import surveyHome from '../../../assets/images/illustrations/survey/surveys-home.png';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { SurveyList } from '../../../components/survey/SurveyList';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { useCustomerSurveyListScreenQuery } from '../../../graphql/generated';

export function SurveyListScreen() {
  const currentUser = useCurrentUser();

  const surveysDocumentQuery = useCustomerSurveyListScreenQuery({
    variables: {
      companyId: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  const surveys = surveysDocumentQuery.data?.surveys || [];

  if (surveysDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <>
      <PageTitle title="Consultations" />
      <SurveyListHeader />
      <div className="flex flex-col gap-4 main-content my-10 w-full">
        <h2 className="title-h3">Vos consultations</h2>
        <SurveyList surveys={surveys} />
      </div>
    </>
  );
}

export function SurveyListHeader() {
  return (
    <div className="bg-purple-50 py-10">
      <div className="main-content flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <h2 className="">Consultations</h2>
          <p className="text-gray-500 max-w-3xl">
            Les consultations permettent de recueillir la perception de toutes
            vos parties prenantes. Poser les bonnes questions aux bonnes
            personnes nous permet d'établir un diagnostic très précis.
          </p>
        </div>
        <img src={surveyHome} alt="" className="w-48" />
      </div>
    </div>
  );
}
