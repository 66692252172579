import { LoaderFullscreen } from '../../../components/layout/Loader';
import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { MaturityEvaluationResult } from '../../../components/pro/publications/maturityScores/MaturityEvaluationResult';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { useMaturityScreenQuery } from '../../../graphql/generated';

export function MaturityScreen() {
  const currentUser = useCurrentUser();

  // Fetch maturity through referential directly
  const stakesWithMaturity = useMaturityScreenQuery({
    variables: {
      input: {
        referentialId: currentUser?.company?.referential?.id || '',
      },
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (stakesWithMaturity.loading) {
    return <LoaderFullscreen />;
  }

  if (stakesWithMaturity.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {stakesWithMaturity.error.message}
      </MessageBox>
    );
  }

  if (stakesWithMaturity.data?.computeMaturityScores) {
    return (
      <>
        <PageTitle title="Score de maturité" />
        <MaturityEvaluationResult
          isEditMode={false}
          stakes={stakesWithMaturity.data.computeMaturityScores}
        />
      </>
    );
  }

  return null;
}
