import { LoaderFullscreen } from '../../../components/layout/Loader';
import React from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { MaterialityMatrixHeader } from '../../../components/publications/materialityMatrix/MaterialityMatrixHeader';
import { SimpleMaterialityMatrix } from '../../../components/pro/publications/simpleMateriality/financialByStakeholderSegmentsMatrix/SimpleMaterialityMatrix';
import { MatrixProvider } from '../../../components/pro/publications/doubleMateriality/MatrixContext';

export function ProMaterialityMatrixScreen() {
  const proContext = useProContext();

  if (proContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  return (
    <>
      <PageTitle title="Matrice de matérialité" />
      <ScreenTopBar />
      <MaterialityMatrixHeader />
      <div className="p-8 flex flex-col gap-8 main-content w-full">
        <MatrixProvider initialStakeholderSegments={[]}>
          <SimpleMaterialityMatrix />
        </MatrixProvider>
      </div>
    </>
  );
}

function ScreenTopBar() {
  return (
    <div className="py-4 px-8 flex items-center gap-4 justify-between">
      <h1 className="title-h5">Matrice de matérialité</h1>
    </div>
  );
}
