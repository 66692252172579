import React, { ReactNode } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { GradientScore } from '../../../generic/GradientScore';
import { Getter } from '@tanstack/table-core/src/utils';
import { useTranslation } from '@hooks/useTranslation';
import { ChevronDownIcon, ChevronUpIcon } from '../../../icons';
import {
  CsrStandardTopicTag_CsrStandardTopicFragment,
  SummaryDetailIroRowFragment,
} from '../../../../graphql/generated';
import { CsrStandardTopicTag } from '../../stakes/CsrStandardTopicTag';
import clsx from 'clsx';

export type IroSummaryStakeRow = {
  stake: {
    id: string;
    name: string;
    icon: ReactNode;
    isDisabled: boolean;
  };
  csrStandardTopics?: CsrStandardTopicTag_CsrStandardTopicFragment[];
  financialMateriality?: number | null;
  impactMateriality?: number | null;
  maxMateriality?: number | null;
  actions: ReactNode[];
  iros: SummaryDetailIroRowFragment[];
};

export const scoreFormatter = (data: { getValue: Getter<number> }) => {
  const score = data.getValue();
  return (
    <div className="w-full flex items-center justify-center">
      <GradientScore score={score} />
    </div>
  );
};

export const useIroSummaryColumns = (): Array<
  ColumnDef<IroSummaryStakeRow>
> => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<IroSummaryStakeRow>();

  return [
    columnHelper.accessor('stake', {
      cell: (data) => {
        const stake = data.getValue();
        const expandProps = {
          onClick: () => data.row.toggleExpanded(),
          style: { cursor: 'pointer' },
        };
        return (
          <div className="flex items-center justify-between">
            <div
              className={clsx(
                'flex items-center gap-3',
                stake.isDisabled ? 'text-gray-500' : 'text-gray-900 font-bold',
              )}
            >
              {stake.icon} <span {...expandProps}>{stake.name}</span>
            </div>
          </div>
        );
      },
      sortDescFirst: false,
      header: () => <span>{t('iro.summary.table.header.stake.label')}</span>,
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('csrStandardTopics', {
      cell: (data) => {
        const csrStandardTopics = data.getValue();
        return (
          <div className="flex items-center gap-0.5 flex-wrap">
            {csrStandardTopics?.map((csrStandardTopic) => (
              <CsrStandardTopicTag
                csrStandardTopic={csrStandardTopic}
                key={csrStandardTopic.id}
              />
            ))}
          </div>
        );
      },
      header: () => (
        <span>{t('iro.summary.table.header.subTopics.label')}</span>
      ),
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('financialMateriality', {
      cell: scoreFormatter,
      header: () => t('iro.summary.table.header.financialMateriality.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
    }),
    columnHelper.accessor('impactMateriality', {
      cell: scoreFormatter,
      header: () => t('iro.summary.table.header.impactMateriality.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
    }),
    columnHelper.accessor('maxMateriality', {
      cell: scoreFormatter,
      header: () => t('iro.summary.table.header.maxMateriality.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'border-r-0 text-center w-36',
        },
      },
    }),
    columnHelper.accessor('actions', {
      cell: (data) => {
        const expandButton = (data.row.getIsExpanded() && (
          <ChevronUpIcon />
        )) || <ChevronDownIcon />;
        return (
          <div className={'flex items-center gap-3'}>
            <a href={'#'} onClick={() => data.row.toggleExpanded()}>
              {expandButton}
            </a>
            {data.getValue()}
          </div>
        );
      },
      meta: {
        th: {
          className: 'text-center w-20',
        },
        td: {
          className: 'text-center w-20 pl-0',
        },
      },
      enableSorting: false,
      header: () => '',
    }),
  ] as Array<ColumnDef<IroSummaryStakeRow>>;
};
