import { NavLink, useParams } from 'react-router-dom';
import { Action } from '../../../../components/publications/roadmap/Action';
import {
  ActionFieldsFragment,
  useActionQuery,
  useCompanyAbstractQuery,
} from '../../../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { UpdateActionButton } from '../../../../components/pro/roadmap/UpdateActionButton';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { isCoachUser } from '../../../../services/CompanyService';
import { useProContext } from '../../../../providers/ProContextProvider';
import { ChevronRightIcon } from '../../../../components/icons';

export function ProActionScreen() {
  const { actionId } = useParams();

  const { data, loading } = useActionQuery({
    variables: {
      id: actionId || '',
    },
    skip: !actionId,
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.action) {
    return <ProActionScreenInner action={data.action} />;
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>Une erreur est survenue lors de la récupération de l'action.</p>
      </MessageBox>
    );
  }
}

export function ProActionScreenInner({
  action,
}: {
  action: ActionFieldsFragment;
}) {
  // Select and load automatically the corresponding company
  const proContext = useProContext();
  const { data } = useCompanyAbstractQuery({
    variables: {
      id: action.roadmap?.company?.id || '',
    },
    skip: !action.roadmap?.company,
    fetchPolicy: 'network-only',
  });
  if (proContext && !proContext?.company && data?.company) {
    proContext.updateCompany(data?.company.id, false);
  }

  return (
    <>
      <ScreenTopBar action={action} />
      <Action action={action} />
    </>
  );
}

function ScreenTopBar({ action }: { action: ActionFieldsFragment }) {
  const currentUser = useCurrentUser();
  return (
    <div className="sticky top-0 bg-white py-4 px-8 flex items-center gap-4 justify-between">
      <h1 className="title-h5 flex items-center gap-1">
        <NavLink to={'/pro/roadmap'}>
          <span>Feuille de route</span>
        </NavLink>
        <span>
          <ChevronRightIcon className="shrink-0" />
        </span>
        <span>{action.title}</span>
      </h1>
      {isCoachUser(currentUser) && (
        <UpdateActionButton action={action} displayLabel={true} />
      )}
    </div>
  );
}
