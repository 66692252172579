import React from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ProBreadcrumb } from '../../../components/nav/ProBreadcrumb';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { ProEvaluationsList } from '../../../components/pro/maturity/ProEvaluationsList';
import { useTranslation } from '@hooks/useTranslation';
import { useProMaturityEvaluationsListScreenQuery } from '../../../graphql/generated';

export function ProMaturityEvaluationsListScreen() {
  const proContext = useProContext();
  const { t } = useTranslation();

  const query = useProMaturityEvaluationsListScreenQuery({
    variables: {
      companyId: proContext?.company?.id || '',
    },
    skip: !proContext?.company?.id,
    fetchPolicy: 'network-only',
  });

  const maturityEvaluations = query.data?.maturityEvaluations || [];

  if (proContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  return (
    <>
      <PageTitle title={t('maturity.evaluation.title')} />
      <ProBreadcrumb />
      <div className="main-content w-full py-8 space-y-8">
        <ProEvaluationsList maturityEvaluations={maturityEvaluations} />
      </div>
    </>
  );
}
