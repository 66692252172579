import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

export function Toggle({
  state,
  setState,
  disabled = false,
}: {
  state: boolean | null | undefined;
  setState: (newState: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <Switch
      checked={state || false}
      disabled={disabled}
      onChange={(newState) => setState(newState)}
      className={clsx(
        state ? 'bg-gray-900' : 'bg-gray-300',
        'm-0 p-0 place-content-start relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2',
      )}
    >
      <span
        aria-hidden="true"
        className={clsx(
          state ? 'translate-x-5' : 'translate-x-1',
          'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  );
}
