import React, { useState } from 'react';
import {
  RoadmapFieldsFragment,
  useRoadmapImportJsonMutation,
} from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';
import { useToast } from '../../layout/Toast';

export function ImportRoadmapJsonFileForm({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [jsonContent, setJsonContent] = useState<string>('');

  const [importRoadmapJson] = useRoadmapImportJsonMutation();
  const importRoadmap = () => {
    setLoading(true);
    importRoadmapJson({
      variables: {
        input: {
          roadmapId: roadmap.id,
          jsonContent: jsonContent,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Feuille de route importée avec succès');
      })
      .catch((e) => {
        toast.openToastWithError(
          "Erreur lors de l'import de la feuille de route",
        );
        console.error(e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <textarea
        placeholder="Coller le contenu JSON ici"
        className="form-input-text w-full h-32 mb-4"
        value={jsonContent}
        onChange={(e) => setJsonContent(e.target.value)}
      />
      <button
        onClick={importRoadmap}
        className="primary"
        disabled={loading || !jsonContent}
      >
        {loading && <Loader />}
        <span>Importer</span>
      </button>
    </div>
  );
}
