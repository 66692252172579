import React from 'react';
import { useToast } from '../../layout/Toast';
import { getAuth, getIdToken } from 'firebase/auth';
import { useFirebaseApp } from '../../../providers/FirebaseProvider';
import { Loader } from '../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { ExportSurveyButtonFragment } from '../../../graphql/generated';

export function ExportSurveyButton({
  survey,
}: {
  survey: ExportSurveyButtonFragment;
}) {
  // TODO: wrap HTTP API REST logic (using Swagger?) to make this code more re-usable
  const toast = useToast();
  const firebaseApp = useFirebaseApp();
  const { t } = useTranslation();
  const currentUser = getAuth(firebaseApp).currentUser;

  const [loading, setLoading] = React.useState(false);

  const exportResults = () => {
    if (currentUser == null) return;
    getIdToken(currentUser)
      .then((token) => {
        const authorizationHeader = token ? `Bearer ${token}` : '';
        if (process.env.API_URL) {
          const apiUrl = process.env.API_URL;
          // Call REST API to export results, using fetch
          setLoading(true);
          fetch(`${apiUrl}/survey/${survey.id}/export/survey`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              Authorization: authorizationHeader,
            },
          })
            .then((result) => {
              if (!result.ok) {
                throw Error(result.statusText);
              }
              return result.blob();
            })
            // We use the download property for triggering the download of the file from our browser.
            // More information about the following code can be found here: https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch.
            // Rewrite filename to be more friendly
            .then((blob) => {
              if (blob != null) {
                const filename = t('survey.settings.export.filename', {
                  surveyId: survey.id,
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
              }
            })
            .catch((error) => {
              toast.openToastWithError(error.message);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          toast.openToastWithError(t('toast:survey.export.error'));
        }
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="bg-blue-50 p-4 rounded-xl flex items-center justify-between">
      <div className="text-blue-900 text-sm">
        {t('survey.settings.export.help')}
      </div>
      <button
        className="primary blue"
        onClick={exportResults}
        disabled={loading}
      >
        {loading && <Loader />}
        {t('global:export')}
      </button>
    </div>
  );
}
