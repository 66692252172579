import React from 'react';
import { ProOnlyGuard } from '../../guards/ProOnlyGuard';
import { ProLayout } from '../../components/layout/ProLayout';
import { ProHomeScreen } from '../pro/home/ProHomeScreen';
import { ProCoachScreen } from '../pro/home/ProCoachScreen';
import { ProCompanyScreen } from '../pro/home/ProCompanyScreen';
import { ProTemplatesScreen } from '../pro/home/ProTemplatesScreen';
import { ProActionEditScreen } from '../pro/publications/roadmap/ProActionEditScreen';
import { ProStakesScreen } from '../pro/ProStakesScreen';
import { ProIroScreen } from '../pro/iro/evaluation/ProIroScreen';
import { ProProjectScreen } from '../pro/project/ProProjectScreen';
import { ProMaturityScreen } from '../pro/publications/ProMaturityScreen';
import { ProPrioritizationMatrixScreen } from '../pro/publications/ProPrioritizationMatrixScreen';
import { ProMaterialityMatrixScreen } from '../pro/publications/ProMaterialityMatrixScreen';
import { ProRoadmapScreen } from '../pro/publications/roadmap/ProRoadmapScreen';
import { ProActionScreen } from '../pro/publications/roadmap/ProActionScreen';
import { ProSurveyListScreen } from '../pro/survey/ProSurveyListScreen';
import { ProSurveyResultsScreen } from '../pro/survey/ProSurveyResultsScreen';
import { ProSurveyScreen } from '../pro/survey/ProSurveyScreen';
import { ProMaturityEvaluationsListScreen } from '../pro/maturity/ProMaturityEvaluationsListScreen';
import { ProMaturityEvaluationImportScreen } from '../pro/maturity/ProMaturityEvaluationImportScreen';
import { ProMaturityEvaluationScreen } from '../pro/maturity/ProMaturityEvaluationScreen';
import { ProMaturityEvaluationAssignScreen } from '../pro/maturity/ProMaturityEvaluationAssignScreen';
import { ProRoutes } from '../ScreensRouter';
import { ProStakeholderScreen } from '../pro/stakeholder/ProStakeholderScreen';
import { ProIroSummaryScreen } from '../pro/iro/summary/ProIroSummaryScreen';
import { useTranslation } from '@hooks/useTranslation';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { ProDoubleMaterialityMatrixScreen } from '../pro/publications/ProDoubleMaterialityMatrixScreen';
import { ProConsultationRawMaterialityScreen } from '../pro/survey/ConsultationRawMateriality/ProConsultationRawMaterialityScreen';

export const useCoachRouteConfig = (): BreadcrumbsRoute[] => {
  const { t } = useTranslation();

  return [
    {
      path: ProRoutes.Home,
      element: (
        <ProOnlyGuard>
          <ProLayout />
        </ProOnlyGuard>
      ),
      children: [
        {
          index: true,
          breadcrumb: null,
          element: <ProHomeScreen />,
        },
        {
          path: ProRoutes.Settings,
          breadcrumb: null,
          children: [
            {
              path: ProRoutes.SettingsCoach,
              element: <ProCoachScreen />,
              breadcrumb: 'Gérer les coachs',
            },
            {
              path: ProRoutes.SettingsCompany,
              element: <ProCompanyScreen />,
              breadcrumb: "Gérer l'entreprise",
            },
            {
              path: ProRoutes.SettingsTemplates,
              element: <ProTemplatesScreen />,
              breadcrumb: 'Gérer les modèles',
            },
            {
              path: ProRoutes.ActionTemplateEdit,
              element: <ProActionEditScreen />,
            },
          ],
        },
        {
          path: ProRoutes.Stakes,
          element: <ProStakesScreen />,
          breadcrumb: "Référentiel d'enjeux",
        },
        {
          path: ProRoutes.Iro,
          children: [
            {
              index: true,
              element: <ProIroSummaryScreen />,
              breadcrumb: 'Cotation des IRO',
            },
            {
              path: ProRoutes.IroRating,
              breadcrumb: '',

              children: [
                {
                  children: [
                    {
                      index: true,
                      element: <ProIroScreen />,
                      breadcrumb: t('screen.iro.iro_rating'),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: ProRoutes.StakeholderMapping,
          element: <ProStakeholderScreen />,
          breadcrumb: 'Cartographie des parties prenantes',
        },
        {
          path: ProRoutes.Project,
          breadcrumb: null,
          children: [
            {
              index: true,
              element: <ProProjectScreen />,
              breadcrumb: 'Gestion du projet',
            },
            {
              path: ProRoutes.ProjectTab,
              children: [
                {
                  index: true,
                  element: <ProProjectScreen />,
                  breadcrumb: null,
                },
              ],
            },
          ],
        },
        {
          path: ProRoutes.Publication,
          breadcrumb: null,
          children: [
            {
              path: ProRoutes.PublicationMaturity,
              breadcrumb: 'Score de maturité',
              element: <ProMaturityScreen />,
            },
            {
              path: ProRoutes.PublicationPrioritizationMatrix,
              breadcrumb: 'Matrice de priorisation',
              element: <ProPrioritizationMatrixScreen />,
            },
            {
              path: ProRoutes.PublicationMaterialityMatrix,
              breadcrumb: 'Matrice de matérialité',
              element: <ProMaterialityMatrixScreen />,
            },
            {
              path: ProRoutes.PublicationDoubleMaterialityMatrix,
              breadcrumb: t('nav:page.double_materiality_matrix'),
              element: <ProDoubleMaterialityMatrixScreen />,
            },
          ],
        },
        {
          path: ProRoutes.Roadmap,
          children: [
            {
              index: true,
              breadcrumb: 'Feuille de route',
              element: <ProRoadmapScreen />,
            },
            {
              path: ProRoutes.RoadmapAction,
              breadcrumb: null,
              element: <ProActionScreen />,
            },
            {
              path: ProRoutes.RoadmapActionEdit,
              breadcrumb: null,
              element: <ProActionEditScreen />,
            },
          ],
        },
        {
          path: ProRoutes.Survey,
          breadcrumb: null,
          children: [
            {
              path: ProRoutes.Survey,
              breadcrumb: null,
              children: [
                {
                  index: true,
                  breadcrumb: 'Consultations',
                  element: <ProSurveyListScreen />,
                },
                {
                  path: ProRoutes.SurveyResults,
                  children: [
                    {
                      index: true,
                      element: <ProSurveyResultsScreen />,
                      breadcrumb: 'Résultats des consultations',
                    },
                  ],
                },
                {
                  path: ProRoutes.SurveyItem,
                  children: [
                    {
                      index: true,
                      element: <ProSurveyScreen />,
                      breadcrumb: null,
                    },
                  ],
                },
                {
                  path: ProRoutes.SurveyTab,
                  children: [
                    {
                      index: true,
                      element: <ProSurveyScreen />,
                      breadcrumb: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: ProRoutes.ConsultationRawMaterialities,
          breadcrumb: null,
          children: [
            {
              index: true,
              breadcrumb: 'Consultations',
              element: <ProConsultationRawMaterialityScreen />,
            },
          ],
        },
        {
          path: ProRoutes.MaturityEvaluations,
          breadcrumb: null,
          children: [
            {
              index: true,
              breadcrumb: t('maturity.evaluation.title'),
              element: <ProMaturityEvaluationsListScreen />,
            },
            {
              path: ProRoutes.MaturityEvaluationImport,
              breadcrumb: 'Import XLS',
              element: <ProMaturityEvaluationImportScreen />,
            },
            {
              path: ProRoutes.MaturityEvaluationItem,
              breadcrumb: null,
              element: <ProMaturityEvaluationScreen />,
            },
            {
              path: '/pro/maturity/:surveyId/:tab',
              children: [
                {
                  index: true,
                  element: <ProMaturityEvaluationScreen />,
                  breadcrumb: null,
                },
              ],
            },
            {
              path: ProRoutes.MaturityEvaluationAssign,
              breadcrumb: 'Assigner les questions',
              element: <ProMaturityEvaluationAssignScreen />,
            },
          ],
        },
      ],
    },
  ];
};
