import {
  NoRoadmap,
  Roadmap,
} from '../../../components/publications/roadmap/Roadmap';
import React from 'react';
import { useRoadmapQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { getLatestRoadmapSummary } from '../../../services/CompanyService';

export function RoadmapScreen() {
  const currentUser = useCurrentUser();
  const lastRoadmap = getLatestRoadmapSummary(currentUser);

  const { data, loading, error } = useRoadmapQuery({
    variables: {
      id: lastRoadmap?.id || '',
    },
    skip: !lastRoadmap,
  });

  if (currentUser) {
    if (data?.roadmap) {
      return <Roadmap roadmap={data.roadmap} />;
    } else {
      return (
        <>
          {loading ? (
            <LoaderFullscreen />
          ) : error ? (
            <MessageBox type={MessageBoxType.Error}>{error.message}</MessageBox>
          ) : (
            <NoRoadmap />
          )}
        </>
      );
    }
  } else {
    return <LoaderFullscreen />;
  }
}
