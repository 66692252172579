import {
  ActionFieldsFragment,
  CatalystFieldsFragment,
  useActionCreateFromTemplateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { ActionIllustrationIcon } from '../../publications/roadmap/ActionIllustrationIcon';
import { StakeTag, StakeTagStyle } from '../../stake/StakeTag';
import { ChevronRightIcon, PlusIcon } from '../../icons';
import { TooltipWrapper } from '../../generic/TooltipWrapper';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';

export function ActionTemplateList({
  actions,
  catalyst,
  previewAction,
}: {
  actions: ActionFieldsFragment[];
  catalyst: CatalystFieldsFragment;
  previewAction: (action: ActionFieldsFragment) => void;
}) {
  return (
    <div className="space-y-4">
      {actions.length > 0 ? (
        actions.map((action) => (
          <div key={action.id}>
            <ActionRow
              action={action}
              catalyst={catalyst}
              previewAction={previewAction}
            />
          </div>
        ))
      ) : (
        <MessageBox type={MessageBoxType.Info}>
          Aucun modèle ne correspond aux critères de recherche
        </MessageBox>
      )}
    </div>
  );
}

function ActionRow({
  action,
  catalyst,
  previewAction,
}: {
  action: ActionFieldsFragment;
  catalyst: CatalystFieldsFragment;
  previewAction: (action: ActionFieldsFragment) => void;
}) {
  return (
    <div className="relative group p-2 mr-4 border border-gray-100 shadow-sm rounded-lg hover:border-gray-900 flex items-center justify-between gap-4">
      <div
        className="w-5/6 flex items-center gap-4 cursor-pointer"
        onClick={() => previewAction(action)}
      >
        <ActionIllustrationIcon action={action} />
        <div className="space-y-2">
          <div className="font-bold text-gray-900">{action.title}</div>
          {(action.diagnosticStakes || []).length > 0 && (
            <div className="flex items-center gap-1 flex-wrap">
              {action.diagnosticStakes?.map((stake) => (
                <StakeTag
                  stake={stake}
                  key={stake.id}
                  style={StakeTagStyle.GRAYED}
                  disableDetailModalOpening={true}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/6 flex items-center justify-end gap-1">
        <div className="invisible group-hover:visible top-2 right-2">
          <AddActionButton actionTemplate={action} catalyst={catalyst} />
        </div>
        <button
          className="primary purple small"
          onClick={() => previewAction(action)}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}

function AddActionButton({
  actionTemplate,
  catalyst,
}: {
  actionTemplate: ActionFieldsFragment;
  catalyst: CatalystFieldsFragment;
}) {
  const toast = useToast();
  const [addAction] = useActionCreateFromTemplateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);
    addAction({
      variables: {
        input: {
          actionTemplateId: actionTemplate.id,
          roadmapId: catalyst.roadmap.id,
          catalystId: catalyst.id,
          companyId: catalyst.roadmap.company.id,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Action ajoutée');
      })
      .catch((err) => console.error(err))
      .finally(() => setIsAdding(false));
  };

  return (
    <TooltipWrapper label={'Ajouter'} place="left">
      <button
        className="tertiary purple small"
        onClick={add}
        disabled={isAdding}
      >
        {isAdding ? <Loader /> : <PlusIcon className="w-4 h-4 shrink-0" />}
      </button>
    </TooltipWrapper>
  );
}
