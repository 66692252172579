import { useParams } from 'react-router-dom';
import { SurveyLanguage, useSurveyQuery } from '../../graphql/generated';
import { LoaderFullscreen } from '../../components/layout/Loader';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';
import React from 'react';
import {
  SURVEY_RESPONSE_MODE,
  SurveyResponse,
} from '../../components/survey/SurveyResponse';
import { useTranslation } from '@hooks/useTranslation';

export function SurveyPreviewScreen() {
  const { surveyId } = useParams();
  const { i18n } = useTranslation();

  const { data, loading, error } = useSurveyQuery({
    variables: { id: surveyId || '' },
    skip: !surveyId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // Switch to survey language
      i18n
        .changeLanguage(data.survey.language || SurveyLanguage.Fr)
        .catch((e) => console.error(e));
    },
  });

  if (error) {
    return <MessageBox type={MessageBoxType.Error}>{error.message}</MessageBox>;
  }

  if (data?.survey) {
    return (
      <SurveyResponse
        survey={data.survey}
        initialResponseMode={SURVEY_RESPONSE_MODE.PREVIEW}
      />
    );
  } else {
    if (loading) {
      return (
        <div className="h-screen w-full">
          <LoaderFullscreen />
        </div>
      );
    } else {
      return (
        <MessageBox type={MessageBoxType.Error}>
          Consultation non trouvé
        </MessageBox>
      );
    }
  }
}
