import { LoaderFullscreen } from '../../../components/layout/Loader';
import React, { useState } from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { isCoachUser } from '../../../services/CompanyService';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { MaturityEvaluationResult } from '../../../components/pro/publications/maturityScores/MaturityEvaluationResult';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { EditIcon, XIcon } from '../../../components/icons';
import { useProMaturityScreenQuery } from '../../../graphql/generated';

export function ProMaturityScreen() {
  const proContext = useProContext();
  const [isEditMode, setIsEditMode] = useState(false);

  // Fetch maturity through referential directly
  const stakesWithMaturityQuery = useProMaturityScreenQuery({
    variables: {
      input: { referentialId: proContext?.company?.referential?.id || '' },
    },
    skip: !proContext?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  if (stakesWithMaturityQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (stakesWithMaturityQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {stakesWithMaturityQuery.error.message}
      </MessageBox>
    );
  }

  if (stakesWithMaturityQuery.data?.computeMaturityScores) {
    return (
      <>
        <PageTitle title="Score de maturité" />
        <ScreenTopBar isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        <div className="w-full">
          <MaturityEvaluationResult
            isEditMode={isEditMode}
            stakes={stakesWithMaturityQuery.data.computeMaturityScores}
          />
        </div>
      </>
    );
  }

  return null;
}

function ScreenTopBar({
  isEditMode,
  setIsEditMode,
}: {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const proContext = useProContext();
  const currentUser = useCurrentUser();

  const editMode = () => {
    setIsEditMode(true);
    proContext?.setIsNavCollapsed(true);
  };

  const closeEditMode = () => {
    setIsEditMode(false);
    proContext?.setIsNavCollapsed(false);
  };

  if (isEditMode) {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <button className="tertiary" onClick={closeEditMode}>
          <XIcon />
        </button>
        <h1 className="title-h5 grow text-center">
          Scores de maturité ・ Édition
        </h1>
      </div>
    );
  } else {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <h1 className="title-h5">Scores de maturité</h1>
        <div className="flex items-center gap-4">
          {isCoachUser(currentUser) && (
            <button className="primary purple" onClick={editMode}>
              <EditIcon className="w-4 h-4" />
              <span>Modifier</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}
