import React from 'react';
import { UserFieldsFragment } from '../../graphql/generated';

export function UserProfileInitials({ user }: { user: UserFieldsFragment }) {
  return (
    <div className="rounded-full bg-gray-900 w-8 h-8 lg:w-16 lg:h-16 flex items-center justify-center text-white font-title font-extrabold text-base lg:text-lg shrink-0">
      <div>
        {user.firstName.charAt(0)}
        {user.lastName.charAt(0)}
      </div>
    </div>
  );
}
