import { FormEvent, useEffect, useState } from 'react';
import {
  NavigateFunction,
  NavLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useFirebaseAuth } from '../../providers/FirebaseProvider';
import { signInWithEmailAndPassword } from 'firebase/auth';

import logo from '../../assets/images/logo-with-name.svg';
import { PageTitle } from '../../components/nav/PageTitle';
import { useCurrentAuth } from '../../providers/CurrentAuthProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { Loader } from '../../components/generic/Loader';
import { ErrorIcon } from '../../components/icons';

export function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const firebaseAuth = useFirebaseAuth();
  const currentAuth = useCurrentAuth();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentAuth && currentUser) {
      redirectToAskedPage(navigate, searchParams);
    } else {
      if (isFormSubmitted) {
        if (currentAuth === undefined || currentUser === undefined) {
          setError(null);
          setIsLoading(true);
        } else if (currentAuth && currentUser === null) {
          setError(
            "Votre compte n'est pas initialisé. Veuillez nous contacter pour finaliser votre inscription",
          );
          setIsLoading(false);
        } else {
          setError('Mauvais mot de passe ou compte inexistant');
          setIsLoading(false);
        }
      }
    }
  }, [isFormSubmitted, currentAuth, currentUser, navigate, searchParams]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .catch((err) => {
        console.error(err);
        setIsFormSubmitted(true);
        setIsLoading(false);
        setError('Mauvais mot de passe ou compte inexistant');
      })
      .finally(() => {
        setIsFormSubmitted(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageTitle title="Se connecter" />

      <div className="w-full h-screen overflow-scroll flex flex-col p-8 items-stretch">
        <div>
          <img className="h-12 w-auto" src={logo} alt="Good Steps logo" />
        </div>
        <div className="grow flex gap-8 justify-between items-center self-center max-w-xl">
          <div>
            <h1>Bienvenue sur Good Steps !</h1>
            <div className="mt-8">
              <form
                name="loginForm"
                className="space-y-6"
                onSubmit={handleSubmit}
              >
                <div className="space-y-1">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(null);
                    }}
                    required
                  />
                </div>

                <div className="space-y-1">
                  <label htmlFor="password">Mot de passe</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError(null);
                    }}
                    required
                  />
                </div>

                <div className="flex justify-center">
                  {isLoading ? (
                    <Loader className="w-10 h-10 text-black" />
                  ) : (
                    <input
                      className="primary purple w-full"
                      type="submit"
                      value="Se connecter"
                    />
                  )}
                </div>

                <div className="flex justify-center">
                  <NavLink
                    to="/forgot-password"
                    className="text-gray-500 italic text-sm"
                  >
                    Mot de passe oublié ?
                  </NavLink>
                </div>

                {error && (
                  <div className="p-4 flex gap-4 items-center bg-red-50 text-red-700">
                    <ErrorIcon className="shrink-0" />
                    {error}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function redirectToAskedPage(
  navigate: NavigateFunction,
  searchParams: URLSearchParams,
) {
  const destination = searchParams.get('destination');
  navigate(destination && destination[0] === '/' ? destination : '/', {
    replace: true,
  });
}
