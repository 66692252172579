import { Tooltip } from '../../tooltip/Tooltip';
import React from 'react';
import { DatapointTag_DatapointFragment } from '../../../graphql/cms/generated';

export function DatapointTag({
  datapoint,
}: {
  datapoint: DatapointTag_DatapointFragment;
}) {
  return (
    <div className="tag blue">
      <Tooltip
        id={datapoint.documentId}
        tooltipChildren={<DatapointTooltip datapoint={datapoint} />}
      >
        {datapoint.identifier}
      </Tooltip>
    </div>
  );
}

function DatapointTooltip({
  datapoint,
}: {
  datapoint: DatapointTag_DatapointFragment;
}) {
  return (
    <div className="space-y-2">
      <h6>{datapoint.name}</h6>
      <div className="flex items-center gap-2">
        {datapoint.alternative && <div className="tag green">Alternative</div>}
        {datapoint.conditional && <div className="tag green">Conditional</div>}
        {datapoint.voluntary && <div className="tag green">Voluntary</div>}
      </div>
    </div>
  );
}
