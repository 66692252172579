import React from 'react';
import { useModal } from '../../../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { IroCommentForm } from './IroCommentForm';
import { IroComment_IroFragment } from '../../../../../../graphql/generated';
import { MessageFillIcon, MessageIcon } from '../../../../../icons';

export function IroComment({
  iro: { id, comment },
}: {
  iro: IroComment_IroFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();

  const openCommentModal = () => {
    modal.openModalWithComponent(
      <IroCommentForm
        {...{
          id,
          initialComment: comment,
        }}
      />,
      t('iro.comment.modal_title'),
      false,
      true,
      'w-3/12',
    );
  };

  return (
    <button className="unstyled" onClick={() => openCommentModal()}>
      {comment ? (
        <MessageFillIcon className="w-4 h-4 text-purple-500" />
      ) : (
        <MessageIcon className="w-4 h-4 text-gray-300" />
      )}
    </button>
  );
}
