import React, { useState } from 'react';
import {
  MaturityScreen_DiagnosticStakeFragment,
  ProMaturityScreen_DiagnosticStakeFragment,
} from '../../../../graphql/generated';
import { StakeTag } from '../../../stake/StakeTag';
import StakeMaturitySelect from '../../stakes/StakeMaturitySelect';
import { ExportableToImage } from '../../../generic/ExportableToImage';
import { MaturityRadarChart } from './MaturityRadarChart';
import { StakeMaturityProgressBar } from '../../../stake/StakeMaturityProgressBar';
import PillarDropdown from '../../../form/PillarDropdown';

export function MaturityEvaluationResult({
  stakes,
  isEditMode,
}: {
  stakes: ProMaturityScreen_DiagnosticStakeFragment[];
  isEditMode: boolean;
}) {
  const [filterByPillar, setFilterByPillar] = useState<string | null>(null);

  return (
    <>
      <div className="bg-gray-50 shadow-inner">
        <div className="space-y-4 main-content py-16">
          <div className="flex gap-4 justify-between items-end">
            <div className="space-y-2">
              <h1>Scores de maturité</h1>
              <p>
                Grâce aux éléments analysés, nous évaluons la maturité de chaque
                enjeu et consolidons les résultats par pilier.
              </p>
            </div>
            <div className="flex flex-col items-start gap-4">
              <PillarDropdown
                currentPillarId={filterByPillar}
                setPillarId={setFilterByPillar}
                isFilter={true}
              />
            </div>
          </div>
          <div>
            <MaturityRadarChart
              stakes={stakes}
              filterByPillar={filterByPillar}
              setPillarId={setFilterByPillar}
            />
          </div>
        </div>
      </div>
      <div className="main-content pt-8">
        <ExportableToImage name="Évaluation de maturité">
          <div className="space-y-4 w-full">
            <h6>Résultats par enjeux</h6>
            <table>
              <thead>
                <tr>
                  <th className="w-5/12">Enjeux</th>
                  <th className="w-6/12">Score de maturité</th>
                  <th className="w-1/12">Niveau de maturité</th>
                </tr>
              </thead>
              <tbody>
                {stakes
                  .filter(
                    (stake) =>
                      !filterByPillar || stake.pillar.id === filterByPillar,
                  )
                  .toSorted(
                    (a, b) =>
                      (b?.maturity?.score || 0) - (a?.maturity?.score || 0),
                  )
                  .map((stake) => {
                    if (stake) {
                      return (
                        <StakeMaturityRow
                          key={stake.id}
                          stake={stake}
                          isEditMode={isEditMode}
                        />
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
          </div>
        </ExportableToImage>
      </div>
    </>
  );
}

function StakeMaturityRow({
  stake,
  isEditMode,
}: {
  stake: MaturityScreen_DiagnosticStakeFragment;
  isEditMode: boolean;
}) {
  return (
    <tr>
      <td>
        <StakeTag stake={stake} />
      </td>
      <td>
        <StakeMaturityProgressBar stake={stake} />
      </td>
      <td>
        <StakeMaturitySelect stake={stake} disabled={!isEditMode} />
      </td>
    </tr>
  );
}
