import React from 'react';
import clsx from 'clsx';
import { useRightDrawer } from '../../layout/RightDrawer';
import {
  ActionFieldsFragment,
  CatalystFieldsFragment,
} from '../../../graphql/generated';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { PillarIcon } from '../../stake/PillarIcon';
import { CheckCircleIcon, ErrorIcon, QuoteWhiteIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function CatalystDrawer({
  catalyst,
}: {
  catalyst: CatalystFieldsFragment;
}) {
  const { closeDrawer } = useRightDrawer();

  return (
    <div className="flex flex-col h-screen">
      <div className="p-6 w-full order-last">
        <button className="w-full" onClick={closeDrawer}>
          Fermer
        </button>
      </div>
      <div className="overflow-y-scroll grow w-full">
        <div className="bg-purple-50 p-8 flex flex-col items-start gap-2">
          <div className="bg-gray-900 p-2 rounded-xl">
            <ThemeIcon
              theme={catalyst.theme}
              className="text-white w-5 h-5"
              enableTooltip={true}
            />
          </div>
          <p className={clsx('text-sm')}>Axe stratégique</p>
          <h3 className="">{catalyst.title}</h3>
        </div>
        <div className="p-8 divide-gray-100 divide-y space-y-4">
          {catalyst.quote && <Quote quote={catalyst.quote} />}
          {catalyst.strength && <Strengths strengths={catalyst.strength} />}
          {catalyst.obstacles && <Obstacles obstacles={catalyst.obstacles} />}
          <Stakes actions={catalyst.actions} />
        </div>
      </div>
    </div>
  );
}

function Quote({ quote }: { quote: string }) {
  return (
    <div className="flex gap-4 bg-white">
      <div className="flex shrink-0">
        <QuoteWhiteIcon className="inline-block h-10 w-10 rounded-full" />
      </div>
      <div className="font-title font-extrabold">{quote}</div>
    </div>
  );
}

function Strengths({ strengths }: { strengths: string[] }) {
  return (
    <div className="pt-4">
      <h5 className="mb-2 text-base">Atouts</h5>
      {strengths.map((strength) => (
        <div className="flex items-center gap-2 mb-2" key={strength}>
          <CheckCircleIcon className="text-green-500 shrink-0 h-6 w-6" />
          <p className="text-sm">{strength}</p>
        </div>
      ))}
    </div>
  );
}

function Obstacles({ obstacles }: { obstacles: string[] }) {
  return (
    <div className="pt-4">
      <h5 className="mb-2 text-base">Obstacles</h5>
      {obstacles.map((obstacle) => (
        <div className="flex items-center gap-2 mb-2" key={obstacle}>
          <ErrorIcon className="text-red-500 shrink-0 h-6 w-6" />
          <p className="text-sm">{obstacle}</p>
        </div>
      ))}
    </div>
  );
}

function Stakes({ actions }: { actions: ActionFieldsFragment[] }) {
  const { translateProperty } = useTranslation();
  const stakes = actions
    .flatMap((action) => action.diagnosticStakes)
    // Dedup stakes
    .filter((thing, i, arr) => arr.findIndex((t) => t?.id === thing?.id) === i)
    // Remove null values
    .filter((stake) => stake);

  if (stakes.length === 0) {
    return null;
  }
  return (
    <div className="py-4">
      <h5 className="mb-2 text-base">Enjeux liés</h5>
      <div className="flex items-center gap-2 flex-wrap">
        {stakes.map(
          (stake) =>
            stake && (
              <div className="tag gray flex items-center gap-2" key={stake.id}>
                <PillarIcon pillar={stake.pillar} />
                <p>{translateProperty(stake, 'name')}</p>
              </div>
            ),
        )}
      </div>
    </div>
  );
}
