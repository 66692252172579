import React, { useState } from 'react';
import {
  CompanySector,
  CompanySize,
  CompanyType,
  CurrentUserDocument,
  RegisterNewCompanyModal_ProjectsDocument,
  useRegisterNewCompanyModal_ProjectsQuery,
  useRegisterNewCompanyModalMutation,
} from '../../graphql/generated';
import { Loader } from '../generic/Loader';
import { useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { useProContext } from '../../providers/ProContextProvider';
import { convertFileToBase64 } from '../../services/UtilsService';
import { Toggle } from '../generic/form/Toggle';
import { CompanySizeDropdown } from './project/CompanySizeDropdown';
import { CompanySectorDropdown } from './project/CompanySectorDropdown';
import { ChooseProjectTemplateModal } from './project/ChooseProjectTemplate/ChooseProjectTemplateModal';
import { DeleteIcon } from '../icons';

export function RegisterNewCompanyModal({
  companyType,
}: {
  companyType: CompanyType;
}) {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const modal = useModal();
  const proContext = useProContext();

  const [companyName, setCompanyName] = useState('');
  const [logo, setLogo] = useState<string | null | undefined>(null);
  const [sector, setSector] = useState<CompanySector | null | undefined>(
    undefined,
  );
  const [size, setSize] = useState<CompanySize | null | undefined>(undefined);
  const [isSubsidiary, setIsSubsidiary] = useState(false);
  const [annualTurnover, setAnnualTurnover] = useState('');

  const [createCompany] = useRegisterNewCompanyModalMutation({
    refetchQueries: () => [
      {
        query: RegisterNewCompanyModal_ProjectsDocument,
        variables: {
          id: currentUser?.company?.id || '',
        },
        fetchPolicy: 'network-only',
      },
      CurrentUserDocument,
    ],
  });

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const companyMaxProjectsCountQuery = useRegisterNewCompanyModal_ProjectsQuery(
    {
      variables: { companyId: currentUser?.company?.id || '' },
      skip: !currentUser?.company?.id,
      fetchPolicy: 'network-only',
    },
  );
  const projects =
    companyMaxProjectsCountQuery.data?.company.projects.filter(
      (project) => project.type === CompanyType.EndUser,
    ) || [];
  const maxProjectsCount =
    companyMaxProjectsCountQuery.data?.company.metadata?.maxProjectsCount || 0;
  const projectsCount = projects.length;
  const isMaxProjectsReached =
    maxProjectsCount > 0 &&
    companyMaxProjectsCountQuery.data?.company &&
    projectsCount >= maxProjectsCount;

  const convertLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        toast.openToastWithError("Le format du fichier n'est pas supporté");
        return;
      }
      if (file.size > 1000000) {
        toast.openToastWithError('Le fichier ne doit pas dépasser 1 Mo');
        return;
      }
      convertFileToBase64(file)
        .then((base64) => {
          setLogo(base64 as string);
        })
        .catch((error) => {
          setLogo(null);
        });
    }
  };

  const create = () => {
    setIsCreating(true);
    createCompany({
      variables: {
        input: {
          company: {
            name: companyName,
            logo,
            createdBy: {
              id: currentUser?.id || '',
            },
            metadata: {
              sector,
              size,
              isSubsidiary,
              annualTurnover,
              customQuestionTypes:
                currentUser?.company?.metadata?.customQuestionTypes || [],
            },
            type: companyType,
          },
          coach: {
            id: currentUser?.id || '',
          },
        },
      },
    })
      .then((result) => {
        toast.openToastWithMessage('Projet créé');
        if (result.data?.companyCreate && proContext) {
          proContext.updateCompany(result.data.companyCreate.id, true);
          // Open templates modal
          modal.openModalWithComponent(
            <ChooseProjectTemplateModal />,
            '',
            true,
            false,
            'w-4/5',
          );
        } else {
          setError('Erreur lors de la création du projet');
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label htmlFor="companyName" className="form-input-label">
            Nom de l'entreprise
          </label>
          <input
            type="text"
            id="companyName"
            className="form-input-text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="logo" className="form-input-label">
            Logo de l'entreprise
          </label>

          <div className="flex gap-4 items-start">
            <div className="shrink-0 w-24 h-24 border border-gray-100 rounded-xl flex items-center justify-center p-2">
              {logo ? (
                <img src={logo} className="rounded-xl w-56" alt="logo" />
              ) : (
                <div className="italic text-sm text-gray-500">Aucun logo</div>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <input
                className="hidden"
                type="file"
                id="logo"
                name="logo"
                accept="image/jpeg, image/png"
                onChange={(event) => convertLogo(event)}
              />
              <div className="flex items-center gap-2">
                {logo && (
                  <button
                    className="unstyled small text-gray-500"
                    onClick={() => {
                      setLogo(null);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                )}
                <label
                  htmlFor="logo"
                  className="button purple secondary small inline-block"
                >
                  Choisir un fichier
                </label>
              </div>
              {logo ? (
                <p className="text-gray-300 text-sm">
                  Choisissez une image au format PNG ou JPEG de moins d'1 Mo
                </p>
              ) : (
                <p className="text-gray-300 text-sm">
                  Aucun fichier sélectionné.
                  <br />
                  Choisissez une image au format PNG ou JPEG de moins d'1 Mo
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="sector" className="form-input-label">
            Effectif
          </label>
          <CompanySizeDropdown size={size} setSize={setSize} />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="sector" className="form-input-label">
            Secteur d'activité
          </label>
          <CompanySectorDropdown sector={sector} setSector={setSector} />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="annualTurnOver" className="form-input-label">
            Chiffre d'affaires annuel
          </label>
          <input
            type="text"
            name="annualTurnOver"
            id="annualTurnOver"
            className="form-input-text"
            placeholder={'250k€ - 5M€'}
            value={annualTurnover}
            onChange={(event) => setAnnualTurnover(event.target.value)}
          />
        </div>

        <div className="flex gap-2 items-center">
          <Toggle state={isSubsidiary} setState={setIsSubsidiary} />
          <label htmlFor="isSubsidiary" className="form-input-label">
            Filiale d'un groupe ?
          </label>
        </div>
      </div>

      {error && <MessageBox type={MessageBoxType.Error}>{error}</MessageBox>}
      {isMaxProjectsReached && (
        <MessageBox type={MessageBoxType.Warning}>
          Vous avez atteint la limite de nombre de projets pour votre compte.
        </MessageBox>
      )}

      <div className="flex gap-2 justify-between pt-4">
        <button
          className="secondary"
          onClick={() => modal.closeModal()}
          disabled={isCreating}
        >
          {isCreating ? <Loader /> : 'Annuler'}
        </button>
        <button
          className="primary purple"
          onClick={create}
          disabled={isCreating || !companyName || isMaxProjectsReached}
        >
          {isCreating ? <Loader /> : 'Créer'}
        </button>
      </div>
    </div>
  );
}
