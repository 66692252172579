import React, { useEffect, useState } from 'react';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../../nav/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { XIcon } from '../../../icons';
import { ProRoutes } from '../../../../screens/ScreensRouter';
import { useTranslation } from '@hooks/useTranslation';
import { ProConsultationRawMateriality_ResultFragment } from '../../../../graphql/generated';
import { useProContext } from '../../../../providers/ProContextProvider';
import { ProConsultationRawMaterialitySettings } from './ProConsultationRawMaterialitySettings';
import { ProConsultationRawMaterialityResults } from './ProConsultationRawMaterialityResults';

export const enum CONSULTATION_RAW_MATERIALITY_TABS {
  RESULTS = 'RESULTS',
  SETTINGS = 'SETTINGS',
}

export const ProConsultationRawMateriality = ({
  consultation,
}: {
  consultation: ProConsultationRawMateriality_ResultFragment;
}) => {
  const { t } = useTranslation();
  const proContext = useProContext();
  const navigate = useNavigate();

  useEffect(() => {
    proContext?.setIsNavCollapsed(true);
    /* eslint-disable-next-line */
  }, []);

  const { tab } = useParams();
  const tabs: TabItem[] = [
    {
      key: CONSULTATION_RAW_MATERIALITY_TABS.RESULTS,
      name: t('survey.tabs.results'),
    },
    {
      key: CONSULTATION_RAW_MATERIALITY_TABS.SETTINGS,
      name: t('survey.tabs.settings'),
    },
  ];
  const [currentTab, setCurrentTab] = useState(
    tabs.find((t) => t.key === tab) || tabs[0],
  );
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="px-8 py-4 border-b border-gray-100 shadow-sm flex items-center justify-between">
        <div className="flex items-center gap-1 w-1/3">
          {proContext?.isNavCollapsed && (
            <button
              className="tertiary"
              onClick={() => {
                navigate(ProRoutes.Survey);
                proContext?.setIsNavCollapsed(false);
              }}
            >
              <XIcon />
            </button>
          )}
          <div className="flex gap-1 title-h5 items-center flex-wrap">
            <span>{consultation.name}</span>
          </div>
        </div>
        <div className="w-1/3 flex justify-center">
          <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
        </div>
        <div className="w-1/3"></div>
      </div>
      <div className="grow overflow-scroll flex">
        <InnerTab currentTab={currentTab} consultation={consultation} />
      </div>
    </div>
  );
};

function InnerTab({
  currentTab,
  consultation,
}: {
  currentTab: TabItem;
  consultation: ProConsultationRawMateriality_ResultFragment;
}) {
  switch (currentTab.key) {
    case CONSULTATION_RAW_MATERIALITY_TABS.RESULTS:
      return (
        <ProConsultationRawMaterialityResults consultation={consultation} />
      );
    case CONSULTATION_RAW_MATERIALITY_TABS.SETTINGS:
      return (
        <ProConsultationRawMaterialitySettings
          consultationId={consultation.id}
        />
      );
    default:
      return null;
  }
}
