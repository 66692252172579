import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { PageTitle } from '../../../components/nav/PageTitle';
import React from 'react';
import { ProUsersList } from '../../../components/pro/project/ProUsersList';
import { useCompanyQuery } from '../../../graphql/generated';
import { ProBreadcrumb } from '../../../components/nav/ProBreadcrumb';

export function ProCoachScreen() {
  const currentUser = useCurrentUser();

  // Get full company data
  const { data, loading } = useCompanyQuery({
    variables: {
      id: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.company) {
    return (
      <div className="">
        <PageTitle title="Gérer les coachs" />
        <ProBreadcrumb />
        <ProUsersList
          users={data.company.employees}
          companyId={data.company.id}
        />
      </div>
    );
  } else {
    return <LoaderFullscreen />;
  }
}
