import React, { useState } from 'react';
import { CompanyType, useCompanyCreateMutation } from '../../graphql/generated';
import { Loader } from '../generic/Loader';
import { useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { useProContext } from '../../providers/ProContextProvider';

export function RegisterNewProjectTemplateModal({
  companyType,
}: {
  companyType: CompanyType;
}) {
  const proContext = useProContext();
  const currentUser = useCurrentUser();
  const toast = useToast();
  const modal = useModal();

  const [templateName, setTemplateName] = useState('');

  const [createCompany] = useCompanyCreateMutation();
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const create = () => {
    setIsCreating(true);
    createCompany({
      variables: {
        input: {
          company: {
            name: templateName,
            createdBy: {
              id: currentUser?.id || '',
            },
            type: companyType,
          },
          coach: {
            id: currentUser?.id || '',
          },
        },
      },
    })
      .then((result) => {
        toast.openToastWithMessage('Modèle de projet créé');
        modal.closeModal();

        // Goto the new template
        if (result?.data?.companyCreate?.id) {
          proContext?.updateCompany(result.data.companyCreate.id, true);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label htmlFor="companyName" className="form-input-label">
            Nom du modèle de projet
          </label>
          <input
            type="text"
            id="companyName"
            className="form-input-text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </div>
      </div>

      {error && <MessageBox type={MessageBoxType.Error}>{error}</MessageBox>}

      <div className="flex gap-2 justify-between pt-4">
        <button
          className="secondary"
          onClick={() => modal.closeModal()}
          disabled={isCreating}
        >
          {isCreating ? <Loader /> : 'Annuler'}
        </button>
        <button
          className="primary purple"
          onClick={create}
          disabled={isCreating}
        >
          {isCreating ? <Loader /> : 'Créer'}
        </button>
      </div>
    </div>
  );
}
