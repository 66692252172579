import { LoaderFullscreen } from '../../../components/layout/Loader';
import React, { useState } from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { SurveyQuestionType } from '../../../graphql/generated';
import Tabs, {
  TabItem,
  TabItemManager,
  TabsStyles,
} from '../../../components/nav/Tabs';
import { useTranslation } from '@hooks/useTranslation';
import { DoubleMaterialityStakeholderMatrix } from '../../../components/pro/publications/doubleMateriality/DoubleMaterialityStakeholderMatrix';
import { UtopiesProDoubleMaterialityMatrixScreen } from './custom/UtopiesProDoubleMaterialityMatrixScreen';
import { NavLink } from 'react-router-dom';
import { ProRoutes } from '../../ScreensRouter';
import { OpenIcon } from '../../../components/icons';
import { DoubleMaterialityStaticMatrix } from '../../../components/pro/publications/doubleMateriality/DoubleMaterialityStaticMatrix';
import { MatrixProvider } from '../../../components/pro/publications/doubleMateriality/MatrixContext';

export const enum DOUBLE_MATERIALITY_TABS {
  IRO = 'IRO',
  STAKEHOLDER = 'STAKEHOLDER',
}

export function ProDoubleMaterialityMatrixScreen() {
  const proContext = useProContext();

  const { t } = useTranslation();
  const tabs: TabItem[] = [
    {
      key: DOUBLE_MATERIALITY_TABS.IRO,
      name: t('screen.double_materiality.iro_matrix.tabTitle'),
    },
    {
      key: DOUBLE_MATERIALITY_TABS.STAKEHOLDER,
      name: t('screen.double_materiality.stakeholder_matrix.tabTitle'),
    },
  ];

  const [currentTab, setCurrentTab] = useState<TabItem>(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  if (proContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  return (
    <>
      <PageTitle title={t('nav:page.double_materiality_matrix')} />
      <ScreenTopBar />

      <div className="px-16 border-b border-gray-100 w-full">
        <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
      </div>

      <div className="bg-gray-50 shadow-inner">
        <MatrixProvider initialStakeholderSegments={[]}>
          <ProDoubleMaterialityMatrixScreenTab currentTab={currentTab} />
        </MatrixProvider>
      </div>
    </>
  );
}

function ProDoubleMaterialityMatrixScreenTab({
  currentTab,
}: {
  currentTab: TabItem;
}) {
  const proContext = useProContext();
  const { t } = useTranslation();
  if (currentTab.key === DOUBLE_MATERIALITY_TABS.IRO) {
    return (
      <div className="main-content py-16 space-y-4">
        <div className="flex items-center gap-4 justify-between">
          <h3>{t('screen.double_materiality.iro_matrix.title')}</h3>
          <NavLink
            to={ProRoutes.Iro}
            className="button unstyled small text-gray-500"
          >
            {t('screen.double_materiality.iro_matrix.gotoIroScreen')}
            <OpenIcon />
          </NavLink>
        </div>
        <DoubleMaterialityStaticMatrix />
      </div>
    );
  }
  if (currentTab.key === DOUBLE_MATERIALITY_TABS.STAKEHOLDER) {
    return (
      <div className="">
        <div className="py-16 main-content space-y-4">
          <div className="flex items-center gap-4 justify-between">
            <h3>{t('screen.double_materiality.stakeholder_matrix.title')}</h3>
            <NavLink
              to={ProRoutes.Survey}
              className="button unstyled small text-gray-500"
            >
              {t(
                'screen.double_materiality.stakeholder_matrix.gotoSurveyScreen',
              )}
              <OpenIcon />
            </NavLink>
          </div>

          <MatrixProvider initialStakeholderSegments={[]}>
            <DoubleMaterialityStakeholderMatrix />
          </MatrixProvider>
        </div>
        <div className="bg-white">
          {(proContext?.company?.metadata?.customQuestionTypes || []).includes(
            SurveyQuestionType.UtopiesStakeDoubleMateriality,
          ) && <UtopiesProDoubleMaterialityMatrixScreen />}
        </div>
      </div>
    );
  }
}

function ScreenTopBar() {
  const { t } = useTranslation();
  return (
    <div className="py-8 px-16 space-y-2">
      <h1 className="">{t('screen.double_materiality.title')}</h1>
      <p className="text-gray-500">
        {t('screen.double_materiality.description')}
      </p>
    </div>
  );
}
