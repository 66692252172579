import { useIntegrationsRouteConfig } from './useIntegrationsRouteConfig';
import { useCoachRouteConfig } from './useCoachRouteConfig';
import { useEndUserRouteConfig } from './useEndUserRouteConfig';
import { usePublicRouteConfig } from './usePublicRouteConfig';

export const useRouteConfig = () => {
  return [
    ...useIntegrationsRouteConfig(),
    ...useCoachRouteConfig(),
    ...useEndUserRouteConfig(),
    ...usePublicRouteConfig(),
  ];
};
