import React, { ReactNode } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export function StrapiApolloProvider({ children }: { children: ReactNode }) {
  const httpLink = new HttpLink({ uri: process.env.STRAPI_URL });
  const token = process.env.STRAPI_API_KEY;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,

        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, httpLink]),
    devtools: {
      enabled: process.env.NODE_ENV !== 'production',
    },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
