import React, { ReactNode } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import i18n from 'i18next';
import { setContext } from '@apollo/client/link/context';
import { getAuth, getIdToken } from 'firebase/auth';
import { useFirebaseApp } from 'providers/FirebaseProvider';
import { TypedTypePolicies } from '../graphql/generated';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

export function ApiApolloProvider({ children }: { children: ReactNode }) {
  const firebaseApp = useFirebaseApp();

  const uploadLink = createUploadLink({
    uri: `${process.env.API_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    const currentUser = getAuth(firebaseApp).currentUser;
    if (!currentUser) {
      return { headers };
    }

    return getIdToken(currentUser).then((token) => {
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'accept-language': i18n.language ?? 'fr',
          ...headers,
        },
      };
    });
  });

  // Remove '__typename' from variables
  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: string) =>
        key === '__typename' ? undefined : value;
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      );
    }
    return forward(operation).map((data) => {
      return data;
    });
  });
  const typePolicies: TypedTypePolicies = {
    Survey: {
      fields: {
        recipients: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        respondents: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  };

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: ApolloLink.from([cleanTypeName, authLink, uploadLink]),
    devtools: {
      enabled: process.env.NODE_ENV !== 'production',
    },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
