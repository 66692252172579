import { useEffect, useRef } from 'react';
import { LeftNav } from '../nav/LeftNav';
import { Breadcrumb } from '../nav/Breadcrumb';
import { RightDrawer, RightDrawerProvider } from './RightDrawer';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';
import { Modal, ModalProvider } from './Modal';
import { Toast, ToastProvider } from './Toast';

export function MainLayout() {
  const location = useLocation();
  const mainContentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    mainContentRef.current &&
      mainContentRef.current.scrollTo({
        top: 0,
        left: 0,
        // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
        behavior: 'instant' as ScrollBehavior,
      });
  }, [location]);

  return (
    <RightDrawerProvider>
      <ModalProvider>
        <ToastProvider>
          <LeftNav />
          <main
            ref={mainContentRef}
            className="pl-[230px] xl:pl-[280px] flex flex-col h-screen overflow-y-auto bg-white"
          >
            <div className="px-8 py-6 border-b border-gray-100">
              <Breadcrumb />
            </div>
            <ErrorBoundary key={location.pathname}>
              <Outlet />
            </ErrorBoundary>
          </main>
          <RightDrawer />
          <Modal />
          <Toast />
        </ToastProvider>
      </ModalProvider>
    </RightDrawerProvider>
  );
}
