import React, { useEffect, useRef } from 'react';
import { LeftNav } from '../nav/LeftNav';
import { RightDrawer, RightDrawerProvider } from './RightDrawer';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';
import { Modal, ModalProvider } from './Modal';
import { Toast, ToastProvider } from './Toast';
import {
  ProContextProvider,
  useProContext,
} from '../../providers/ProContextProvider';
import clsx from 'clsx';
import { LeftDrawer, LeftDrawerProvider } from './LeftDrawer';

export function ProLayout() {
  const location = useLocation();
  const mainContentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    mainContentRef.current &&
      mainContentRef.current.scrollTo({
        top: 0,
        left: 0,
        // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
        behavior: 'instant' as ScrollBehavior,
      });
  }, [location]);

  return (
    <ToastProvider>
      <ProContextProvider>
        <RightDrawerProvider>
          <LeftDrawerProvider>
            <ModalProvider>
              <LeftNav />
              <LeftDrawer />
              <ProInnerLayout mainContentRef={mainContentRef} />
              <RightDrawer />
              <Modal />
              <Toast />
            </ModalProvider>
          </LeftDrawerProvider>
        </RightDrawerProvider>
      </ProContextProvider>
    </ToastProvider>
  );
}

function ProInnerLayout({
  mainContentRef,
}: {
  mainContentRef: React.RefObject<HTMLElement>;
}) {
  const location = useLocation();
  const proContext = useProContext();
  return (
    <main
      ref={mainContentRef}
      className={clsx(
        'flex flex-col h-screen overflow-y-auto bg-white transition-all duration-300 ease-in-out',
        proContext?.isNavCollapsed ? 'pl-[0px]' : 'pl-[230px] xl:pl-[280px]',
      )}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </main>
  );
}
