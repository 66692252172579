import {
  CatalystFieldsFragment,
  RoadmapFieldsFragment,
} from '../../../graphql/generated';
import React from 'react';
import { CatalystTitle } from './CatalystTitle';
import { sortByNullablePosition } from '../../../services/UtilsService';
import { ActionList } from './ActionList';

export function RoadmapListView({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  return (
    <div className="flex flex-col divide-y divide-gray-100 space-y-12">
      {roadmap.catalysts
        .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
        .map((catalyst, index) => (
          <div key={catalyst.id} className="pt-6">
            <Catalyst catalyst={catalyst} index={index} />
          </div>
        ))}
    </div>
  );
}

function Catalyst({
  catalyst,
  index,
}: {
  catalyst: CatalystFieldsFragment;
  index: number;
}) {
  return (
    <>
      <CatalystTitle catalyst={catalyst} index={index} />
      <div className="mt-2">
        <ActionList actions={catalyst.actions} />
      </div>
    </>
  );
}
