import React from 'react';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import {
  DisclosureRequirement_ArFragment,
  DisclosureRequirement_DrLevel2Fragment,
  DisclosureRequirement_DrLevel3Fragment,
  DisclosureRequirement_MdrFragment,
  DisclosureRequirement_MdrLevel1Fragment,
  DisclosureRequirement_MdrLevel2Fragment,
  DisclosureRequirement_MdrLevel3Fragment,
  DisclosureRequirementFragment,
  useDisclosureRequirementQuery,
} from '../../../graphql/cms/generated';
import { ChevronDownIcon } from '../../icons';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';
import { Footnote } from '../components/Footnote';
import { Guidance } from '../components/Guidance';
import { DatapointTag } from '../components/DatapointTag';
import { BlocksRenderer } from '../renderers/BlocksRenderer';
import { LoaderFullscreen } from '../../layout/Loader';

export function DisclosureRequirement({ documentId }: { documentId: string }) {
  const query = useDisclosureRequirementQuery({
    variables: { documentId: documentId },
  });
  const document = query.data?.disclosureRequirement || null;

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="w-full">
      {document ? (
        <DisclosureRequirementContent document={document} />
      ) : (
        <MessageBox type={MessageBoxType.Error}>No document found</MessageBox>
      )}
    </div>
  );
}

function DisclosureRequirementContent({
  document,
}: {
  document: DisclosureRequirementFragment;
}) {
  const { t } = useTranslation();
  const level2Dr = document.content?.dr_level_2?.filter(notEmpty) || [];
  const applicationRequirements =
    document.application_requirements?.filter(notEmpty) || [];
  const mdrs = document.minimum_disclosure_requirements?.filter(notEmpty) || [];
  const guidances = document.guidances?.filter(notEmpty) || [];

  const [drLevel2Expanded, setDrLevel2Expanded] = React.useState(true);
  const [arExpanded, setArExpanded] = React.useState(true);
  const [guidanceExpanded, setGuidanceExpanded] = React.useState(true);
  const [mdrExpanded, setMdrExpanded] = React.useState(true);

  return (
    <div className="space-y-8">
      <h1>
        {document.code} {document.title}
      </h1>
      <div className="space-y-4 pl4">
        {level2Dr.length > 0 && (
          <h2 className="flex items-center gap-2">
            <div>
              {t(
                'disclosure_requirements.disclosure_requirement.disclosures_requirements',
              )}
            </div>
            <button
              className="tertiary"
              onClick={() => setDrLevel2Expanded(!drLevel2Expanded)}
            >
              <ChevronDownIcon
                className={clsx(drLevel2Expanded && 'rotate-180')}
              />
            </button>
          </h2>
        )}
        {drLevel2Expanded && (
          <div className="space-y-4">
            {level2Dr.map((drLevel2, index) => (
              <DisclosureRequirementLevel2Content
                drLevel2={drLevel2}
                key={`${document.documentId}_${index}`}
              />
            ))}
          </div>
        )}
      </div>
      <div className="space-y-4">
        {applicationRequirements.length > 0 && (
          <h2 className="flex items-center gap-2">
            <div>
              {t(
                'disclosure_requirements.disclosure_requirement.application_requirements',
              )}
            </div>
            <button
              className="tertiary"
              onClick={() => setArExpanded(!arExpanded)}
            >
              <ChevronDownIcon className={clsx(arExpanded && 'rotate-180')} />
            </button>
          </h2>
        )}
        {arExpanded && (
          <div className="space-y-4">
            {applicationRequirements.map((applicationRequirement, index) => (
              <ApplicationRequirementContent
                applicationRequirement={applicationRequirement}
                key={`${document.documentId}_ar_${index}`}
              />
            ))}
          </div>
        )}
      </div>

      <div className="space-y-4">
        {mdrs.length > 0 && (
          <h2 className="flex items-center gap-2">
            <div>
              {t(
                'disclosure_requirements.disclosure_requirement.minimum_disclosure_requirements',
              )}
            </div>
            <button
              className="tertiary"
              onClick={() => setMdrExpanded(!mdrExpanded)}
            >
              <ChevronDownIcon className={clsx(mdrExpanded && 'rotate-180')} />
            </button>
          </h2>
        )}
        {mdrExpanded && (
          <div className="divide-y divide-gray-100 space-y-4">
            {mdrs.map((mdr, index) => (
              <DisclosureRequirementMdr
                mdr={mdr}
                key={`${document.documentId}_mdr_${index}`}
              />
            ))}
          </div>
        )}
      </div>

      <div className="space-y-4">
        {guidances.length > 0 && (
          <h2 className="flex items-center gap-2">
            <div>
              {t('disclosure_requirements.disclosure_requirement.guidelines')}
            </div>
            <button
              className="tertiary"
              onClick={() => setGuidanceExpanded(!guidanceExpanded)}
            >
              <ChevronDownIcon
                className={clsx(guidanceExpanded && 'rotate-180')}
              />
            </button>
          </h2>
        )}
        {guidanceExpanded && (
          <div className="divide-y divide-gray-100 space-y-4">
            {guidances.map((guidance, index) => (
              <Guidance
                guidance={guidance}
                key={`${document.documentId}_guidance_${index}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function DisclosureRequirementLevel2Content({
  drLevel2,
}: {
  drLevel2: DisclosureRequirement_DrLevel2Fragment;
}) {
  const level3Dr = drLevel2.dr_level_3?.filter(notEmpty) || [];
  const footnotes = drLevel2.footnotes?.filter(notEmpty) || [];
  return (
    <div>
      <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
        <div className="space-y-4 w-2/3">
          <BlocksRenderer content={drLevel2.content} />
          {footnotes.map((footnote, index) => (
            <Footnote footnote={footnote} key={`footnote_${index}`} />
          ))}
        </div>
        <div className="flex items-center gap-2 flex-wrap w-1/3 pl-4">
          {drLevel2.datapoints.filter(notEmpty).map((datapoint) => (
            <DatapointTag datapoint={datapoint} key={datapoint.documentId} />
          ))}
        </div>
      </div>
      <div className="space-y-2">
        {level3Dr.map((drLevel3, index) => (
          <DisclosureRequirementLevel3Content
            drLevel3={drLevel3}
            key={`dr_level3_${index}`}
          />
        ))}
      </div>
    </div>
  );
}

function DisclosureRequirementLevel3Content({
  drLevel3,
}: {
  drLevel3: DisclosureRequirement_DrLevel3Fragment;
}) {
  const footnotes = drLevel3.footnotes?.filter(notEmpty) || [];
  return (
    <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
      <div className="space-y-2 w-2/3 pl-4">
        <BlocksRenderer content={drLevel3.content} />
        {footnotes.map((footnote, index) => (
          <Footnote footnote={footnote} key={`footnote_${index}`} />
        ))}
      </div>
      <div className="flex items-center gap-2 flex-wrap w-1/3 pl-4">
        {drLevel3.datapoints.filter(notEmpty).map((datapoint) => (
          <DatapointTag datapoint={datapoint} key={datapoint.documentId} />
        ))}
      </div>
    </div>
  );
}

function ApplicationRequirementContent({
  applicationRequirement,
}: {
  applicationRequirement: DisclosureRequirement_ArFragment;
}) {
  const footnotes = applicationRequirement.footnotes?.filter(notEmpty) || [];
  return (
    <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
      <div className="space-y-2 w-2/3">
        <BlocksRenderer content={applicationRequirement.content} />
        {footnotes.map((footnote, index) => (
          <Footnote footnote={footnote} key={`footnote_${index}`} />
        ))}
      </div>
      <div className="flex items-center gap-2 flex-wrap w-1/3 pl-4">
        {applicationRequirement.datapoints.filter(notEmpty).map((datapoint) => (
          <DatapointTag datapoint={datapoint} key={datapoint.documentId} />
        ))}
      </div>
    </div>
  );
}

function DisclosureRequirementMdr({
  mdr,
}: {
  mdr: DisclosureRequirement_MdrFragment;
}) {
  const mdrLevel1 = mdr.content;
  return (
    <div className="space-y-4 pt-4">
      {mdrLevel1 && <DisclosureRequirementMdrLevel1 mdrLevel1={mdrLevel1} />}
    </div>
  );
}

function DisclosureRequirementMdrLevel1({
  mdrLevel1,
}: {
  mdrLevel1: DisclosureRequirement_MdrLevel1Fragment;
}) {
  const { t } = useTranslation();
  const mdrLevel2: DisclosureRequirement_MdrLevel2Fragment[] =
    mdrLevel1.mdr_level_2?.filter(notEmpty) || [];
  const ar: DisclosureRequirement_ArFragment[] =
    mdrLevel1.application_requirements?.filter(notEmpty) || [];
  return (
    <div className="space-y-4 pt-4">
      {mdrLevel2.map((mdrLevel2, index) => (
        <DisclosureRequirementMdrLevel2
          mdrLevel2={mdrLevel2}
          key={`mdr_level2_${index}`}
        />
      ))}
      {ar.length > 0 && (
        <h2>
          {t(
            'disclosure_requirements.disclosure_requirement.application_requirements',
          )}
        </h2>
      )}
      {ar.map((applicationRequirement, index) => (
        <ApplicationRequirementContent
          applicationRequirement={applicationRequirement}
          key={`mar_application_requirements_${index}`}
        />
      ))}
    </div>
  );
}

function DisclosureRequirementMdrLevel2({
  mdrLevel2,
}: {
  mdrLevel2: DisclosureRequirement_MdrLevel2Fragment;
}) {
  const mdrLevel3: DisclosureRequirement_MdrLevel3Fragment[] =
    mdrLevel2.mdr_level_3?.filter(notEmpty) || [];
  return (
    <div className="">
      <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
        <div className="space-y-2 w-2/3 pl-4 hover:bg-gray-50">
          <BlocksRenderer content={mdrLevel2.content} />
        </div>
      </div>
      <div className="space-y-2 pl-4">
        {mdrLevel3.map((mdrLevel3, index) => (
          <DisclosureRequirementMdrLevel3
            mdrLevel3={mdrLevel3}
            key={`mdr_level3_${index}`}
          />
        ))}
      </div>
    </div>
  );
}

function DisclosureRequirementMdrLevel3({
  mdrLevel3,
}: {
  mdrLevel3: DisclosureRequirement_MdrLevel3Fragment;
}) {
  return (
    <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
      <div className="space-y-2 w-2/3 pl-4">
        <BlocksRenderer content={mdrLevel3.content} />
      </div>
      <div className="flex items-center gap-2 flex-wrap w-1/3 pl-4">
        {mdrLevel3.datapoints.filter(notEmpty).map((datapoint) => (
          <DatapointTag datapoint={datapoint} key={datapoint.documentId} />
        ))}
      </div>
    </div>
  );
}

// Until we upgrade to Typescript 5.5, we need to use this function to filter out null and undefined values
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
