import React, { useState } from 'react';
import {
  RoadmapFieldsFragment,
  useRoadmapUpdateMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import { Loader } from '../../generic/Loader';

export function UpdateVisionModal({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  const toast = useToast();
  const modal = useModal();

  const [title, setTitle] = useState(roadmap.vision?.title);
  const [description, setDescription] = useState(
    roadmap.vision?.description || '',
  );
  const [quote, setQuote] = useState(roadmap.vision?.quote || '');

  const [updateRoadmap] = useRoadmapUpdateMutation();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateVision = () => {
    setIsUpdating(true);
    updateRoadmap({
      variables: {
        input: {
          id: roadmap.id,
          vision: {
            title: title,
            description: description,
            quote: quote,
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Vision mise à jour');
        modal.closeModal();
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-5xl">
      <div className="flex flex-col gap-1 w-96">
        <label htmlFor="title" className="form-input-label">
          Vision
        </label>
        <input
          type="text"
          id="title"
          className="form-input-text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label htmlFor="description" className="form-input-label">
          Description
        </label>
        <textarea
          id="description"
          className="form-input-text h-48"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label htmlFor="quote" className="form-input-label">
          Citation
        </label>
        <textarea
          id="quote"
          className="form-input-text h-24"
          value={quote}
          onChange={(e) => setQuote(e.target.value)}
        />
      </div>

      <div className="flex gap-2 justify-end">
        <button
          className="primary"
          onClick={() => updateVision()}
          disabled={isUpdating || !title}
        >
          {isUpdating ? <Loader /> : 'Modifier'}
        </button>
      </div>
    </div>
  );
}
