import { CompanySize } from '../../../graphql/generated';
import StringDropdown from '../../generic/form/StringDropdown';
import React from 'react';

export function CompanySizeDropdown({
  size,
  setSize,
}: {
  size: CompanySize | null | undefined;
  setSize: (size: CompanySize) => void;
}) {
  const availableSizes = [
    { id: CompanySize.Micro, label: 'TPE / Microentreprise (<10 salariés)' },
    { id: CompanySize.Small, label: 'PME (de 10 à 250 salariés)' },
    { id: CompanySize.Medium, label: 'ETI (de 250 à 5 000 salariés)' },
    {
      id: CompanySize.Large,
      label: 'Grande Entreprise (de 5 000 à 10 000 salariés)',
    },
  ];

  return (
    <StringDropdown
      availableItems={availableSizes}
      item={availableSizes.find((item) => item.id === size) || null}
      setItem={(item) => {
        const enumIndex = Object.keys(CompanySize).indexOf(item.id);
        setSize(Object.values(CompanySize)[enumIndex]);
      }}
    />
  );
}
