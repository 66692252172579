import { StrapiApolloProvider } from '../../components/cms/StrapiApolloProvider';
import { DisclosureRequirements } from '../../components/cms/collections/DisclosureRequirements';
import React from 'react';

export function DisclosureRequirementsScreen() {
  return (
    <StrapiApolloProvider>
      <DisclosureRequirements />
    </StrapiApolloProvider>
  );
}
