import React, { useEffect, useState } from 'react';
import { Toggle } from '../../generic/form/Toggle';
import { useToast } from '../../layout/Toast';
import { useProContext } from '../../../providers/ProContextProvider';
import { CompanySizeDropdown } from './CompanySizeDropdown';
import { CompanySectorDropdown } from './CompanySectorDropdown';
import { useNavigate } from 'react-router-dom';
import { CompanyLogoInput } from './CompanyLogoInput';
import { DeleteCompanyButton } from './DeleteCompanyButton';
import { LoaderFullscreen } from '../../layout/Loader';
import {
  CompanyUpdateInfo_CompanyFragment,
  useCompanyUpdateInfo_CompanyQuery,
  useCompanyUpdateInfo_CompanyUpdateMutation,
} from '../../../graphql/generated';

export function CompanyUpdateInfo({ companyId }: { companyId: string }) {
  const navigate = useNavigate();

  const companyQuery = useCompanyUpdateInfo_CompanyQuery({
    variables: {
      id: companyId,
    },
  });

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      <h2 className="title-h4">À propos de l&apos;entreprise</h2>
      <div className="space-y-8 divide-y divide-gray-100">
        <div className="grid grid-cols-2 gap-8 divide-x divide-gray-100">
          {companyQuery.data?.company && (
            <CompanySettingsForm company={companyQuery.data.company} />
          )}

          <div className="pl-4">
            {companyQuery.data?.company && (
              <CompanyLogoInput company={companyQuery.data.company} />
            )}
          </div>
        </div>

        <div className="py-8">
          {companyQuery.data?.company && (
            <DeleteCompanyButton
              company={companyQuery.data.company}
              callback={() => navigate('/pro')}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function CompanySettingsForm({
  company,
}: {
  company: CompanyUpdateInfo_CompanyFragment;
}) {
  const toast = useToast();
  const proContext = useProContext();

  const [updateCompanyMutation] = useCompanyUpdateInfo_CompanyUpdateMutation();

  const [name, setName] = useState<string>(company.name);
  const [sector, setSector] = useState(company.metadata?.sector);
  const [size, setSize] = useState(company.metadata?.size);
  const [isSubsidiary, setIsSubsidiary] = useState(
    company.metadata?.isSubsidiary || false,
  );
  const [annualTurnover, setAnnualTurnover] = useState(
    company.metadata?.annualTurnover || '',
  );

  // TODO: we should not use useEffect here
  useEffect(() => {
    setName(company.name);
    setSector(company.metadata?.sector);
    setSize(company.metadata?.size);
    setIsSubsidiary(company.metadata?.isSubsidiary || false);
    setAnnualTurnover(company.metadata?.annualTurnover || '');
  }, [company]);

  const saveCompany = () => {
    updateCompanyMutation({
      variables: {
        input: {
          id: company.id,
          name,
          metadata: {
            sector,
            size,
            isSubsidiary,
            annualTurnover,
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Informations mises à jour');
        proContext?.refreshCompany();
      })
      .catch((err: { message: string }) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <label htmlFor="name" className="form-input-label">
          Nom / Raison sociale
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="form-input-text"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>

      <div className="flex flex-col gap-2">
        <label htmlFor="sector" className="form-input-label">
          Taille de la structure diagnostiquée
        </label>
        <CompanySizeDropdown size={size} setSize={setSize} />
      </div>

      <div className="flex flex-col gap-2">
        <label htmlFor="sector" className="form-input-label">
          Secteur d&apos;activité
        </label>
        <CompanySectorDropdown sector={sector} setSector={setSector} />
      </div>

      <div className="flex flex-col gap-2">
        <label htmlFor="annualTurnOver" className="form-input-label">
          Chiffre d&apos;affaires annuel
        </label>
        <input
          type="text"
          name="annualTurnOver"
          id="annualTurnOver"
          className="form-input-text"
          placeholder={'250k€ - 5M€'}
          value={annualTurnover}
          onChange={(event) => setAnnualTurnover(event.target.value)}
        />
      </div>

      <div className="flex gap-2 items-center">
        <Toggle state={isSubsidiary} setState={setIsSubsidiary} />
        <label htmlFor="isSubsidiary" className="form-input-label">
          Filiale d&apos;un groupe ?
        </label>
      </div>

      <div className="flex justify-end">
        <button className="primary" onClick={saveCompany} disabled={!name}>
          Enregistrer
        </button>
      </div>
    </div>
  );
}
