import {
  ActionSearchTemplateInput,
  ActionTemplatesLinkedToThisStake_ActionFragment,
  ActionTemplatesLinkedToThisStake_StakeFragment,
  ActionTemplateSource,
  AddActionTemplateToFirstCatalyst_ActionFragment,
  useActionTemplatesLinkedToThisStake_SearchActionMutation,
  useAddActionTemplateToFirstCatalyst_ActionCreateMutation,
  useStakeActionsDetailQuery,
} from '../../../graphql/generated';
import React, { useCallback, useState } from 'react';
import { ActionCard } from '../../publications/roadmap/ActionCard';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useModal } from '../../layout/Modal';
import { useProContext } from '../../../providers/ProContextProvider';
import { LoaderFullscreen } from '../../layout/Loader';
import { ActionIllustrationIcon } from '../../publications/roadmap/ActionIllustrationIcon';
import StringDropdown, {
  StringDropdownItem,
} from '../../generic/form/StringDropdown';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { getLatestCompanyRoadmapSummary } from '../../../services/CompanyService';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { CreateRoadmapButton } from '../../pro/roadmap/CreateRoadmap';
import { NavLink } from 'react-router-dom';
import { ArrowRightIcon, PlusIcon, SearchIcon } from '../../icons';

export function StakeActionsDetail({ stakeId }: { stakeId: string }) {
  const modal = useModal();
  const proContext = useProContext();

  const query = useStakeActionsDetailQuery({
    variables: {
      id: stakeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data?.getStakeWithComputedFields) {
    return null;
  }

  const stake = query.data?.getStakeWithComputedFields;

  const roadmapSummary = getLatestCompanyRoadmapSummary(proContext?.company);

  if (!roadmapSummary) {
    // No roadmap -> we need to create one first
    return (
      <MessageBox type={MessageBoxType.Info}>
        <div className="flex items-center gap-2 justify-between w-full">
          <div>
            Vous devez créer une feuille de route pour pouvoir ajouter des
            actions
          </div>
          <CreateRoadmapButton />
        </div>
      </MessageBox>
    );
  }

  const actions = stake.actions?.filter((action) => !action.isTemplate) || [];

  return (
    <div className="p-4 space-y-4">
      <div className="space-y-2">
        <div className="flex items-center gap-4 justify-between">
          <h6>Actions présentes dans la feuille de route</h6>
          <NavLink to={`/pro/roadmap/`} onClick={() => modal.closeModal()}>
            <button className="tertiary small bg-transparent">
              <div>Voir la feuille de route</div>
              <ArrowRightIcon />
            </button>
          </NavLink>
        </div>
        {actions.length > 0 ? (
          <div className="py-4">
            <div className="flex flex-col gap-2">
              {actions
                .filter((action) => !action.isTemplate)
                .map((action) => (
                  <div onClick={() => modal.closeModal()}>
                    <ActionCard action={action} key={action.id} />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <MessageBox type={MessageBoxType.Info}>
            Aucune action liée. Vous pouvez en créer une en allant dans la
            section "Feuille de route"
          </MessageBox>
        )}
      </div>
      <ActionTemplatesLinkedToThisStake stake={stake} />
    </div>
  );
}

function ActionTemplatesLinkedToThisStake({
  stake,
}: {
  stake: ActionTemplatesLinkedToThisStake_StakeFragment;
}) {
  const currentUser = useCurrentUser();

  const [hasSearchBeenTriggered, setHasSearchBeenTriggered] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<
    ActionTemplatesLinkedToThisStake_ActionFragment[]
  >([]);
  const [source, setSource] = useState<ActionTemplateSource>(
    ActionTemplateSource.Goodsteps,
  );

  const [searchActionTemplatesMutation] =
    useActionTemplatesLinkedToThisStake_SearchActionMutation();
  const refreshActionsList = useCallback(
    (searchCriteria: ActionSearchTemplateInput) => {
      setIsSearching(true);
      searchActionTemplatesMutation({
        variables: {
          input: searchCriteria,
        },
      })
        .then((result) => {
          result.data?.searchActionTemplates &&
            setSearchResult(result.data.searchActionTemplates);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => setIsSearching(false));
    },
    [searchActionTemplatesMutation],
  );

  const sourceItems: StringDropdownItem[] = [
    {
      id: ActionTemplateSource.Goodsteps,
      label: 'Goodsteps',
    },
    {
      id: ActionTemplateSource.OwnTemplates,
      label: 'Mes modèles',
    },
  ];
  const sourceItem = sourceItems.find((item) => item.id === source) || null;
  const setSourceItem = (item: StringDropdownItem) => {
    if (item) {
      setSource(item.id as ActionTemplateSource);
      search(item.id as ActionTemplateSource);
    }
  };

  const search = (source: ActionTemplateSource) => {
    if (stake.origin?.id) {
      refreshActionsList({
        companyId: currentUser?.company?.id || '',
        source: source,
        stake: {
          id: stake.origin.id,
        },
      });
    } else {
      setSearchResult([]);
    }
  };

  if (isSearching) {
    return <LoaderFullscreen />;
  }

  if (!hasSearchBeenTriggered) {
    return (
      <div className="flex items-center justify-center">
        <button
          className="primary small"
          onClick={() => {
            setHasSearchBeenTriggered(true);
            search(ActionTemplateSource.Goodsteps);
          }}
        >
          <SearchIcon />
          <div>Trouver des modèles d'actions</div>
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h6>Modèles d'actions</h6>
        <div className="flex items-center gap-2">
          <label className="text-sm font-bold">Chercher dans</label>
          <StringDropdown
            availableItems={sourceItems}
            item={sourceItem}
            setItem={setSourceItem}
          />
        </div>
      </div>
      <div>
        {searchResult.length > 0 ? (
          <div className="flex flex-col gap-2">
            {searchResult.map((action) => (
              <div className="flex flex-col items-center gap-4 w-full rounded-xl bg-white border-2 border-transparent">
                <div className="flex flex-col items-center w-full grow">
                  <div className="flex items-center gap-2 w-full grow p-1">
                    <ActionIllustrationIcon action={action} />
                    <div className="text-sm font-semibold grow">
                      {action.title}
                    </div>
                    <AddActionTemplateToFirstCatalyst actionTemplate={action} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <MessageBox type={MessageBoxType.Info}>
            Aucun modèle trouvé
          </MessageBox>
        )}
      </div>
    </div>
  );
}

function AddActionTemplateToFirstCatalyst({
  actionTemplate,
}: {
  actionTemplate: AddActionTemplateToFirstCatalyst_ActionFragment;
}) {
  const toast = useToast();

  // Fetch first catalyst
  const proContext = useProContext();
  const roadmapSummary = getLatestCompanyRoadmapSummary(proContext?.company);
  const catalyst = roadmapSummary?.catalysts[0];

  const [addAction] =
    useAddActionTemplateToFirstCatalyst_ActionCreateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);
    addAction({
      variables: {
        input: {
          actionTemplateId: actionTemplate.id,
          roadmapId: roadmapSummary?.id || '',
          catalystId: catalyst?.id || '',
          companyId: proContext?.company?.id || '',
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Action ajoutée');
      })
      .catch((err) => console.error(err))
      .finally(() => setIsAdding(false));
  };

  return (
    <button className="small tertiary" onClick={add} disabled={isAdding}>
      {isAdding ? <Loader /> : <PlusIcon />}
      <div>Ajouter</div>
    </button>
  );
}
