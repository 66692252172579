import React from 'react';
import { RoadmapFieldsFragment } from '../../../graphql/generated';
import { useModal } from '../../layout/Modal';
import { RoadmapListView } from './RoadmapListView';
import { UpdateVisionModal } from './UpdateVisionModal';
import { EditLinkToQuoteFile } from './EditLinkToQuoteFile';
import { QuoteWhiteIcon } from '../../icons';

export function EditRoadmap({ roadmap }: { roadmap: RoadmapFieldsFragment }) {
  return <RoadmapContent roadmap={roadmap} />;
}

function RoadmapContent({ roadmap }: { roadmap: RoadmapFieldsFragment }) {
  return (
    <div className="pb-10">
      <RoadmapHeader roadmap={roadmap} />

      <div className="main-content w-full pb-10">
        <RoadmapListView roadmap={roadmap} />
        <EditLinkToQuoteFile roadmap={roadmap} />
      </div>
    </div>
  );
}

function RoadmapHeader({ roadmap }: { roadmap: RoadmapFieldsFragment }) {
  const modal = useModal();

  return (
    <div className="bg-blue-50 p-12">
      <div className="flex items-start justify-center gap-8 main-content">
        <div className="grow space-y-4">
          <p className="text-gray-500 text-sm">{roadmap?.vision?.title}</p>
          <h2>{roadmap?.vision?.description}</h2>
          <button
            className="primary purple"
            onClick={() =>
              modal.openModalWithComponent(
                <UpdateVisionModal roadmap={roadmap} />,
                'Vision',
              )
            }
          >
            Modifier
          </button>
        </div>
        {roadmap?.vision?.quote && (
          <div className="flex gap-4 p-4 rounded-xl bg-white shadow-sm max-w-lg">
            <div className="flex shrink-0">
              <QuoteWhiteIcon className="inline-block h-10 w-10 rounded-full" />
            </div>
            <div className="font-title font-extrabold">
              {roadmap?.vision?.quote}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
