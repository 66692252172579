import RichTextEditorTextarea from '../../../../../generic/form/RichTextEditorTextarea';
import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useUpdateIroCommentMutation } from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import { useModal } from '../../../../../layout/Modal';
import { Loader } from '../../../../../generic/Loader';

interface IroCommentFormProps {
  id: string;
  initialComment?: string | null;
}

export const IroCommentForm = ({ id, initialComment }: IroCommentFormProps) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(initialComment || '');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const modal = useModal();

  const [update] = useUpdateIroCommentMutation();
  const handleSubmit = () => {
    setIsLoading(true);
    update({
      variables: {
        input: {
          id: id,
          comment,
        },
      },
    })
      .then(() => {
        modal.closeModal();
        toast.openToastWithMessage(t('toast:iro.comment.success'));
      })
      .catch(() => {
        toast.openToastWithMessage(t('toast:iro.comment.error'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      key={`iro-comment-${id}`}
      className="flex flex-col items-stretch gap-4"
    >
      <RichTextEditorTextarea
        inlined={true}
        value={comment}
        onChange={(value) => setComment(value)}
      />
      <button
        className="primary small"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading && <Loader />}
        {t('iro.comment.cta')}
      </button>
    </div>
  );
};
