import React from 'react';
import { RoadmapFieldsFragment } from '../../../graphql/generated';
import { titleFromPriorityLevel } from '../../badges/PriorityBadge';
import { complexityLevelToNumber } from '../../badges/ComplexityBadge';
import { priceLevelToNumber } from '../../badges/PriceBadge';
import { useTranslation } from '@hooks/useTranslation';
import { TFunction } from 'i18next';

export function DownloadRoadmapButton({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  const { t } = useTranslation();
  const downloadRoadmap = () => {
    const roadmapAsCsv = getRoadmapAsCsv(roadmap, t);
    downloadFile(roadmapAsCsv, 'roadmap.csv', 'text/csv');
  };

  return (
    <button onClick={downloadRoadmap} className="primary">
      Exporter en CSV
    </button>
  );
}

function getRoadmapAsCsv(
  roadmap: RoadmapFieldsFragment,
  t: TFunction<'translation', undefined>,
): string {
  const csvRows = [
    [
      'Axe stratégique',
      'Pilier',
      'Priorité',
      'Complexité',
      'Coût',
      'Action',
      'Description',
      'Bénéfices',
      'Métriques',
      'Suggestions',
      'Ressources',
    ],
  ];

  roadmap.catalysts.forEach((catalyst) => {
    catalyst.actions.forEach((action) => {
      csvRows.push([
        catalyst.title,
        t('enum:theme.'.concat(action.theme.toLowerCase())),
        titleFromPriorityLevel(action.priorityLevel),
        complexityLevelToNumber(action.complexityLevel).toString(),
        priceLevelToNumber(action.priceLevel).toString(),
        action.title,
        action.description ? removeHtmlTags(action.description) : '',
        action.benefits ? `<ul>${arrayToHtmlList(action.benefits)}</ul>` : '',
        action.metrics
          ? `<ul>${arrayToHtmlList(
              action.metrics.map((metric) => metric.title),
            )}</ul>`
          : '',
        action.solutions
          ? `<ul>${arrayToHtmlList(
              action.solutions.map(
                (solution) => `${solution.title} ${solution.description}`,
              ),
            )}</ul>`
          : '',
        action.resources
          ? `<ul>${arrayToHtmlList(
              action.resources.map((resource) => resource.title),
            )}</ul>`
          : '',
      ]);
    });
  });
  return csvRows
    .map((row) => row.map((cell) => `"${cell}"`).join(';'))
    .join('\n');
}

const removeHtmlTags = (str: string) => str.replace(/<[^>]*>?/gm, '');
const removeQuotes = (str: string) => str.replace(/"/gm, '');
const arrayToHtmlList = (arrayOfItems: string[]) =>
  arrayOfItems.reduce(
    (acc, item) => `${acc}<li>${removeQuotes(item)}</li>`,
    '',
  );
const downloadFile = (data: string, fileName: string, fileType: string) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
