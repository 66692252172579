import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  DeleteSurveyButtonFragment,
  DuplicateSurveyButtonFragment,
  SurveyContextualMenu_SurveysDocument,
  SurveyContextualMenuFragment,
  SurveysDocument,
  useSurveyContextualMenu_DeleteMutation,
  useSurveyDuplicateMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { NavLink } from 'react-router-dom';
import { SURVEY_TABS } from './ProSurvey';
import { useProContext } from '../../../providers/ProContextProvider';
import {
  ConfirmationModal,
  ConfirmationModalType,
} from '../../modals/ConfirmationModal';
import { useModal } from '../../layout/Modal';
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  ScaleIcon,
  SettingsIcon,
  ShareIcon,
} from '../../icons';
import { ContextualMenu } from '../../generic/ContextualMenu';

export function SurveyContextualMenu({
  survey,
}: {
  survey: SurveyContextualMenuFragment;
}) {
  const { t } = useTranslation();

  return (
    <ContextualMenu>
      <NavLink
        to={`/pro/survey/${survey.id}/${SURVEY_TABS.SURVEY_CONTENT}`}
        className="contextual-menu-item button"
      >
        <EditIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.content')}</div>
      </NavLink>
      <NavLink
        to={`/pro/survey/${survey.id}/${SURVEY_TABS.SURVEY_SHARE}`}
        className="contextual-menu-item button"
      >
        <ShareIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.share')}</div>
      </NavLink>
      <NavLink
        to={`/pro/survey/${survey.id}/${SURVEY_TABS.SURVEY_TRACKING_RESPONSES}`}
        className="contextual-menu-item button"
      >
        <EyeIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.follow')}</div>
      </NavLink>
      <NavLink
        to={`/pro/survey/${survey.id}/${SURVEY_TABS.SURVEY_RESULTS}`}
        className="contextual-menu-item button"
      >
        <ScaleIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.results')}</div>
      </NavLink>
      <NavLink
        to={`/pro/survey/${survey.id}/${SURVEY_TABS.SETTINGS}`}
        className="contextual-menu-item button"
      >
        <SettingsIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.settings')}</div>
      </NavLink>
      <hr className="border-t h-1 w-full mt-1 min-w-40" />
      <DuplicateSurveyButton survey={survey} callback={close} />
      <DeleteSurveyButton survey={survey} callback={close} />
    </ContextualMenu>
  );
}

function DuplicateSurveyButton({
  survey,
  callback,
}: {
  survey: DuplicateSurveyButtonFragment;
  callback: () => void;
}) {
  const toast = useToast();
  const proContext = useProContext();

  const [duplicateSurveyMutation] = useSurveyDuplicateMutation({
    refetchQueries: () => [
      {
        query: SurveysDocument,
        variables: {
          companyId: proContext?.company?.id || '',
        },
      },
    ],
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const duplicate = () => {
    setIsUpdating(true);
    duplicateSurveyMutation({
      variables: {
        id: survey.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Le questionnaire a bien été dupliqué');
        callback();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsUpdating(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isUpdating}
      onClick={duplicate}
    >
      {isUpdating ? <Loader /> : <CopyIcon />}
      <div>Dupliquer</div>
    </button>
  );
}

function DeleteSurveyButton({
  survey,
  callback,
}: {
  survey: DeleteSurveyButtonFragment;
  callback: () => void;
}) {
  const toast = useToast();
  const modal = useModal();
  const proContext = useProContext();

  const [deleteSurveyMutation] = useSurveyContextualMenu_DeleteMutation({
    refetchQueries: () => [
      {
        query: SurveyContextualMenu_SurveysDocument,
        variables: {
          companyId: proContext?.company?.id || '',
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSurvey = () => {
    setIsDeleting(true);
    deleteSurveyMutation({
      variables: {
        id: survey.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Le questionnaire a bien été supprimé');
        callback();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsDeleting(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message="Cela supprimera également les réponses et analyse liés à cette consultation. Cette opération est irréversible !"
            callbackIfConfirmed={deleteSurvey}
            type={ConfirmationModalType.Danger}
          />,
          `Supprimer la consultation`,
        )
      }
    >
      {isDeleting ? <Loader /> : <DeleteIcon />}
      <div>Supprimer</div>
    </button>
  );
}
