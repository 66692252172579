import React, { useState } from 'react';
import {
  UpdateTaskModal_SubTaskFragment,
  UpdateTaskModal_TaskFragment,
  useUpdateTaskModal_DeleteMutation,
  useUpdateTaskModal_SurveysQuery,
  useUpdateTaskModal_UpdateMutation,
} from '../../graphql/generated';
import { Loader } from '../generic/Loader';
import { useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { NumberCircle } from '../generic/NumberCircle';
import StringDropdown, {
  StringDropdownItem,
} from '../generic/form/StringDropdown';
import { ProRoutes } from '../../screens/ScreensRouter';
import { useProContext } from '../../providers/ProContextProvider';
import { DeleteIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

export function UpdateTaskModal({
  task,
}: {
  task: UpdateTaskModal_TaskFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();
  const proContext = useProContext();

  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description || '');
  const [assignedTo, setAssignedTo] = useState(task.assignedTo || '');
  const [deliverable, setDeliverable] = useState(task.deliverable || '');
  const [startedAt, setStartedAt] = useState(
    task.startedAt?.split('T')[0] || '',
  );
  const [finishedAt, setFinishedAt] = useState(
    task.finishedAt?.split('T')[0] || '',
  );
  const [subtasks, setSubtasks] = useState<UpdateTaskModal_SubTaskFragment[]>(
    task.subtasks || [],
  );
  const [linkToPage, setLinkToPage] = useState<string>(task.linkToPage || '');
  const [linkToSurveyId, setLinkToSurveyId] = useState<string>(
    task.linkToSurvey?.id || '',
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [updateTaskMutation] = useUpdateTaskModal_UpdateMutation();
  const [deleteTaskMutation] = useUpdateTaskModal_DeleteMutation();
  const deleteTask = () => {
    setIsDeleting(true);
    deleteTaskMutation({ variables: { id: task.id } })
      .then(() => {
        toast.openToastWithMessage('Tâche supprimée');
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  const updateTask = () => {
    setIsUpdating(true);
    updateTaskMutation({
      variables: {
        input: {
          id: task.id,
          title: title,
          description,
          assignedTo,
          deliverable,
          startedAt: startedAt || null,
          finishedAt: finishedAt || null,
          subtasks,
          linkToPage,
          linkToSurvey: linkToSurveyId ? { id: linkToSurveyId } : null,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Tâche mise à jour');
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsUpdating(false));
  };

  const addSubtask = () => {
    setSubtasks([
      ...subtasks,
      {
        __typename: 'Subtask',
        title: '',
        done: false,
      },
    ]);
  };

  const availablePages: StringDropdownItem[] = [
    { id: '', label: t('global:none') },
    { id: ProRoutes.Stakes, label: t('nav:page.referential') },
    {
      id: ProRoutes.StakeholderMapping,
      label: t('nav:page.stakeholder_mapping'),
    },
    { id: ProRoutes.Iro, label: t('nav:page.iro_rating') },
    { id: ProRoutes.PublicationMaturity, label: t('nav:page.maturity_score') },
    {
      id: ProRoutes.PublicationPrioritizationMatrix,
      label: t('nav:page.prioritization_matrix'),
    },
    {
      id: ProRoutes.PublicationMaterialityMatrix,
      label: t('nav:page.materiality_matrix'),
    },
    {
      id: ProRoutes.PublicationDoubleMaterialityMatrix,
      label: t('nav:page.double_materiality_matrix'),
    },
    { id: ProRoutes.Roadmap, label: t('nav:page.roadmap') },
    { id: ProRoutes.Survey, label: t('nav:page.surveys') },
    {
      id: ProRoutes.MaturityEvaluations,
      label: t('nav:page.maturity_evaluation'),
    },
    { id: ProRoutes.ProjectUsers, label: 'Paramètres -> Utilisateurs' },
    { id: ProRoutes.ProjectCompany, label: 'Paramètres -> Entreprise' },
  ];

  const surveysDocumentQuery = useUpdateTaskModal_SurveysQuery({
    variables: {
      companyId: proContext?.company?.id ?? '',
    },
    skip: !proContext?.company?.id,
    fetchPolicy: 'network-only',
  });
  const availableSurveys: StringDropdownItem[] = (
    surveysDocumentQuery.data?.surveys || []
  )
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .map((survey) => ({
      id: survey.id,
      label: survey.name,
    }));
  availableSurveys.unshift({ id: 'none', label: '-' });

  return (
    <div className="flex flex-col gap-4 max-w-5xl min-w-[600px]">
      <div className="flex flex-col gap-1">
        <label htmlFor="title" className="form-input-label">
          Nom
        </label>
        <input
          type="text"
          id="title"
          className="form-input-text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label htmlFor="description" className="form-input-label">
          Description
        </label>
        <textarea
          id="description"
          className="form-input-text h-24"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <hr />
      <div className="flex gap-4 items-start">
        <div className="w-1/2 flex flex-col gap-4">
          <label htmlFor="description" className="form-input-label">
            Lier à une page
          </label>
          <StringDropdown
            item={availablePages.find((page) => page.id === linkToPage) || null}
            availableItems={availablePages}
            setItem={(item) => {
              if (item.id) {
                setLinkToPage(item.id);
              }
            }}
          />
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <label htmlFor="description" className="form-input-label">
            Lier à un questionnaire
          </label>
          <StringDropdown
            item={
              availableSurveys.find((survey) => survey.id === linkToSurveyId) ||
              null
            }
            availableItems={availableSurveys}
            setItem={(item) => {
              if (item.id) {
                setLinkToSurveyId(item.id);
              }
            }}
          />
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <label className="form-input-label">Étapes</label>
        <Subtasks subtasks={subtasks} setSubtasks={setSubtasks} />
        <button className="primary purple small" onClick={addSubtask}>
          Ajouter une étape
        </button>
      </div>
      <hr />
      <div className="flex gap-4 items-start">
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="assignedTo" className="form-input-label">
              Responsable
            </label>
            <input
              type="text"
              id="assignedTo"
              className="form-input-text"
              value={assignedTo}
              onChange={(e) => setAssignedTo(e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="deliverable" className="form-input-label">
              Livrable
            </label>
            <input
              type="text"
              id="deliverable"
              className="form-input-text"
              value={deliverable}
              onChange={(e) => setDeliverable(e.target.value)}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="flex gap-4 items-start">
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="startedAt" className="form-input-label">
              Date de début
            </label>
            <input
              className="form-input-text"
              type="date"
              id="startedAt"
              value={startedAt}
              onChange={(e) => setStartedAt(e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="finishedAt" className="form-input-label">
              Date de fin
            </label>
            <input
              className="form-input-text"
              type="date"
              id="finishedAt"
              value={finishedAt}
              onChange={(e) => setFinishedAt(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 justify-between">
        <button
          className="secondary danger"
          onClick={() => deleteTask()}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : 'Supprimer'}
        </button>
        <button
          className="primary purple"
          onClick={() => updateTask()}
          disabled={isUpdating || !title}
        >
          {isUpdating ? <Loader /> : 'Modifier'}
        </button>
      </div>
    </div>
  );
}

function Subtasks({
  subtasks,
  setSubtasks,
}: {
  subtasks: UpdateTaskModal_SubTaskFragment[];
  setSubtasks: React.Dispatch<
    React.SetStateAction<UpdateTaskModal_SubTaskFragment[]>
  >;
}) {
  return (
    <div className="flex flex-col gap-2">
      {subtasks.map((subtask, index) => (
        <div key={`subtask_${index}`} className="flex items-center gap-2">
          <NumberCircle number={index + 1} size={8} />
          <input
            type="text"
            className="form-input-text"
            value={subtask.title}
            onChange={(e) => {
              setSubtasks(
                subtasks.map((st, i) =>
                  i === index ? { ...st, title: e.target.value } : st,
                ),
              );
            }}
          />
          <button
            className="tertiary"
            onClick={() => {
              setSubtasks(subtasks.filter((_, i) => i !== index));
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
    </div>
  );
}
