import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon, ChevronDownIcon } from '../../icons';
import { Float } from '@headlessui-float/react';

export type StringDropdownItem = {
  id: string | null;
  label: string;
};

export default function StringDropdown<T extends StringDropdownItem>({
  availableItems,
  item,
  setItem,
  extraPositionClassName,
}: {
  availableItems: T[];
  item: T | null;
  setItem: (item: T) => void;
  extraPositionClassName?: string;
}) {
  const chooseItem = (item: T) => {
    setItem(item);
  };

  return (
    <Listbox value={item} onChange={chooseItem}>
      {({ open }) => (
        <Float portal adaptiveWidth>
          <ListboxButton className="form-input-select">
            <span className={'flex items-center w-full'}>
              {item?.label || '-'}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions
              className={clsx(
                'absolute z-10 mt-1 w-full min-w-fit overflow-auto rounded-md bg-white text-base shadow-lg',
                'ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm max-h-60 py-1',
                extraPositionClassName,
              )}
            >
              {availableItems.map((item) => (
                <ListboxOption
                  key={item.id}
                  className={clsx(
                    'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                  )}
                  value={item}
                >
                  {({ selected }) => (
                    <div className="flex items-center gap-1">
                      <div
                        className={clsx(
                          selected ? 'font-semibold' : 'font-normal',
                          'ml-1 shrink-0',
                        )}
                      >
                        {item.label}
                      </div>

                      {selected ? (
                        <div
                          className={clsx(
                            'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                          )}
                        >
                          <CheckIcon
                            className="h-5 w-5 shrink-0"
                            aria-hidden="true"
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </Float>
      )}
    </Listbox>
  );
}
