import {
  ActionFieldsFragment,
  CatalystFieldsFragment,
  RoadmapFieldsFragment,
} from '../../../graphql/generated';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { PriorityBadge } from '../../badges/PriorityBadge';
import { ComplexityBadge } from '../../badges/ComplexityBadge';
import { PriceBadge } from '../../badges/PriceBadge';
import React from 'react';
import { CatalystTitle } from './CatalystTitle';
import { AddCatalystButton } from './AddCatalystButton';
import { EditActionMenu } from './EditActionMenu';
import { UpdateActionButton } from './UpdateActionButton';
import { sortByNullablePosition } from '../../../services/UtilsService';
import { AddActionMenu } from './AddActionMenu';
import { ActionIllustrationIcon } from '../../publications/roadmap/ActionIllustrationIcon';
import { mapActionPriorityLevelToNumber } from '../../publications/roadmap/ActionList';

export function RoadmapListView({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  return (
    <div className="flex flex-col divide-y divide-gray-100 space-y-12">
      {roadmap.catalysts
        .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
        .map((catalyst, index) => (
          <div key={catalyst.id} className="pt-6">
            <Catalyst catalyst={catalyst} index={index} />
          </div>
        ))}
      <div className="py-4">
        <AddCatalystButton roadmap={roadmap} />
      </div>
    </div>
  );
}

function Catalyst({
  catalyst,
  index,
}: {
  catalyst: CatalystFieldsFragment;
  index: number;
}) {
  return (
    <>
      <CatalystTitle catalyst={catalyst} index={index} />
      <div className="mt-2">
        <ActionList actions={catalyst.actions} />
        <AddActionMenu catalyst={catalyst} />
      </div>
    </>
  );
}

function ActionList({ actions }: { actions: ActionFieldsFragment[] }) {
  const sortedActions = actions.toSorted(
    (a, b) =>
      mapActionPriorityLevelToNumber(a.priorityLevel) -
      mapActionPriorityLevelToNumber(b.priorityLevel),
  );
  return (
    <div className="">
      <div className="flex gap-4 text-gray-500 text-sm">
        <div className="flex items-center gap-2 mb-2 w-2/3">
          <div>Actions</div>
        </div>
        <div className="w-1/3 grid grid-cols-5 gap-2 mb-2 justify-items-center items-end mr-2">
          <div>Pilier</div>
          <div>Priorité</div>
          <div>Complexité</div>
          <div>Coût</div>
        </div>
      </div>
      {sortedActions.map((action) => (
        <div
          className="mb-2 border-2 border-white rounded-lg hover:border-gray-900"
          key={action.id}
        >
          <ActionRow action={action} />
        </div>
      ))}
    </div>
  );
}

function ActionRow({ action }: { action: ActionFieldsFragment }) {
  return (
    <div className="rounded-xl flex items-center justify-between gap-2">
      <div className="font-bold text-gray-900 w-2/3 flex items-center gap-4">
        <ActionIllustrationIcon action={action} />
        <div>{action.title}</div>
      </div>
      <div className="w-1/3 grid grid-cols-5 gap-2 justify-items-center items-center pr-4">
        <ThemeIcon theme={action.theme} enableTooltip={true} />
        <PriorityBadge priorityLevel={action.priorityLevel} />
        <ComplexityBadge complexityLevel={action.complexityLevel} />
        <PriceBadge priceLevel={action.priceLevel} />
        <div className="flex gap-1 items-center">
          <EditActionMenu action={action} />
          <UpdateActionButton action={action} />
        </div>
      </div>
    </div>
  );
}
