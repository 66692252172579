import { Repository } from '../../../graphql/generated';
import React from 'react';
import iso26000Logo from '../../../assets/images/iso26000.png';
import csrdLogo from '../../../assets/images/csrd.png';
import { LogoIcon } from '../../icons';

export function RepositoryIllustration({
  repository,
}: {
  repository: Repository;
}) {
  switch (repository) {
    case Repository.Iso26000:
      return <img src={iso26000Logo} alt="ISO26000" />;
    case Repository.EfragEsrs:
      return <img src={csrdLogo} alt="CSRD" />;
    case Repository.Goodsteps:
    case Repository.Custom:
    default:
      return <LogoIcon className="w-14 h-14" />;
  }
}
