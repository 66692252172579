import React, { useState } from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { isCoachUser } from '../../../services/CompanyService';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { PrioritizationMatrixHeader } from '../../../components/publications/prioritizationMatrix/PrioritizationMatrixHeader';
import { PrioritizationMatrix } from '../../../components/publications/prioritizationMatrix/PrioritizationMatrix';
import { PrioritizationMatrixEditForm } from '../../../components/pro/publications/prioritizationMatrix/PrioritizationMatrixEditForm';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { EditIcon, XIcon } from '../../../components/icons';
import { useProPrioritizationMatrixScreenQuery } from '../../../graphql/generated';

export function ProPrioritizationMatrixScreen() {
  const [isEditMode, setIsEditMode] = useState(false);

  const proContext = useProContext();

  const stakesQuery = useProPrioritizationMatrixScreenQuery({
    variables: {
      referentialId: proContext?.company?.referential?.id || '',
      companyId: proContext?.company?.id || '',
    },
    skip: !proContext?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  const stakes = stakesQuery.data?.computeMaturityScores;
  const priorities =
    stakesQuery.data?.computeSimpleMaterialitiesFinancial ?? [];

  if (stakesQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!proContext?.company?.referential?.id) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        Aucun référentiel pour ce projet
      </MessageBox>
    );
  }

  return (
    (stakes && (
      <>
        <PageTitle title="Matrice de priorisation" />
        <ScreenTopBar isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        {isEditMode ? (
          <>
            <div className="">
              <PrioritizationMatrixHeader />
            </div>
            <div className="main-content space-y-16 py-16 w-full">
              <div>
                <PrioritizationMatrix stakes={stakes} priorities={priorities} />
              </div>
              <div>
                <PrioritizationMatrixEditForm stakes={stakes} />
              </div>
            </div>
          </>
        ) : (
          <>
            <PrioritizationMatrixHeader />
            <div className="p-8 flex flex-col gap-8 main-content w-full divide-y divide-gray-100">
              <PrioritizationMatrix stakes={stakes} priorities={priorities} />
            </div>
          </>
        )}
      </>
    )) || (
      <MessageBox type={MessageBoxType.Error}>
        Aucun enjeu pour ce projet
      </MessageBox>
    )
  );
}

function ScreenTopBar({
  isEditMode,
  setIsEditMode,
}: {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const proContext = useProContext();
  const currentUser = useCurrentUser();

  const editMode = () => {
    setIsEditMode(true);
    proContext?.setIsNavCollapsed(true);
  };

  const closeEditMode = () => {
    setIsEditMode(false);
    proContext?.setIsNavCollapsed(false);
  };

  if (isEditMode) {
    return (
      <div className="py-4 px-8 flex items-center gap-4 justify-between">
        <button className="tertiary" onClick={closeEditMode}>
          <XIcon />
        </button>
        <h1 className="title-h5 grow text-center">
          Matrice de priorisation ・ Édition
        </h1>
        <div></div>
      </div>
    );
  } else {
    return (
      <div className="py-4 px-8 flex items-center gap-4 justify-between">
        <h1 className="title-h5">Matrice de priorisation</h1>
        <div className="flex items-center gap-4">
          {isCoachUser(currentUser) && (
            <button className="primary purple" onClick={editMode}>
              <EditIcon className="w-4 h-4" />
              <span>Modifier</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}
