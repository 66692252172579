import { tailwindColors } from '../../../services/TailwindService';

export const getConsensusLineDataset = (label: string) => {
  return {
    label: label,
    data: [
      {
        x: 0,
        y: 0,
        r: 0,
      },
      {
        x: 4,
        y: 4,
        r: 0,
      },
    ],
    borderColor: tailwindColors.gray[100],
    backgroundColor: 'white',
    borderWidth: 5,
    borderCapStyle: 'round',
    pointStyle: false,
    type: 'line',
    animation: true,
    datalabels: {
      display: false,
      label: label,
      backgroundColor: 'transparent',
    },
  };
};
