import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import {
  DiagnosticStakeUpdateInput,
  PriorityLevel,
  StakePriorityProgressBar_StakeFragment,
  StakePrioritySelect_StakeFragment,
  useStakePrioritySelect_StakeUpdateMutation,
} from '../../../graphql/generated';
import { NumberCircle } from '../../generic/NumberCircle';
import { getPriorityLabel } from '../../../services/DiagnosticService';
import { Loader } from '../../generic/Loader';
import { AutoIcon, CheckIcon, ChevronDownIcon, NoEyeIcon } from '../../icons';
import { getPriorityLevelFromComputedValue } from '../../../services/ReferentialService';

type PriorityListItem = {
  id: PriorityLevel | null;
  name: string;
  icon: ReactElement;
};

const noPriorityListItem: PriorityListItem = {
  id: null,
  name: getPriorityLabel(null),
  icon: <NoEyeIcon />,
};
const computedPriorityListItem: PriorityListItem = {
  id: PriorityLevel.Computed,
  name: getPriorityLabel(PriorityLevel.Computed),
  icon: <AutoIcon />,
};
const priorityListItems: PriorityListItem[] = [
  computedPriorityListItem,
  {
    id: PriorityLevel.High,
    name: getPriorityLabel(PriorityLevel.High),
    icon: <NumberCircle number={4} size={5} />,
  },
  {
    id: PriorityLevel.Medium,
    name: getPriorityLabel(PriorityLevel.Medium),
    icon: <NumberCircle number={3} size={5} />,
  },
  {
    id: PriorityLevel.Low,
    name: getPriorityLabel(PriorityLevel.Low),
    icon: <NumberCircle number={2} size={5} />,
  },
  {
    id: PriorityLevel.None,
    name: getPriorityLabel(PriorityLevel.None),
    icon: <NumberCircle number={1} size={5} />,
  },
  noPriorityListItem,
];

export default function StakePrioritySelect({
  stake,
  disabled,
}: {
  stake: StakePrioritySelect_StakeFragment;
  disabled: boolean;
}) {
  const [selected, setSelected] = useState(computedPriorityListItem);
  const [editDiagnosticStakes] = useStakePrioritySelect_StakeUpdateMutation();
  const [isLoading, setIsLoading] = useState(false);

  const editDiagnosticStake = (input: DiagnosticStakeUpdateInput) => {
    setIsLoading(true);
    editDiagnosticStakes({
      variables: {
        input,
      },
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSelected(definePriorityListItem(stake.priorityLevel));
  }, [stake.priorityLevel]);

  const chooseItem = (priorityListItem: PriorityListItem) => {
    setSelected(priorityListItem);
    editDiagnosticStake({
      id: stake.id,
      priorityLevel: priorityListItem.id,
    });
  };

  const priorityLevelFromComputedValue = getPriorityLevelFromStake(stake);
  const iconForComputedValue = priorityListItems.find(
    (priorityListItem) =>
      priorityListItem.id === priorityLevelFromComputedValue,
  )?.icon;

  return (
    <Listbox
      value={selected}
      onChange={chooseItem}
      disabled={disabled || isLoading}
    >
      {({ open }) => (
        <div className="relative mt-1">
          <ListboxButton className="form-input-select">
            {selected.id === PriorityLevel.Computed ? (
              <span className="flex items-center w-full">
                {isLoading ? <Loader /> : iconForComputedValue}
                <span className="ml-3 flex items-center gap-1">
                  {selected.icon}
                  {selected.name}
                </span>
              </span>
            ) : (
              <span className="flex items-center w-full">
                {isLoading ? <Loader /> : selected.icon}
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
            )}

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {priorityListItems.map((priorityListItem) => (
                <ListboxOption
                  key={priorityListItem.id}
                  className={clsx(
                    'listbox-option relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                  )}
                  value={priorityListItem}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        {priorityListItem.icon}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate',
                          )}
                        >
                          {priorityListItem.name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={clsx(
                            'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

function definePriorityListItem(
  currentPriority: PriorityLevel | null | undefined,
): PriorityListItem {
  if (currentPriority) {
    const found = priorityListItems.find(
      (priorityListItem) => priorityListItem.id === currentPriority,
    );
    if (found) {
      return found;
    }
  }

  return noPriorityListItem;
}

function getPriorityLevelFromStake(
  stake: StakePrioritySelect_StakeFragment,
): PriorityLevel | null {
  const computedValue = getPriorityComputedValue(stake);
  return getPriorityLevelFromComputedValue(computedValue || 0);
}

// TODO: this is the wrong value, we should fix this by using materiality instead
function getPriorityComputedValue(
  stake: StakePriorityProgressBar_StakeFragment,
): number | null {
  // Value is the average of all stakeholders priority
  const allCounts =
    (stake.stakePriorityScore?.internalCount || 0) +
    (stake.stakePriorityScore?.externalCount || 0) +
    (stake.stakePriorityScore?.ceoCount || 0);
  const allSums =
    (stake.stakePriorityScore?.internalSum || 0) +
    (stake.stakePriorityScore?.externalSum || 0) +
    (stake.stakePriorityScore?.ceoSum || 0);

  if (allCounts > 0) {
    return allSums / allCounts;
  }

  return null;
}
