import { CatalystFieldsFragment } from '../../../graphql/generated';
import { UpdateCatalystModal } from './UpdateCatalystModal';
import React from 'react';
import { useModal } from '../../layout/Modal';
import { EditIcon } from '../../icons';

export function UpdateCatalystButton({
  catalyst,
}: {
  catalyst: CatalystFieldsFragment;
}) {
  const modal = useModal();
  return (
    <button
      className="primary purple"
      onClick={() =>
        modal.openModalWithComponent(
          <UpdateCatalystModal catalyst={catalyst} />,
          null,
          true,
          false,
          'w-1/2',
        )
      }
    >
      <EditIcon className="w-4 h-4" />
      <span>Editer</span>
    </button>
  );
}
