import React from 'react';
import { CatalystFieldsFragment } from '../../../graphql/generated';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { UpdateCatalystButton } from './UpdateCatalystButton';
import { EditCatalystMenu } from './EditCatalystMenu';

export function CatalystTitle({
  catalyst,
  index,
}: {
  catalyst: CatalystFieldsFragment;
  index: number;
}) {
  return (
    <div className="flex flex-col">
      <p className="text-gray-500 text-sm font-semibold">
        Axe stratégique #{index + 1}
      </p>
      <div className="flex items-center mt-2 mb-4 gap-2 justify-between">
        <div className="flex items-center gap-2">
          <div className="bg-gray-900 p-2 rounded-xl">
            <ThemeIcon
              theme={catalyst.theme}
              enableTooltip={true}
              className="text-white w-5 h-5"
            />
          </div>
          <h3 className="title-h2">{catalyst.title}</h3>
        </div>
        <div className="flex gap-1 items-center">
          <EditCatalystMenu catalyst={catalyst} />
          <UpdateCatalystButton catalyst={catalyst} />
        </div>
      </div>
    </div>
  );
}
