import React, { useRef } from 'react';
import clsx from 'clsx';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { Loader } from '../../../generic/Loader';
import { sanitizeTypeformString } from '../../../../services/SurveyService';
import { useProContext } from '../../../../providers/ProContextProvider';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import {
  convertArrayToObject,
  getExportFileName,
} from '../../../../services/FileService';
import { ExportToImageModal } from '../../../generic/ExportableToImage';
import { useModal } from '../../../layout/Modal';
import { utils, writeFileXLSX } from 'xlsx';
import {
  ImpactLevel,
  MaterialityTypes,
  MatrixArray_AnswerFragment,
  MatrixArray_StakeFragment,
  PriorityLevel,
} from '../../../../graphql/generated';
import {
  getImpactLabel,
  getPriorityLabel,
} from '../../../../services/DiagnosticService';
import { DownloadIcon, ExportIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { ContextualMenu } from '../../../generic/ContextualMenu';

export type MatrixRowType = {
  stakeId: string;
  label: string;
  values: MatrixCellType[];
};

export type MatrixCellType = {
  key: string;
  label: string;
  value: number;
};

export function MatrixArray({
  title,
  matrix,
  columns,
}: {
  title: string | null | undefined;
  matrix: MatrixRowType[];
  columns: string[];
}) {
  const proContext = useProContext();
  const currentUser = useCurrentUser();
  const exportableRef = useRef<HTMLDivElement>(null);
  if (matrix.length > 0) {
    return (
      <div className="p-4 border border-gray-100 rounded-2xl shadow-sm bg-white relative">
        <div className="absolute top-4 right-4 z-40">
          <ExportMatrixMenu matrix={matrix} exportableRef={exportableRef} />
        </div>
        <div ref={exportableRef}>
          {title && (
            <div className="font-bold mb-4 mr-16">
              {sanitizeTypeformString(title, proContext, currentUser)}
            </div>
          )}
          <MatrixRows matrix={matrix} columns={columns} />
        </div>
      </div>
    );
  } else {
    return <MessageBox type={MessageBoxType.Info}>Aucune donnée</MessageBox>;
  }
}

function MatrixRows({
  matrix,
  columns,
}: {
  matrix: MatrixRowType[];
  columns: string[];
}) {
  if (matrix.length > 0) {
    const numberOfColumns = columns.length;
    return (
      <div className="flex flex-col items-stretch gap-4 justify-evenly">
        <MatrixHeader matrix={matrix} numberOfColumns={numberOfColumns} />
        {matrix.map((row) => (
          <MatrixRow
            key={row.stakeId}
            row={row}
            numberOfColumns={numberOfColumns}
          />
        ))}
      </div>
    );
  } else {
    return <MessageBox type={MessageBoxType.Info}>Aucune donnée</MessageBox>;
  }
}

function MatrixHeader({
  matrix,
  numberOfColumns,
}: {
  matrix: MatrixRowType[];
  numberOfColumns: number;
}) {
  // Fetch header labels from first row
  const headerLabels =
    matrix.length > 0 ? matrix[0].values.map((value) => value.label) : [];
  return (
    <div className="flex items-center justify-between w-full">
      <div className="font-semibold w-1/3"></div>
      <div className={clsx('w-2/3 grid gap-3', `grid-cols-${numberOfColumns}`)}>
        {headerLabels.map((label) => (
          <div key={label} className="text-gray-500 text-center text-sm">
            {label}
          </div>
        ))}
      </div>
    </div>
  );
}

function MatrixRow({
  row,
  numberOfColumns,
}: {
  row: MatrixRowType;
  numberOfColumns: number;
}) {
  const sumOfAllValues = row.values.reduce(
    (sum, value) => sum + value.value,
    0,
  );
  return (
    <div className="flex items-center justify-between w-full">
      <div className="w-1/3 text-sm">{row.label || <Loader />}</div>
      <div className={clsx('w-2/3 grid gap-3', `grid-cols-${numberOfColumns}`)}>
        {row.values.map((cell) => (
          <MatrixCell
            key={cell.key}
            cell={cell}
            sumOfAllValues={sumOfAllValues}
          />
        ))}
      </div>
    </div>
  );
}

function MatrixCell({
  cell,
  sumOfAllValues,
}: {
  cell: MatrixCellType;
  sumOfAllValues: number;
}) {
  const percentage = sumOfAllValues > 0 ? cell.value / sumOfAllValues : 0;
  const textColor = percentage < 0.5 ? 'text-purple-500' : 'text-white';
  return (
    <div
      className={clsx(
        'flex items-center justify-center gap-1 rounded-lg p-3 font-semibold',
        textColor,
      )}
      style={{ backgroundColor: `rgba(94, 67, 149, ${percentage})` }}
    >
      <div className="">{Math.round(percentage * 100)}%</div>
      <div className={clsx('font-normal text-sm', textColor)}>{cell.value}</div>
    </div>
  );
}

function ExportMatrixToImageButton({
  refToExport,
}: {
  refToExport: React.RefObject<HTMLDivElement>;
}) {
  const modal = useModal();
  return (
    <button
      className="contextual-menu-item"
      onClick={() =>
        modal.openModalWithComponent(
          <ExportToImageModal
            refToExport={refToExport}
            name={getExportFileName('Export tableau matrice')}
          />,
          "Exporter en tant qu'image",
          false,
          true,
          'w-8/12',
        )
      }
    >
      <DownloadIcon />
      <div>Image</div>
    </button>
  );
}

function ExportMatrixContentToXlsButton({
  matrix,
}: {
  matrix: MatrixRowType[];
}) {
  const exportToXls = () => {
    // Fetch header labels from first row
    const headerLabels =
      matrix.length > 0 ? matrix[0].values.map((value) => value.label) : [];

    // For each column, add % column
    const header = [
      'Enjeu',
      ...headerLabels,
      ...headerLabels.map((c) => `${c} (%)`),
    ];

    const rows = matrix.map((row) => {
      const sumOfAllValues = row.values.reduce(
        (sum, value) => sum + value.value,
        0,
      );
      const rowValues = row.values.map((cell) => {
        return cell.value;
      });
      // Add percentage columns
      const rowPercentageValues = row.values.map((cell) => {
        return sumOfAllValues > 0
          ? Math.round((100 * cell.value) / sumOfAllValues)
          : 0;
      });
      return [`${row.label}`, ...rowValues, ...rowPercentageValues];
    });

    // Convert array to object to be able to use xlsx library
    const asAnObject = convertArrayToObject(rows, header);

    const worksheet = utils.json_to_sheet(asAnObject, {
      skipHeader: false,
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'matrice');
    writeFileXLSX(workbook, 'Export tableau matrice.xlsx', {
      compression: true,
    });
  };

  return (
    <button className="contextual-menu-item" onClick={exportToXls}>
      <DownloadIcon />
      <div>Fichier XLS</div>
    </button>
  );
}

function ExportMatrixMenu({
  matrix,
  exportableRef,
}: {
  matrix: MatrixRowType[];
  exportableRef: React.RefObject<HTMLDivElement>;
}) {
  const button = (
    <ExportIcon className="flex-shrink-0 h-3.5 w-3.5 text-gray-500" />
  );
  return (
    <ContextualMenu button={button}>
      <ExportMatrixContentToXlsButton matrix={matrix} />
      <ExportMatrixToImageButton refToExport={exportableRef} />
    </ContextualMenu>
  );
}

export function stakeAnswersToMatrixRows(
  answers: MatrixArray_AnswerFragment[],
  stakes: MatrixArray_StakeFragment[],
  columns: Array<PriorityLevel | ImpactLevel>,
  materialityType: MaterialityTypes,
): MatrixRowType[] {
  const { translateProperty } = useTranslation();
  return (
    answers
      .flatMap((answer) => answer.stakes)
      .reduce((acc: MatrixRowType[], stakeAnswer) => {
        if (acc.find((row) => row.stakeId === stakeAnswer?.stakeId)) {
          // Increment value in the right cell
          const stakeId = stakeAnswer?.stakeId || '';
          const level =
            materialityType === MaterialityTypes.Financial
              ? stakeAnswer?.priority
              : stakeAnswer?.impact;
          const row = acc.find((row) => row.stakeId === stakeId);
          const cell = row?.values.find((cell) => cell.key === level);
          if (cell) {
            cell.value++;
          }
          return acc;
        } else {
          const stake = stakes.find(
            (stake) => stake.id === stakeAnswer?.stakeId,
          );

          return [
            ...acc,
            {
              stakeId: stakeAnswer?.stakeId || '',
              label: translateProperty(stake, 'name'),
              values: columns.map((column) => {
                const label =
                  materialityType === MaterialityTypes.Financial
                    ? getPriorityLabel(column as PriorityLevel)
                    : getImpactLabel(column as ImpactLevel);
                const value =
                  materialityType === MaterialityTypes.Financial
                    ? stakeAnswer?.priority === column
                      ? 1
                      : 0
                    : stakeAnswer?.impact === column
                      ? 1
                      : 0;
                return {
                  key: column,
                  label,
                  value,
                };
              }),
            },
          ];
        }
      }, [])
      .toSorted((a, b) => a.label.localeCompare(b.label)) || []
  );
}
