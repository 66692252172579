import React from 'react';
import { useDisclosureRequirementsQuery } from '../../../graphql/cms/generated';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { DisclosureRequirement } from './DisclosureRequirement';
import { useTranslation } from '@hooks/useTranslation';
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';
import { LoaderFullscreen } from '../../layout/Loader';

export function DisclosureRequirements() {
  const { t } = useTranslation();
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<
    string | null
  >(null);

  const [page, setPage] = React.useState(1);
  const pageSize = 10;

  const query = useDisclosureRequirementsQuery({
    variables: {
      page: page,
      pageSize: pageSize,
    },
  });

  return (
    <div className="w-full main-content py-16 space-y-8">
      <h1>{t('disclosure_requirements.title')}</h1>
      {query.loading && <LoaderFullscreen />}
      <table>
        <thead>
          <tr>
            <th>{t('disclosure_requirements.table.header.esrs')}</th>
            <th>{t('disclosure_requirements.table.header.id')}</th>
            <th>{t('disclosure_requirements.table.header.name')}</th>
          </tr>
        </thead>
        <tbody>
          {query.data?.disclosureRequirements
            ?.filter((disclosureRequirement) => disclosureRequirement !== null)
            .map((disclosureRequirement, index) => {
              if (disclosureRequirement === null) {
                return null;
              }
              return (
                <tr
                  key={index}
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={() =>
                    setSelectedDocumentId(disclosureRequirement.documentId)
                  }
                >
                  <td className="font-bold">
                    {disclosureRequirement.esrs?.name}
                  </td>
                  <td className="font-bold">{disclosureRequirement.code}</td>
                  <td>{disclosureRequirement.title}</td>
                </tr>
              );
            })}
          {query.data?.disclosureRequirements?.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center">
                {t('global:none')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex items-center gap-2">
        <div>Page {page}</div>
        <button
          aria-label={t('global:pager.previous')}
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <ChevronLeftIcon />
        </button>
        <button
          aria-label={t('global:pager.next')}
          onClick={() => setPage(page + 1)}
        >
          <ChevronRightIcon />
        </button>
      </div>
      {query.error && (
        <MessageBox type={MessageBoxType.Error}>
          {query.error.message}
        </MessageBox>
      )}
      {selectedDocumentId && (
        <DisclosureRequirement documentId={selectedDocumentId} />
      )}
    </div>
  );
}
