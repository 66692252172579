import { Survey_SurveyFragment } from '../../graphql/generated';
import React, { useState } from 'react';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../nav/Tabs';
import { ManageStakeholderList } from './manageStakeholderList/ManageStakeholderList';
import { EditSurvey } from '../pro/survey/editSurvey/EditSurvey';
import { SurveyResponses } from './surveyTrackingResponses/responsesList/SurveyResponses';

const enum SURVEY_TABS {
  SURVEY_CONTENT = 'SURVEY_CONTENT',
  SURVEY_RECIPIENTS = 'SURVEY_RECIPIENTS',
  SURVEY_RESPONSES = 'SURVEY_RESPONSES',
}

export function Survey({ survey }: { survey: Survey_SurveyFragment }) {
  const tabs: TabItem[] = [
    { key: SURVEY_TABS.SURVEY_CONTENT, name: 'Prévisualisation' },
    { key: SURVEY_TABS.SURVEY_RECIPIENTS, name: 'Partage' },
    { key: SURVEY_TABS.SURVEY_RESPONSES, name: 'Suivi' },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="p-4">
        <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
      </div>
      <div className="grow overflow-scroll flex">
        <SurveyInnerTab currentTab={currentTab} survey={survey} />
      </div>
    </div>
  );
}

function SurveyInnerTab({
  currentTab,
  survey,
}: {
  currentTab: TabItem;
  survey: Survey_SurveyFragment;
}) {
  switch (currentTab.key) {
    case SURVEY_TABS.SURVEY_CONTENT:
      return <EditSurvey survey={survey} isPreview={true} />;
    case SURVEY_TABS.SURVEY_RESPONSES:
      return (
        <div className="main-content py-8 w-full flex flex-col gap-8">
          <SurveyResponses survey={survey} />
        </div>
      );
    case SURVEY_TABS.SURVEY_RECIPIENTS:
      return (
        <div className="w-full">
          <ManageStakeholderList surveyId={survey.id} />
        </div>
      );
    default:
      return null;
  }
}
