import React, { useEffect, useState } from 'react';
import {
  SurveyResponses_SurveyFragment,
  useSurveyWaitingStakeholderQuery,
} from '../../../../graphql/generated';
import Cookies from 'js-cookie';
import { ITEMS_PER_PAGE_COOKIE } from '../responsesList/SurveyResponsesTable';
import { LoaderFullscreen } from '../../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { Loader } from '../../../generic/Loader';
import StringDropdown from '../../../generic/form/StringDropdown';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { Table } from '../../../molecules/table/Table';
import { getSortedRowModel } from '@tanstack/react-table';
import {
  SurveyWaitingStakeholderColumnsRow,
  useSurveyWaitingStakeholderColumnsRow,
} from './useSurveyWaitingStakeholderColumns';
import { CopyNonRespondentEmailsButton } from '../actions/CopyNonRespondentEmailsButton';

export const SurveyWaitingStakeholder = ({
  survey,
  setWaitingStakeholderCounter,
}: {
  survey: SurveyResponses_SurveyFragment;
  setWaitingStakeholderCounter?: (responsesCounter: number) => void;
}) => {
  const { t } = useTranslation();
  const initialItemsPerPage = parseInt(
    Cookies.get(ITEMS_PER_PAGE_COOKIE) || '20',
    10,
  );
  const [numberPerPage, setNumberPerPage] = useState(initialItemsPerPage);
  const [cursor, setCursor] = useState<{
    before: string | undefined;
    after: string | undefined;
  }>({ before: undefined, after: undefined });
  const paging = {
    first: cursor.after || !cursor.before ? numberPerPage : undefined,
    last: cursor.before ? numberPerPage : undefined,
    after: cursor.after,
    before: cursor.before,
  };

  useEffect(() => {
    Cookies.set(ITEMS_PER_PAGE_COOKIE, numberPerPage.toString());
  }, [numberPerPage]);
  const query = useSurveyWaitingStakeholderQuery({
    variables: {
      surveyId: survey.id,
      paging,
    },
  });
  const columns = useSurveyWaitingStakeholderColumnsRow();

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data) {
    return <Loader />;
  }

  const rows = query.data.waitingStakeholders.edges.map((edge) => {
    const node = edge.node;
    return {
      email: node.email,
      firstName: node.firstName,
      lastName: node.lastName,
    };
  });
  if (setWaitingStakeholderCounter) {
    setWaitingStakeholderCounter(query.data.waitingStakeholders.totalCount);
  }
  const next = () => {
    setCursor({
      before: undefined,
      after: query.data?.waitingStakeholders?.pageInfo.endCursor,
    });
  };

  const previous = () => {
    setCursor({
      before: query.data?.waitingStakeholders?.pageInfo.startCursor,
      after: undefined,
    });
  };
  return (
    <div className="space-y-4 pt-8 pb-16">
      {(rows.length === 0 && (
        <MessageBox type={MessageBoxType.Success}>
          <div className="flex items-center justify-between">
            <p>{t('translation:survey.waitingStakeholders.noResult')}</p>
          </div>
        </MessageBox>
      )) || (
        <div className="space-y-5">
          <div className="space-y-2">
            <div className="flex justify-end gap-2">
              <div className="w-1/4"></div>
              <CopyNonRespondentEmailsButton survey={survey} />
            </div>
          </div>
          <Table<SurveyWaitingStakeholderColumnsRow>
            {...{
              tableClassName: 'table',
              columns,
              data: rows,
              enableSorting: false,
              getSortedRowModel: getSortedRowModel(),
            }}
          />
          <div className="flex justify-end gap-2">
            <div className="w-30">
              <StringDropdown
                availableItems={[
                  {
                    id: '10',
                    label: t('global:pager.itemsPerPage', { count: 10 }),
                  },
                  {
                    id: '20',
                    label: t('global:pager.itemsPerPage', { count: 20 }),
                  },
                  {
                    id: '50',
                    label: t('global:pager.itemsPerPage', { count: 50 }),
                  },
                ]}
                item={{
                  id: numberPerPage.toString(),
                  label: t('global:pager.itemsPerPage', {
                    count: numberPerPage,
                  }),
                }}
                setItem={(item) => setNumberPerPage(parseInt(item.id))}
                extraPositionClassName="-translate-y-full"
              />
            </div>
            <button
              aria-label={t('global:pager.previous')}
              disabled={
                !query.data?.waitingStakeholders.pageInfo.hasPreviousPage
              }
              onClick={previous}
            >
              <ChevronLeftIcon />
            </button>
            <button
              aria-label={t('global:pager.next')}
              disabled={!query.data?.waitingStakeholders.pageInfo.hasNextPage}
              onClick={next}
            >
              <ChevronRightIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
