import React, { useMemo, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  MaturityEvaluationRespondModal_ReferentialFragment,
  MaturityEvaluationRespondModal_ResponseFragment,
  MaturityEvaluationRespondModal_SurveyFragment,
  MaturityEvaluationWithRecipientsDocument,
  MaturityQuestion_QuestionFragment,
  SurveySubmitAnswerInput,
  useMaturityEvaluationRespondModal_SubmitMutation,
  useMaturityEvaluationRespondModalQuery,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { LoaderFullscreen } from '../../../layout/Loader';
import { useToast } from '../../../layout/Toast';
import { Loader } from '../../../generic/Loader';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { useProContext } from '../../../../providers/ProContextProvider';
import { Toggle } from '../../../generic/form/Toggle';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import PillarDropdown from '../../../form/PillarDropdown';
import StakeDropdown from '../../../form/StakeDropdown';
import { MaturityQuestion } from './MaturityQuestion';
import { PillarsCompletion } from './PillarsCompletion';
import { CheckIcon, SearchIcon, XIcon } from '../../../icons';

export function MaturityEvaluationRespondModal({
  maturityEvaluation,
}: {
  maturityEvaluation: MaturityEvaluationRespondModal_SurveyFragment;
}) {
  const proContext = useProContext();
  const query = useMaturityEvaluationRespondModalQuery({
    variables: {
      maturityEvaluation: maturityEvaluation.id,
      lastResponseFromSurvey: maturityEvaluation.id,
      referential: proContext?.company?.referential?.id || '',
    },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.data?.maturityEvaluation && query.data?.referential) {
    return (
      <MaturityEvaluationRespondModalInner
        survey={query.data?.maturityEvaluation}
        lastResponse={query.data?.surveyLastResponse}
        referential={query.data?.referential}
        refetch={() => query.refetch}
      />
    );
  }

  return null;
}

function MaturityEvaluationRespondModalInner({
  survey,
  lastResponse,
  referential,
  refetch,
}: {
  survey: MaturityEvaluationRespondModal_SurveyFragment;
  lastResponse:
    | MaturityEvaluationRespondModal_ResponseFragment
    | null
    | undefined;
  referential: MaturityEvaluationRespondModal_ReferentialFragment;
  refetch: () => void;
}) {
  const modal = useModal();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResponseMutation] =
    useMaturityEvaluationRespondModal_SubmitMutation();
  const toast = useToast();

  const [currentStakeId, setCurrentStakeId] = useState<string | null>(null);
  const [currentPillarId, setCurrentPillarId] = useState<string | null>(null);

  const [searchedQuestionTitle, setSearchedQuestionTitle] =
    useState<string>('');
  const [hideAnsweredQuestions, setHideAnsweredQuestions] =
    useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const questions = (survey.questions || [])
    .toSorted((a, b) => a.position - b.position)
    .flatMap((question) => {
      if (question.type === 'group') {
        return (
          (question.children || []).toSorted(
            (a, b) => a.position - b.position,
          ) || []
        );
      } else {
        return [question];
      }
    })
    // Dedup
    .filter(
      (question, index, self) =>
        self.findIndex((q) => q.id === question.id) === index,
    );
  const sortedQuestionsFiltered = useMemo(
    () =>
      questions
        .filter((question) => {
          if (currentPillarId) {
            if (question.pillar?.id) {
              return question.pillar?.id === currentPillarId;
            }
            if (question.stake?.id) {
              const stake = referential?.pillars
                .flatMap((pillar) => pillar.stakes)
                .find((stake) => stake.id === question.stake?.id);
              return stake?.pillar.id === currentPillarId;
            }
            return false;
          }
          return true;
        })
        .filter((question) => {
          if (currentStakeId) {
            if (question.stake?.id) {
              return question.stake?.id === currentStakeId;
            }
            return false;
          }
          return true;
        })
        .filter((question) => {
          if (searchedQuestionTitle) {
            return question.title
              .toLowerCase()
              .includes(searchedQuestionTitle.toLowerCase());
          }
          return true;
        })
        .filter((question) => {
          if (hideAnsweredQuestions) {
            return !lastResponse?.answers?.find(
              (answer) => answer.question.id === question.id,
            );
          }
          return true;
        }),
    [
      questions,
      currentPillarId,
      referential?.pillars,
      currentStakeId,
      searchedQuestionTitle,
      hideAnsweredQuestions,
      lastResponse?.answers,
    ],
  );

  const [answers, setAnswers] = useState<{
    [key: string]: SurveySubmitAnswerInput;
  }>(
    lastResponse?.answers?.reduce(
      (acc, answer) => {
        acc[answer.question.id] = {
          type: answer.type,
          choice: answer.choice,
          choices: answer.choices,
          question: {
            id: answer.question.id,
          },
          comment: answer.comment,
          text: answer.text,
          nps: answer.nps,
          number: answer.number,
          stakes: answer.stakes,
          identity: answer.identity,
        };
        return acc;
      },
      {} as { [key: string]: SurveySubmitAnswerInput },
    ) || {},
  );

  const submit = () => {
    setIsSubmitting(true);
    submitResponseMutation({
      variables: {
        input: {
          survey: {
            id: survey?.id || '',
          },
          answers: Object.values(answers),
          respondent: {
            email: currentUser?.auth?.email || '',
            name: `${currentUser?.firstName as string} ${
              currentUser?.lastName as string
            }`,
            role: currentUser?.auth?.roles.join(', '),
          },
        },
      },
      refetchQueries: [
        {
          query: MaturityEvaluationWithRecipientsDocument,
          variables: {
            id: survey?.id || '',
          },
        },
      ],
    })
      .then(() => {
        setIsSubmitting(false);
        setUnsavedChanges(false);
        toast.openToastWithMessage('Réponses sauvegardées');
        // Refetch last data
        refetch();
      })
      .catch((e) => {
        setIsSubmitting(false);
        toast.openToastWithError(e.message);
      });
  };

  const setAnswer = (
    answer: SurveySubmitAnswerInput | null,
    question: MaturityQuestion_QuestionFragment,
  ) => {
    if (answer === null) {
      // Unset answer
      const copyOfAnswers = { ...answers };
      delete copyOfAnswers[question.id];
      setAnswers(copyOfAnswers);
      return;
    } else {
      const copyOfAnswers = { ...answers };
      copyOfAnswers[question.id] = answer;
      setAnswers(copyOfAnswers);
    }
    setUnsavedChanges(true);
  };

  const handlePillarChange = (pillarId: string | null) => {
    setCurrentPillarId(pillarId);
    setCurrentStakeId(null); // Reset the Stake dropdown when a new Pillar is selected
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className="bg-white flex items-center gap-2 p-4 w-full justify-between">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-100"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <div className="flex items-center justify-between grow">
          <h4 className="flex items-center justify-center gap-3 w-full">
            {t('maturity.evaluation.title')}
          </h4>
        </div>
        <button
          onClick={submit}
          disabled={isSubmitting || !unsavedChanges}
          className="primary purple"
        >
          {isSubmitting && <Loader />}
          {!unsavedChanges && <CheckIcon className="w-5 h-5" />}
          {unsavedChanges ? (
            <div>{t('maturity.evaluation.respondModal.save')}</div>
          ) : (
            <div>{t('maturity.evaluation.respondModal.saved')}</div>
          )}
        </button>
      </div>
      <div className="overflow-y-scroll">
        <div className="px-8 py-6 border-t border-gray-100 space-y-2">
          <h1>{survey?.name || ''}</h1>
          {referential && (
            <PillarsCompletion
              referential={referential}
              currentPillarId={currentPillarId}
              setCurrentPillarId={handlePillarChange}
              response={lastResponse}
              questions={questions}
            />
          )}
        </div>
        <div className="bg-gray-50 flex items-center gap-4 px-8 py-4">
          <PillarDropdown
            currentPillarId={currentPillarId}
            setPillarId={handlePillarChange}
            isFilter={true}
          />
          <StakeDropdown
            currentStakeId={currentStakeId}
            setCurrentStakeId={setCurrentStakeId}
            pillarId={currentPillarId}
            isRequired={false}
            isFilter={true}
          />
          <div className="bg-white grow flex items-center justify-start gap-2 px-2 border border-gray-100 shadow-sm rounded-md">
            <SearchIcon className="text-gray-300" />
            <input
              placeholder="Rechercher une question"
              className="border-0 w-full focus:border-0 focus:ring-0 focus:outline-none text-sm placeholder-gray-300 pl-0"
              type={'text'}
              value={searchedQuestionTitle}
              onChange={(e) => setSearchedQuestionTitle(e.target.value)}
            />
          </div>

          <div className="flex items-center gap-2">
            <Toggle
              state={hideAnsweredQuestions}
              setState={setHideAnsweredQuestions}
            />
            <div className="text-sm font-bold">
              {t('maturity.evaluation.respondModal.unrespondedQuestionsOnly')}
            </div>
          </div>
        </div>
        <div className="bg-gray-50 flex flex-col gap-4 pb-4 px-8">
          {sortedQuestionsFiltered.length === 0 && (
            <MessageBox type={MessageBoxType.Info}>
              {t('maturity.evaluation.respondModal.noQuestion')}
            </MessageBox>
          )}
          {sortedQuestionsFiltered.map((question, index) => (
            <MaturityQuestion
              key={question.id}
              question={question}
              index={index}
              answer={answers[question.id]}
              setAnswer={setAnswer}
              setUnsavedChanges={setUnsavedChanges}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
