import React from 'react';
import { LoginScreen } from '../public/LoginScreen';
import { PasswordResetScreen } from '../public/PasswordResetScreen';
import { RegisterScreen } from '../public/RegisterScreen';
import { NotFoundScreen } from '../public/NotFoundScreen';
import { ContentOnlyLayout } from '../../components/layout/ContentOnlyLayout';
import { StorybookScreen } from '../public/StorybookScreen';
import { SurveyResponseScreen } from '../public/SurveyResponseScreen';
import { SurveyPreviewScreen } from '../public/SurveyPreviewScreen';
import { DisclosureRequirementsScreen } from '../public/DisclosureRequirementsScreen';

export const usePublicRouteConfig = () => {
  return [
    // Login
    {
      path: '/login',
      element: <LoginScreen />,
    },
    // Password Reset
    {
      path: '/forgot-password',
      element: <PasswordResetScreen />,
    },
    // Register
    {
      path: '/register/:companyId/:role',
      element: <RegisterScreen />,
    },
    // 404
    {
      path: '*',
      element: <NotFoundScreen />,
    },
    // Other public pages
    {
      path: '/public',
      element: <ContentOnlyLayout />,
      children: [
        {
          index: true,
          element: <NotFoundScreen />,
        },
        // Design system
        {
          path: '/public/design-system',
          element: <StorybookScreen />,
        },
        // Disclosure requirements
        {
          path: '/public/disclosure-requirements',
          element: <DisclosureRequirementsScreen />,
        },
      ],
    },
    // Public survey page
    {
      path: '/survey/:surveyId',
      element: <ContentOnlyLayout />,
      children: [
        {
          path: '/survey/:surveyId/answer',
          element: <SurveyResponseScreen />,
        },
        {
          path: '/survey/:surveyId/response/:responseId',
          element: <SurveyResponseScreen />,
        },
        {
          path: '/survey/:surveyId/preview',
          element: <SurveyPreviewScreen />,
        },
      ],
    },
  ];
};
