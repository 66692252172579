import React, { useMemo, useRef } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import plugin from 'chartjs-plugin-datalabels';
import { Bubble } from 'react-chartjs-2';
import { ExportChartJsToImageButton } from '../ExportChartJsToImageButton';
import { useTranslation } from '@hooks/useTranslation';
import {
  TMaterialityMatrixChartDatapoint,
  TMaterialityMatrixChartJs,
  TMaterialityMatrixChartJsOptions,
} from './types';
import { getChartJsOptions } from './getChartJsOptions';
import { getChartJsDataset } from './getChartJsDataset';
import { getConsensusLineDataset } from './getConsensusLineDataset';
import { DragHandler } from './dragHandlers';
import { getAnnotations } from './getAnnotations';

export const MaterialityBubbleChartJs = ({
  datapoints,
  filteredDatapointIds,
  options,
}: {
  datapoints: TMaterialityMatrixChartDatapoint[];
  filteredDatapointIds: string[];
  options: TMaterialityMatrixChartJsOptions;
}) => {
  const { t } = useTranslation();

  // ChartJS init
  Chart.register(zoomPlugin, annotationPlugin, plugin);
  const chartRef = useRef<TMaterialityMatrixChartJs>(null);
  const dragHandlerRef = useRef<DragHandler>(new DragHandler());

  // Build datasets
  const chartJsData = useMemo(() => {
    return {
      datasets: getChartJsDataset(datapoints, filteredDatapointIds),
    };
  }, [datapoints, filteredDatapointIds]);

  if (options.enableConsensusLine) {
    chartJsData.datasets.push(
      getConsensusLineDataset(
        t('components.generic.chart.MaterialityBubbleChartJs.consensus_line'),
      ),
    );
  }

  const baseOpt = getChartJsOptions(options);
  // Build options
  const chartJsOptions = useMemo(() => {
    // Build annotations option
    if (baseOpt.plugins) {
      const annotations = getAnnotations(
        options,
        datapoints,
        dragHandlerRef.current,
        filteredDatapointIds,
      );

      if (annotations.length > 0) {
        baseOpt.plugins.annotation = {
          annotations,
          common: {
            drawTime: 'afterDraw',
          },
        };
      }
    }

    return baseOpt;
  }, [dragHandlerRef, datapoints, filteredDatapointIds, baseOpt, options]);

  const plugins = useMemo(
    () => [
      // { id: 'zoom' },
      { id: 'title' },
      { id: 'annotation' },
      { id: 'datalabels' },
      dragHandlerRef.current.getPlugin(),
    ],
    [dragHandlerRef],
  );

  return (
    <div className="flex flex-col items-end">
      <div className="h-[600px] w-full">
        <Bubble
          ref={chartRef}
          data={chartJsData}
          options={chartJsOptions}
          plugins={plugins}
        />
      </div>
      {options.enableExportAsImage && (
        <ExportChartJsToImageButton chartRef={chartRef} className="primary">
          Exporter
        </ExportChartJsToImageButton>
      )}
    </div>
  );
};
