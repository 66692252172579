import {
  ActionFieldsFragment,
  useActionDeleteMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useModal } from '../../layout/Modal';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { useToast } from '../../layout/Toast';
import { XIcon } from '../../icons';

export function DeleteActionButton({
  action,
}: {
  action: ActionFieldsFragment;
}) {
  const modal = useModal();
  const toast = useToast();
  const [deleteAction] = useActionDeleteMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteCurrentAction = () => {
    setIsDeleting(true);
    deleteAction({
      variables: {
        id: action.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage("L'action a bien été supprimée");
      })
      .catch((err) => console.error(err))
      .finally(() => setIsDeleting(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            callbackIfConfirmed={deleteCurrentAction}
            message="Souhaitez-vous vraiment supprimer cette action ?"
          />,
          action.title,
        )
      }
    >
      {isDeleting ? <Loader /> : <XIcon />}
      <span>Supprimer</span>
    </button>
  );
}
