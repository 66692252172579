import React from 'react';
import { EyeIcon, EyeStrikeThroughIcon } from '../../icons';

export default function PasswordInput({
  value,
  onChangeCallback,
  placeholder,
}: {
  value: string;
  onChangeCallback: (value: string) => void;
  placeholder?: string;
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className="relative">
      {showPassword ? (
        <button
          className="absolute top-3 right-4 unstyled"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(false);
          }}
        >
          <EyeStrikeThroughIcon className="text-gray-500 hover:text-gray-900" />
        </button>
      ) : (
        <button
          className="absolute top-1/2 bottom-1/2 right-4 unstyled"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(true);
          }}
        >
          <EyeIcon className="text-gray-500 hover:text-gray-900" />
        </button>
      )}
      <input
        id="password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        autoComplete="current-password"
        value={value}
        onChange={(e) => onChangeCallback(e.target.value)}
        required
      />
    </div>
  );
}
