import clsx from 'clsx';
import { useCompanyNameQuery } from '../../../graphql/generated';
import React from 'react';

export function CompanyLogo({
  companyId,
  size,
}: {
  companyId: string;
  size: 'big' | 'small';
}) {
  const companyLogoQuery = useCompanyNameQuery({
    variables: { id: companyId },
    fetchPolicy: 'cache-and-network',
  });

  if (
    companyLogoQuery.loading ||
    companyLogoQuery.error ||
    !companyLogoQuery.data?.companyName.logo
  ) {
    // Placeholder
    return (
      <div
        className={clsx(
          'rounded-lg bg-gray-50 border border-gray-100 flex items-center justify-center text-gray-900 font-title font-extrabold shrink-0',
          size === 'big' && 'h-20 w-20 max-w-sm text-xl',
          size === 'small' && 'w-10 h-10 rounded-md text-sm',
        )}
      >
        <div>{companyLogoQuery.data?.companyName.name.substring(0, 3)}</div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'shrink-0 flex items-center justify-center',
        size === 'big' && 'h-20 rounded-xl max-w-sm',
        size === 'small' && 'w-10 h-10 rounded-md',
      )}
    >
      {companyLogoQuery.data?.companyName.logo ? (
        <img
          src={companyLogoQuery.data.companyName.logo}
          className={clsx(
            'shrink-0 object-contain',
            size === 'big' && 'h-20 max-w-56 rounded-xl',
            size === 'small' && 'h-auto w-10 rounded-md',
          )}
          alt=""
        />
      ) : (
        <div
          className={clsx(
            'bg-gray-50 flex items-center justify-center text-gray-500 border border-gray-100',
            size === 'big' && 'w-20 h-20 rounded-xl text-xl',
            size === 'small' && 'h-10 w-10 rounded-md text-base',
          )}
        >
          {companyLogoQuery.data?.companyName.name[0]}
        </div>
      )}
    </div>
  );
}
