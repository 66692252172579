import {
  ActionFieldsFragment,
  useActionDuplicateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useToast } from '../../layout/Toast';
import { PlusIcon } from '../../icons';

export function DuplicateActionButton({
  action,
}: {
  action: ActionFieldsFragment;
}) {
  const toast = useToast();
  const [updateActionMutation] = useActionDuplicateMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const duplicate = () => {
    setIsUpdating(true);
    updateActionMutation({
      variables: {
        input: {
          actionId: action.id,
          companyId: action.company.id,
          isTemplate: false,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage("L'action a bien été dupliquée");
      })
      .catch((err) => console.error(err))
      .finally(() => setIsUpdating(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isUpdating}
      onClick={duplicate}
    >
      {isUpdating ? <Loader /> : <PlusIcon />}
      <span>Dupliquer</span>
    </button>
  );
}
