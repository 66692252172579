import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { StakeholderList } from '../../../components/pro/stakeholder/mapping/StakeholderList';
import { ScreenTopBar } from '../../../components/layout/ScreenTopBar';
import { useProContext } from '../../../providers/ProContextProvider';
import { withProContext } from '../../../providers/withProContext';
import { Loader } from '../../../components/generic/Loader';

export const ProStakeholderScreen = withProContext(
  () => {
    const proContext = useProContext();
    const { t } = useTranslation();
    if (!proContext?.company?.id) {
      return <Loader />;
    }

    return (
      <>
        <PageTitle title={t('screen.stakeholder.stakeholder_mapping')} />
        <ScreenTopBar>
          {t('screen.stakeholder.stakeholder_mapping')}
        </ScreenTopBar>
        <StakeholderList companyId={proContext.company.id} />
      </>
    );
  },
  { requiredCompany: true },
);
