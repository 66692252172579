import React from 'react';
import {
  SurveyListFragment,
  SurveyTarget,
  useSurveyWithRecipientsQuery,
} from '../../graphql/generated';
import { NavLink } from 'react-router-dom';
import surveyCollaborators from '../../assets/images/illustrations/survey/survey-collaborators.png';
import surveyExternal from '../../assets/images/illustrations/survey/survey-external.png';
import surveyCeo from '../../assets/images/illustrations/survey/survey-ceo.png';
import { ProgressBar } from '../generic/ProgressBar';
import { Loader } from '../generic/Loader';
import { SurveyStatusTag } from './SurveyStatusTag';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { ChevronRightIcon, UserIcon } from '../icons';

export function SurveyList({ surveys }: { surveys: SurveyListFragment[] }) {
  return (
    <div className="pb-16">
      {surveys?.length > 0 ? (
        <table className="">
          <thead>
            <tr>
              <th className="text-left">Consultation</th>
              <th className="text-center">Type</th>
              <th className="text-center">Statut</th>
              <th className="text-center">Taux de complétion</th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
            {surveys.map((survey) => {
              return <SurveyRow survey={survey} key={survey.id} />;
            })}
          </tbody>
        </table>
      ) : (
        <MessageBox type={MessageBoxType.Info}>Aucune consultation</MessageBox>
      )}
    </div>
  );
}

function SurveyRow({ survey }: { survey: SurveyListFragment }) {
  // Fetch Recipients data
  const { data, loading } = useSurveyWithRecipientsQuery({
    variables: { id: survey.id },
    fetchPolicy: 'network-only',
  });

  return (
    <tr>
      <td>
        <NavLink to={`/diag/survey/${survey.id}`}>
          <div className="flex items-center gap-2">
            {survey.target === SurveyTarget.Collaborators && (
              <img src={surveyCollaborators} alt="" className="w-12" />
            )}
            {survey.target === SurveyTarget.External && (
              <img src={surveyExternal} alt="" className="w-12" />
            )}
            {survey.target === SurveyTarget.Ceo && (
              <img src={surveyCeo} alt="" className="w-12" />
            )}
            <div className="font-title font-extrabold">{survey.name}</div>
          </div>
        </NavLink>
      </td>
      <td>
        <div className="flex items-center justify-center font-bold gap-2">
          <UserIcon />
          {survey.target === SurveyTarget.Collaborators && <span>Interne</span>}
          {survey.target === SurveyTarget.External && <span>Externe</span>}
          {survey.target === SurveyTarget.Ceo && <span>Direction</span>}
        </div>
      </td>
      <td>
        <div className="flex justify-center">
          <SurveyStatusTag survey={survey} />
        </div>
      </td>
      <td className="text-center w-1/4">
        {loading ? (
          <Loader />
        ) : (
          data?.survey && (
            <ProgressBar
              value={data.survey.responses?.length || 0}
              total={data.survey.recipients?.length || 0}
              targetThreshold={data.survey.targetThreshold || 0}
              displayLabel={false}
              displayPercentage={true}
              displayCounts={true}
            />
          )
        )}
      </td>
      <td>
        <NavLink to={`/diag/survey/${survey.id}`}>
          <button className="primary purple small">
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </NavLink>
      </td>
    </tr>
  );
}
