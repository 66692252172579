import React from 'react';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { MaterialityMatrixHeader } from '../../../components/publications/materialityMatrix/MaterialityMatrixHeader';
import { SimpleMaterialityMatrix } from '../../../components/pro/publications/simpleMateriality/financialByStakeholderSegmentsMatrix/SimpleMaterialityMatrix';
import { MatrixProvider } from '../../../components/pro/publications/doubleMateriality/MatrixContext';

export function MaterialityMatrixScreen() {
  const currentUser = useCurrentUser();
  if (currentUser) {
    return (
      <>
        <PageTitle title="Matrice de matérialité" />
        <MaterialityMatrixHeader />
        <div className="p-8 flex flex-col gap-8 main-content w-full divide-y divide-gray-100">
          <MatrixProvider initialStakeholderSegments={[]}>
            <SimpleMaterialityMatrix />
          </MatrixProvider>
        </div>
      </>
    );
  } else {
    return <LoaderFullscreen />;
  }
}
