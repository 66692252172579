import { useEffect, ReactNode } from 'react';
import { useCurrentAuth } from '../providers/CurrentAuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

export function VisiativLoggedInOnlyGuard({
  children,
}: {
  children: ReactNode;
}) {
  const location = useLocation();
  const auth = useCurrentAuth();
  const navigate = useNavigate();

  const destination = encodeURIComponent(
    `${location.pathname}${location.search}`,
  );
  const loginUrl = `/integrations/visiativ/login?destination=${destination}`;

  useEffect(() => {
    if (auth === null) {
      navigate(loginUrl, { replace: true });
    }
  }, [auth, navigate, loginUrl]);

  if (!auth) {
    return null;
  }

  return <>{children}</>;
}
