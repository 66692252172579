import React, { useState } from 'react';
import { Repository } from '../../../graphql/generated';
import StringDropdown, {
  StringDropdownItem,
} from '../../generic/form/StringDropdown';
import { useTranslation } from '@hooks/useTranslation';

export function RepositoryDropdown({
  initialRepository,
  updateRepository,
}: {
  initialRepository: Repository;
  updateRepository: (repository: Repository) => void;
}) {
  const { t } = useTranslation();
  const [repository, setRepository] = useState(initialRepository);
  const availableRepositories: StringDropdownItem[] = [
    {
      id: Repository.EfragEsrs,
      label: t(`enum:repository.${Repository.EfragEsrs}`),
    },
    {
      id: Repository.Iso26000,
      label: t(`enum:repository.${Repository.Iso26000}`),
    },
  ];
  const currentItem =
    availableRepositories.find((item) => item.id === repository) || null;
  const pickRepository = (item: StringDropdownItem) => {
    setRepository(item.id as Repository);
    updateRepository(item.id as Repository);
  };

  return (
    <StringDropdown
      availableItems={availableRepositories}
      item={currentItem}
      setItem={pickRepository}
    />
  );
}
