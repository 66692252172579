import React from 'react';
import { CmsComponentFootnote_FootnoteFragment } from '../../../graphql/cms/generated';
import { BlocksRenderer } from '../renderers/BlocksRenderer';

export function Footnote({
  footnote,
}: {
  footnote: CmsComponentFootnote_FootnoteFragment;
}) {
  return (
    <div className="space-y-2">
      <BlocksRenderer
        content={footnote.content}
        blocks={{
          paragraph: ({ children }) => (
            <p className="italic text-xs">{children}</p>
          ),
        }}
      />
    </div>
  );
}
