import { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useFirebaseAuth } from 'providers/FirebaseProvider';
import { useCurrentAuth } from 'providers/CurrentAuthProvider';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import {
  linkWithCredential,
  OAuthCredential,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Loader } from '../../../components/generic/Loader';
import { Auth } from '@firebase/auth';
import logo from '../../../assets/images/logo-with-name.svg';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { ModalContextType, useModal } from '../../../components/layout/Modal';
import { useVisiativ } from '../../../providers/VisiativProvider';
import { useEmailExistsQuery } from '../../../graphql/generated';
import { ErrorIcon } from '../../../components/icons';

function getVisiativOAuthProvider(visiativLoginHint: string | null) {
  if (process.env.INTEGRATIONS_VISIATIV_OIDC) {
    const provider = new OAuthProvider(process.env.INTEGRATIONS_VISIATIV_OIDC);
    provider.addScope('openid');
    provider.addScope('profile');
    provider.addScope('email');
    if (visiativLoginHint) {
      provider.setCustomParameters({ login_hint: visiativLoginHint });
    }

    return provider;
  }
}

function initiateVisiativLogin(
  firebaseAuth: Auth,
  modal: ModalContextType,
  visiativLoginHint: string | null,
) {
  const visiativOAuthProvider = getVisiativOAuthProvider(visiativLoginHint);
  if (visiativOAuthProvider) {
    signInWithPopup(firebaseAuth, visiativOAuthProvider).catch(
      function (error) {
        if (error.code === 'auth/account-exists-with-different-credential') {
          console.error('auth/account-exists-with-different-credential');
          const credential = OAuthProvider.credentialFromError(error);
          if (credential) {
            // Proceed to link the account with Good Steps one
            modal.openModalWithComponent(
              <GoodStepsLoginScreen visiativCredential={credential} />,
              'Associer votre compte Good Steps',
            );
          }
        } else {
          console.error(error);
        }
      },
    );
  }
}

export function VisiativIntegrationLoginScreen() {
  const currentAuth = useCurrentAuth();
  const currentUser = useCurrentUser();
  const visiativLoginHint = useVisiativ();
  const firebaseAuth = useFirebaseAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const modal = useModal();

  useEffect(() => {
    if (currentAuth && currentUser) {
      redirectToAskedPage(navigate, searchParams);
    }
  }, [currentAuth, currentUser, navigate, searchParams]);

  const { data } = useEmailExistsQuery({
    variables: {
      email: visiativLoginHint || '',
    },
    skip: !visiativLoginHint,
  });

  return (
    <CenteredLayout>
      <h1>Bienvenue !</h1>
      {data?.emailExists ? (
        <p className="">Vous avez déjà un compte Good Steps 🥳</p>
      ) : (
        <p className="">Vous avez déjà un compte Good Steps ?</p>
      )}

      <MessageBox type={MessageBoxType.Info}>
        <p>
          Nous allons procéder à l'association
          <br />
          de votre compte Good Steps{' '}
          <span className="font-bold">{visiativLoginHint}</span>
          <br />
          avec <strong>votre compte Visiativ</strong>
        </p>
      </MessageBox>
      <button
        className="primary"
        onClick={() =>
          initiateVisiativLogin(firebaseAuth, modal, visiativLoginHint)
        }
      >
        Associer mon compte Good Steps
      </button>
    </CenteredLayout>
  );
}

function CenteredLayout({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full bg-white ">
      <div className="w-full p-8">
        <img className="h-10" src={logo} alt="Good Steps logo" />
      </div>
      <div className="grow max-w-lg flex flex-col gap-8 items-center justify-center">
        <div className="flex flex-col gap-4">{children}</div>
      </div>
    </div>
  );
}

function GoodStepsLoginScreen({
  visiativCredential,
}: {
  visiativCredential: OAuthCredential;
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const firebaseAuth = useFirebaseAuth();
  const modal = useModal();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        const visiativProviderData = userCredential.user.providerData.find(
          (provider) => provider.providerId === 'oidc.visiativ',
        );

        if (visiativProviderData) {
          // Account already linked
        } else {
          if (firebaseAuth.currentUser) {
            linkWithCredential(firebaseAuth.currentUser, visiativCredential)
              .then(() => {
                console.log('success');
                modal.closeModal();
              })
              .catch((err) => console.error(err));
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        setError('Mauvais mot de passe ou compte inexistant');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-stretch w-full">
      <div className="grow flex flex-col gap-8 justify-between items-center self-center max-w-sm">
        <h2 className="text-3xl text-center">Dernière étape !</h2>
        <p className="text-center text-gray-500">
          Connectez-vous avec votre compte Good Steps pour finaliser
          l'association
        </p>

        <form
          name="loginForm"
          className="space-y-5 w-full"
          onSubmit={handleSubmit}
        >
          <div className="space-y-1">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(null);
              }}
              required
            />
          </div>

          <div className="space-y-1">
            <label htmlFor="password">Mot de passe</label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
              }}
              required
            />
          </div>

          <div className="flex justify-center">
            {isLoading ? (
              <Loader className="w-10 h-10 text-black" />
            ) : (
              <input
                className="primary purple w-full"
                type="submit"
                value="Relier mon compte"
              />
            )}
          </div>
          {error && (
            <div className="p-4 flex gap-4 items-center bg-red-50 text-red-700">
              <ErrorIcon className="shrink-0" />
              {error}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

function redirectToAskedPage(
  navigate: NavigateFunction,
  searchParams: URLSearchParams,
) {
  const destination = searchParams.get('destination');
  navigate(destination && destination[0] === '/' ? destination : '/', {
    replace: true,
  });
}
