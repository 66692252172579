import React from 'react';
import { useProContext } from '../../../../providers/ProContextProvider';
import {
  DoubleMaterialityStaticMatrix_ReferentialFragment,
  DoubleMaterialityStaticMatrix_StakeFragment,
  useDoubleMaterialityStakeholderMatrixQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import { StakesMaterialityChart } from '../../survey/questionsDataviz/stakesMaterialityChart/StakesMaterialityChart';
import { useTranslation } from '@hooks/useTranslation';
import { Namespace, TFunction } from 'i18next';
import { TMaterialityMatrixChartJsOptions } from '../../../generic/chart/types';
import { useMatrix } from './MatrixContext';

export function DoubleMaterialityStakeholderMatrix() {
  const proContext = useProContext();
  const { t } = useTranslation();
  const { stakeholderSegments } = useMatrix();

  const query = useDoubleMaterialityStakeholderMatrixQuery({
    variables: {
      referentialId: proContext?.company?.referential?.id || '',
      input: {
        companyId: proContext?.company?.id || '',
        filters: {
          stakeholderSegments: stakeholderSegments ?? [],
        },
      },
    },
    /** cache is disabled because calculators are using the same properties to store
     * financialMaterialityScore and impactMaterialityScore.
     * We need to change this behavior to get cache back.
     */
    fetchPolicy: 'no-cache',
  });

  const referential: DoubleMaterialityStaticMatrix_ReferentialFragment | null =
    query.data?.referential ?? null;
  const stakeMaterialities:
    | DoubleMaterialityStaticMatrix_StakeFragment[]
    | null = query.data?.computeDoubleMaterialitiesStakeholder ?? null;

  const stakesMaterialities = stakeMaterialities
    ?.filter((stake) => {
      return !(
        !stake.financialMaterialityScore && !stake.impactMaterialityScore
      );
    })
    .map((stake) => {
      return {
        stake,
        xMateriality: stake.financialMaterialityScore ?? 0,
        yMateriality: stake.impactMaterialityScore ?? 0,
        materialityScore: Math.max(
          stake.financialMaterialityScore ?? 0,
          stake.impactMaterialityScore ?? 0,
        ),
      };
    });

  const options = buildChartOptions(referential, t);

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <StakesMaterialityChart
      stakesMaterialities={stakesMaterialities || []}
      options={{ ...options, enableStakeholderSegmentsFilter: true }}
    />
  );
}

function buildChartOptions(
  referential: DoubleMaterialityStaticMatrix_ReferentialFragment | null,
  t: TFunction<Namespace, ''>,
): TMaterialityMatrixChartJsOptions {
  const financialMaterialityThreshold =
    referential?.company.financialMaterialityThreshold;
  const impactMaterialityThreshold =
    referential?.company.impactMaterialityThreshold;

  const chartOptions: TMaterialityMatrixChartJsOptions = {
    enableConsensusLine: true,
    defaultConsensusLineState: false, // Consensus line hidden by default for double materiality
    enableZoom: true,
    xAxisLabel: t(
      'charts.double_materiality_matrix.financial_materiality_axis_label',
    ),
    yAxisLabel: t(
      'charts.double_materiality_matrix.impact_materiality_axis_label',
    ),
  };
  if (financialMaterialityThreshold) {
    chartOptions.xThreshold = financialMaterialityThreshold;
    chartOptions.enableThresholds = true;
  }
  if (impactMaterialityThreshold) {
    chartOptions.yThreshold = impactMaterialityThreshold;
    chartOptions.enableThresholds = true;
  }

  return chartOptions;
}
