import { UserFieldsFragment } from 'graphql/generated';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { CopyTextToClipboardButton } from '../../generic/CopyTextToClipboardButton';
import React from 'react';
import { getInvitationLink } from '../../../services/CompanyService';
import loginIllustration from '../../../assets/images/illustrations/login.png';
import { IllustratedExplanationMessage } from '../../generic/IllustratedExplanationMessage';
import { User } from './User';

export function ProUsersList({
  users,
  companyId,
}: {
  users: UserFieldsFragment[];
  companyId: string;
}) {
  if (users.length > 0) {
    const employeeInvitationLink = getInvitationLink(companyId, null);
    return (
      <div className="main-content w-full max-w-5xl p-8 space-y-8">
        <IllustratedExplanationMessage
          illustration={<img src={loginIllustration} alt="" />}
          title="Invitez vos collègues coachs"
          description="Utilisez ce lien pour inviter vos collaborateurs à rejoindre votre espace sur la plateforme Good Steps et ainsi partager tous vos projets !"
        >
          <CopyTextToClipboardButton
            buttonLabel="Copier le lien d'invitation"
            textToCopy={employeeInvitationLink}
            className="purple small"
          />
        </IllustratedExplanationMessage>

        <table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Email</th>
              <th>Dernière connexion</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <User user={user} key={index} companyId={companyId} />
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Info}>Aucun utilisateur</MessageBox>
    );
  }
}
