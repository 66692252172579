import React from 'react';
import { LoaderFullscreen } from '../components/layout/Loader';
import { ProHomeScreen } from '../screens/pro/home/ProHomeScreen';
import { useProContext } from './ProContextProvider';

type ProContextRequiredProps = {
  requiredCompany: boolean;
};

export const withProContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options?: ProContextRequiredProps,
) => {
  return function WithProContext(props: P) {
    const proContext = useProContext();

    if (proContext?.isLoadingCompany) {
      return (
        <div className="w-full h-screen bg-white">
          <LoaderFullscreen />
        </div>
      );
    }

    if (options?.requiredCompany && !proContext?.company) {
      return <ProHomeScreen />;
    }

    return <WrappedComponent {...props} />;
  };
};
