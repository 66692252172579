import React from 'react';
import {
  MaturityEvaluationAnswers_MaturityEvaluationFragment,
  SurveyAnswerSummaryFieldsFragment,
  SurveyQuestionAnswersFieldsFragment,
  SurveyQuestionType,
  useMaturityEvaluationAnswersQuery,
  useSurveyQuestionsWithLatestAnswerQuery,
} from '../../../graphql/generated';
import { LoaderFullscreen } from '../../layout/Loader';
import { useProContext } from '../../../providers/ProContextProvider';
import { NumberCircle } from '../../generic/NumberCircle';
import clsx from 'clsx';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { InnerHtml } from '../../generic/InnerHtml';
import { ChevronDownIcon } from '../../icons';

export function MaturityEvaluationAnswers({ stakeId }: { stakeId: string }) {
  const proContext = useProContext();
  const { data, loading } = useMaturityEvaluationAnswersQuery({
    variables: { companyId: proContext?.company?.id || '' },
  });

  if (loading) {
    return <LoaderFullscreen />;
  }
  if (!data?.maturityEvaluations) {
    return null;
  }

  return (
    <MaturityEvaluationAnswersInner
      stakeId={stakeId}
      maturityEvaluationSurveys={data.maturityEvaluations}
    />
  );
}

export function MaturityEvaluationAnswersInner({
  stakeId,
  maturityEvaluationSurveys,
}: {
  stakeId: string;
  maturityEvaluationSurveys: MaturityEvaluationAnswers_MaturityEvaluationFragment[];
}) {
  // Load questions with latest answer for each
  const surveyQuery = useSurveyQuestionsWithLatestAnswerQuery({
    variables: {
      surveysId: maturityEvaluationSurveys?.map((survey) => survey.id) || [],
      stakeId: stakeId,
    },
    fetchPolicy: 'network-only',
  });

  if (surveyQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (surveyQuery.data?.surveyQuestionsWithLatestAnswer) {
    return surveyQuery.data?.surveyQuestionsWithLatestAnswer?.length > 0 ? (
      <div className="flex flex-col space-y-4">
        {surveyQuery.data.surveyQuestionsWithLatestAnswer.map(
          (question, index) => (
            <MaturityEvaluationAnswersRouter
              key={question.id}
              question={question}
              index={index}
            />
          ),
        )}
      </div>
    ) : (
      <MessageBox type={MessageBoxType.Info}>
        Aucune question liée à cet enjeu
      </MessageBox>
    );
  }

  return null;
}

function MaturityEvaluationAnswersRouter({
  question,
  index,
}: {
  question: SurveyQuestionAnswersFieldsFragment;
  index: number;
}) {
  // Answer is the first one
  const answer = question.answers?.[0];

  switch (question.type) {
    case SurveyQuestionType.MaturityEvaluation:
    case SurveyQuestionType.MultipleChoice:
      return (
        <MaturityEvaluationChoiceAnswer
          question={question}
          answer={answer}
          index={index}
        />
      );
    case SurveyQuestionType.ShortText:
      return (
        <MaturityEvaluationTextAnswer
          question={question}
          answer={answer}
          index={index}
        />
      );
    default:
      // TODO: manage all question types (short text, number...)
      return (
        <MessageBox type={MessageBoxType.Warning}>Type non supporté</MessageBox>
      );
  }
}

function MaturityEvaluationChoiceAnswer({
  question,
  answer,
  index,
}: {
  question: SurveyQuestionAnswersFieldsFragment;
  answer: SurveyAnswerSummaryFieldsFragment | undefined;
  index: number;
}) {
  const [displayUnselectedChoices, setDisplayUnselectedChoices] =
    React.useState(false);

  // Max score is depending on question.canSelectMultipleChoices : if true, we must add all question choices scores, else we must take the max score
  const questionMaxScore = question.canSelectMultipleChoices
    ? question.choices?.reduce((acc, choice) => acc + (choice.score || 0), 0) ||
      0
    : question.choices?.reduce(
        (acc, choice) => Math.max(acc, choice.score || 0),
        0,
      ) || 0;
  // Answer score is the sum of all selected choices scores
  const answersScore = question.canSelectMultipleChoices
    ? answer?.choices?.reduce((acc, choice) => {
        // Score is in question.choices, so we must find the choice in question.choices
        const questionChoice = question.choices?.find(
          (c) => c.id === choice.id,
        );
        return acc + (questionChoice?.score || 0);
      }, 0) || 0
    : // Choice come from answer.choice, so we must find the choice in question.choices
      question.choices?.find((c) => c.id === answer?.choice?.id)?.score || 0;

  return (
    <div className="border border-gray-100 rounded-xl shadow-sm p-4 space-y-2 bg-white">
      <div className="flex items-start gap-2">
        <NumberCircle number={index + 1} size={8} />
        <div className="flex flex-col gap-2 items-stretch w-full">
          <div className="flex items-start justify-between gap-4">
            <div>
              <div className="font-bold">{question.title}</div>
              {question.description && (
                <div className="text-gray-500 text-sm">
                  <InnerHtml html={question.description} />
                </div>
              )}
            </div>

            <div className="flex items-center gap-1">
              <div className="text-sm text-gray-500">Score</div>
              <div className="tag blue">
                {answersScore}/{questionMaxScore}
              </div>
            </div>
          </div>

          {(question.choices || [])
            .toSorted((a, b) => (a.score || 0) - (b.score || 0))
            .map((choice, choiceIndex) => {
              const isSelected =
                answer?.choices?.find((c) => c.id === choice.id) ||
                answer?.choice?.id === choice.id;

              if (!isSelected && !displayUnselectedChoices) {
                return null;
              }

              return (
                <div
                  className={clsx(
                    'border border-gray-100 rounded-lg p-2 flex items-start gap-2 shadow-sm',
                    isSelected && 'border-gray-900 bg-yellow-50',
                  )}
                  key={choice.id}
                >
                  <NumberCircle number={choiceIndex} size={6} />
                  <div className="text-sm grow">{choice.label}</div>
                  <div className="flex items-center gap-1">
                    <div className="text-sm text-gray-500">Score</div>
                    <div className="tag blue">{choice.score || '0'}</div>
                  </div>
                </div>
              );
            })}

          <div>
            <button
              className="tertiary small"
              onClick={() =>
                setDisplayUnselectedChoices(!displayUnselectedChoices)
              }
            >
              {!displayUnselectedChoices && <div>Voir toutes les options</div>}
              {displayUnselectedChoices && (
                <div>Cacher les options non sélectionnées</div>
              )}
              <ChevronDownIcon
                className={clsx(displayUnselectedChoices && 'rotate-180')}
              />
            </button>
          </div>

          <AnswerComment answer={answer} />
        </div>
      </div>
    </div>
  );
}

function MaturityEvaluationTextAnswer({
  question,
  answer,
  index,
}: {
  question: SurveyQuestionAnswersFieldsFragment;
  answer: SurveyAnswerSummaryFieldsFragment | undefined;
  index: number;
}) {
  return (
    <div className="border border-gray-100 rounded-xl shadow-sm p-4 space-y-2 bg-white">
      <div className="flex items-start gap-2">
        <NumberCircle number={index + 1} size={8} />
        <div className="flex flex-col gap-2 items-stretch w-full">
          <div className="flex items-start justify-between gap-4">
            <div>
              <div className="font-bold">{question.title}</div>
              {question.description && (
                <div className="text-gray-500 text-sm">
                  {question.description}
                </div>
              )}
            </div>
          </div>

          {answer?.text ? (
            <div className="p-4 border border-gray-900 rounded-xl text-sm">
              {answer?.text}
            </div>
          ) : (
            <div className="p-4 border border-gray-900 rounded-xl text-sm italic text-gray-500">
              Aucune réponse
            </div>
          )}

          <AnswerComment answer={answer} />
        </div>
      </div>
    </div>
  );
}

function AnswerComment({
  answer,
}: {
  answer: SurveyAnswerSummaryFieldsFragment | undefined;
}) {
  if (!answer?.comment) {
    return null;
  }
  return (
    <div className="p-2 text-sm border border-yellow-300 bg-yellow-100 rounded-lg">
      {answer.comment}
    </div>
  );
}
