import {
  StakeMaterialityReason_StakeFragment,
  useStakeMaterialityReason_StakeUpdateMutation,
} from '../../../../graphql/generated';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { useToast } from '../../../layout/Toast';
import React, { useState } from 'react';
import StakeMaterialityToggle from '../../../pro/stakes/StakeMaterialityToggle';
import { isProUser } from '../../../../services/CompanyService';
import RichTextEditorTextarea from '../../../generic/form/RichTextEditorTextarea';
import { ChevronDownIcon } from '../../../icons';
import { Loader } from '../../../generic/Loader';

export function StakeMaterialityReason({
  stake,
}: {
  stake: StakeMaterialityReason_StakeFragment;
}) {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [isUpdating, setIsUpdating] = useState(false);
  const [materialityReason, setMaterialityReason] = React.useState<string>(
    stake.materialityReason || '',
  );
  const [displayMaterialityReason, setDisplayMaterialityReason] =
    useState<boolean>(!!stake.materialityReason);
  const [editDiagnosticStakes] =
    useStakeMaterialityReason_StakeUpdateMutation();

  const saveMaterialityReason = () => {
    setIsUpdating(true);
    editDiagnosticStakes({
      variables: {
        input: {
          id: stake.id,
          materialityReason: materialityReason,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Matérialité enregistrée');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <StakeMaterialityToggle
          stake={stake}
          disabled={!isProUser(currentUser)}
        />
        <h6>Enjeu matériel pour l&apos;entreprise</h6>
      </div>
      {displayMaterialityReason ? (
        <RichTextEditorTextarea
          value={materialityReason}
          disabled={!isProUser(currentUser)}
          placeholder={
            stake.isDisabled
              ? 'Raison de la non-matérialité'
              : 'Raison de la matérialité'
          }
          onChange={setMaterialityReason}
        />
      ) : (
        <button
          className="unstyled small text-gray-500"
          onClick={() => setDisplayMaterialityReason(true)}
        >
          <div>Ajouter une justification</div>
          <ChevronDownIcon />
        </button>
      )}

      {materialityReason !== (stake.materialityReason || '') && (
        <div className="flex justify-end">
          <button
            className="primary purple small"
            onClick={saveMaterialityReason}
            disabled={
              isUpdating ||
              materialityReason === (stake.materialityReason || '')
            }
          >
            {isUpdating && <Loader />}
            <div>Enregistrer</div>
          </button>
        </div>
      )}
    </div>
  );
}
