import React from 'react';
import { useProContext } from '../../providers/ProContextProvider';
import { PageTitle } from '../../components/nav/PageTitle';
import { Referential } from '../../components/pro/stakes/Referential';
import { ProBreadcrumb } from '../../components/nav/ProBreadcrumb';
import { ProHomeScreen } from './home/ProHomeScreen';
import { useTranslation } from '@hooks/useTranslation';

export function ProStakesScreen() {
  const proContext = useProContext();
  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  return (
    <>
      <PageTitle title="Enjeux" />
      <ProBreadcrumb />
      <StakesHeader />
      <div className="p-8 w-full">
        <Referential referentialId={proContext.company.referential?.id} />
      </div>
    </>
  );
}

function StakesHeader() {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 py-10">
      <div className="main-content flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <h2>{t('referential.header.title')}</h2>
          <p className="text-gray-500 max-w-3xl">
            {t('referential.header.description')}
          </p>
        </div>
      </div>
    </div>
  );
}
