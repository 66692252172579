import { useParams } from 'react-router-dom';
import React from 'react';
import { useConsultationRawMaterialityScreenQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import binocularsIllustration from '../../../assets/images/illustrations/binoculars.png';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ConsultationRawMateriality } from '../../../components/survey/ConsultationRawMateriality/ConsultationRawMateriality';

export const ConsultationRawMaterialityScreen = () => {
  const { consultationId } = useParams();

  const { data, loading } = useConsultationRawMaterialityScreenQuery({
    variables: { id: consultationId || '' },
    skip: !consultationId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.consultationRawMateriality) {
    return (
      <>
        <PageTitle title="Consultations" />
        <ConsultationRawMateriality
          consultation={data.consultationRawMateriality}
        />
      </>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de la consultation.
        </p>
      </MessageBox>
    );
  }
};

export function NoSurvey() {
  return (
    <div className="p-8 flex flex-col gap-4 w-full items-center justify-center bg-blue-50 h-full">
      <img src={binocularsIllustration} alt="" className="w-56 grayscale" />
      <h1>Ce sondage n'existe pas</h1>
    </div>
  );
}
