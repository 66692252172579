import { useParams } from 'react-router-dom';
import { Action } from '../../components/publications/roadmap/Action';
import { useActionQuery } from '../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../components/layout/Loader';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';

export function ActionScreen() {
  const { actionId } = useParams();

  const { data, loading } = useActionQuery({
    variables: {
      id: actionId || '',
    },
    skip: !actionId,
  });

  if (data?.action) {
    return <Action action={data.action} />;
  } else {
    return (
      <>
        {loading ? (
          <LoaderFullscreen />
        ) : (
          <MessageBox type={MessageBoxType.Error}>
            <p>Une erreur est survenue lors de la récupération de l'action.</p>
          </MessageBox>
        )}
      </>
    );
  }
}
