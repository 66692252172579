import { CompanyFieldsFragment, UserFieldsFragment } from 'graphql/generated';
import React from 'react';
import { CopyTextToClipboardButton } from '../../generic/CopyTextToClipboardButton';
import { getInvitationLink } from '../../../services/CompanyService';
import { HelpTooltip } from '../../generic/HelpTooltip';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { User } from './User';

export function Employees({ company }: { company: CompanyFieldsFragment }) {
  const employees = company.employees.toSorted((a, b) => {
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  });

  const collaboratorInvitationLink = getInvitationLink(company.id, null);

  return (
    <div className="space-y-4 pt-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <h2 className="title-h4">Client</h2>
          <div className="text-gray-500">Les destinataires du diagnostic.</div>
          <HelpTooltip>
            <p className="text-sm font-normal">
              Outre un droit de lecture, il·elles peuvent gérer les tâches de la
              feuille de route et inviter d’autres utilisateurs client.
            </p>
          </HelpTooltip>
        </div>
        <CopyTextToClipboardButton
          buttonLabel="Copier le lien d'invitation client"
          textToCopy={collaboratorInvitationLink}
          className="purple small"
        />
      </div>
      <EmployeesList employees={employees} companyId={company.id} />
    </div>
  );
}

function EmployeesList({
  employees,
  companyId,
}: {
  employees: UserFieldsFragment[];
  companyId: string;
}) {
  if (employees.length > 0) {
    return (
      <table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Dernière connexion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {employees.map((user, index) => (
            <User user={user} key={index} companyId={companyId} />
          ))}
        </tbody>
      </table>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Info}>Aucun utilisateur</MessageBox>
    );
  }
}
