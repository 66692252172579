import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';

export function AdminLayout() {
  const location = useLocation();

  return (
    <div className="flex h-full w-full">
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </div>
  );
}
