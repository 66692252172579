import React from 'react';
import { Breadcrumb } from './Breadcrumb';

export function ProBreadcrumb({
  currentPageTitle,
}: {
  currentPageTitle?: string;
}) {
  return (
    <div className="px-8 py-6 border-b border-gray-100 shadow-sm">
      <Breadcrumb currentPageTitle={currentPageTitle} />
    </div>
  );
}
