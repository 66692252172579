import {
  TMaterialityMatrixChartDatapoint,
  TMaterialityMatrixChartJsOptions,
} from './types';
import {
  AnnotationOptions,
  AnnotationTypeRegistry,
} from 'chartjs-plugin-annotation';
import { mapThemeColorToHexColor } from '../../../services/TailwindService';
import { ThemeColor } from '../../../graphql/generated';
import { wrapLongLabel } from '../../../services/ChartJsService';
import { DragHandler } from './dragHandlers';

const getThresholdAnnotations = (
  options: TMaterialityMatrixChartJsOptions,
): AnnotationOptions<keyof AnnotationTypeRegistry>[] => {
  const annotations: AnnotationOptions<keyof AnnotationTypeRegistry>[] = [];
  if (options.xThreshold) {
    annotations.push({
      type: 'box',
      backgroundColor: mapThemeColorToHexColor(ThemeColor.Purple, 50),
      borderWidth: 0,
      xMax: 10,
      xMin: options.xThreshold,
      drawTime: 'beforeDraw',
    });
  }
  if (options.yThreshold) {
    annotations.push({
      type: 'box',
      backgroundColor: mapThemeColorToHexColor(ThemeColor.Purple, 50),
      borderWidth: 0,
      yMax: 10,
      yMin: options.yThreshold,
      drawTime: 'beforeDraw',
    });
  }
  return annotations;
};

const getLabelAnnotations = (
  datapoints: TMaterialityMatrixChartDatapoint[],
  filteredDatapointIds: string[] = [],
  dragHandler: DragHandler,
): AnnotationOptions<keyof AnnotationTypeRegistry>[] => {
  return datapoints
    .filter((datapoint) => {
      if (filteredDatapointIds.length === 0) {
        return true;
      }
      return filteredDatapointIds.includes(datapoint.id);
    })
    .map((datapoint) => {
      const strongColor = mapThemeColorToHexColor(datapoint.color, 500);
      return {
        type: 'label',
        callout: {
          display: true,
          borderColor: strongColor,
          side: () => 10,
          start: () => '50%',
        },
        content: wrapLongLabel(datapoint.name),
        font: {
          size: 12,
        },
        position: {
          x: 'end',
          y: 'center',
        },
        xValue: datapoint.position.x,
        yValue: datapoint.position.y,
        xAdjust: -30,
        yAdjust: 30,
        z: 200,
        enter: (ctx) => {
          dragHandler.onEnter(ctx.element);
        },
        leave: () => {
          dragHandler.onLeave();
        },
      };
    });
};

export const getAnnotations = (
  options: TMaterialityMatrixChartJsOptions,
  datapoints: TMaterialityMatrixChartDatapoint[],
  dragHandler: DragHandler,
  filteredDatapointIds: string[] = [],
): AnnotationOptions<keyof AnnotationTypeRegistry>[] => {
  const annotations: AnnotationOptions<keyof AnnotationTypeRegistry>[] = [];
  if (options.enableThresholds) {
    annotations.push(...getThresholdAnnotations(options));
  }
  if (options.enableLabels) {
    annotations.push(
      ...getLabelAnnotations(datapoints, filteredDatapointIds, dragHandler),
    );
  }

  return annotations;
};
