import React, { useState } from 'react';
import { RoadmapFieldsFragment } from '../../../graphql/generated';
import { NavLink } from 'react-router-dom';
import binocularsIllustration from '../../../assets/images/illustrations/binoculars.png';
import expertsIllustration from '../../../assets/images/illustrations/experts.png';
import { RoadmapPriorityView } from './RoadmapPriorityView';
import { RoadmapListView } from './RoadmapListView';
import { DownloadFileButton } from '../../generic/DownloadFileButton';
import { Toggle } from '../../generic/form/Toggle';
import { BellIcon, QuoteWhiteIcon } from '../../icons';

export function Roadmap({ roadmap }: { roadmap: RoadmapFieldsFragment }) {
  const isRoadmapPublished = roadmap?.publishedAt !== null;

  return isRoadmapPublished ? (
    <RoadmapContent roadmap={roadmap} />
  ) : (
    <NoRoadmap />
  );
}

export function RoadmapContent({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  const [displayTimelineView, setDisplayTimelineView] = useState(true);

  return (
    <div className="pb-10">
      <RoadmapHeader roadmap={roadmap} />

      <div className="main-content w-full mt-10">
        <div className="flex items-center justify-end gap-2">
          <div className="text-sm font-semibold">Vue liste</div>
          <Toggle
            state={displayTimelineView}
            setState={setDisplayTimelineView}
          />
          <div className="text-sm font-semibold">Vue priorisée</div>
        </div>
      </div>
      <div className="main-content w-full pb-10">
        {displayTimelineView ? (
          <RoadmapPriorityView roadmap={roadmap} />
        ) : (
          <RoadmapListView roadmap={roadmap} />
        )}
      </div>
      {roadmap.linkToQuoteFile && (
        <div className="main-content w-full bg-blue-50 flex gap-8 items-center p-8 rounded-xl">
          <img src={expertsIllustration} alt="" className="w-40" />
          <div className="space-y-4">
            <h3>Allez encore plus loin grâce à nos expert·es</h3>
            <p className="text-sm">
              Découvrez notre proposition d'accompagnement pour aller plus loin
              dans vos objectifs grâce à nos experts qualifiés.
            </p>
            <DownloadFileButton
              url={roadmap.linkToQuoteFile}
              label={"Proposition d'accompagnement"}
              openInNewTab={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function RoadmapHeader({ roadmap }: { roadmap: RoadmapFieldsFragment }) {
  return (
    <div className="bg-blue-50 p-12">
      <div className="flex items-start justify-center gap-8 main-content">
        <div className="grow">
          <p className="text-gray-500 text-sm mb-2">{roadmap?.vision?.title}</p>
          <h2>{roadmap?.vision?.description}</h2>
        </div>
        {roadmap?.vision?.quote && (
          <div className="flex gap-4 p-4 rounded-xl bg-white shadow-sm max-w-lg">
            <div className="flex shrink-0">
              <QuoteWhiteIcon className="inline-block h-10 w-10 rounded-full" />
            </div>
            <div className="font-title  font-extrabold">
              {roadmap?.vision?.quote}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function NoRoadmap() {
  return (
    <div className="p-8 flex flex-col gap-4 w-full items-center justify-center bg-blue-50 h-full">
      <img src={binocularsIllustration} alt="" className="w-56 grayscale" />
      <h1>Votre feuille de route n’est pas encore prête</h1>
      <p className="max-w-2xl text-center">
        Votre feuille de route est la dernière étape du diagnostic RSE. Elle
        sera réalisée à la suite des différents interviews et des réponses aux
        consultations.
      </p>
      <p className="font-bold flex items-center gap-2">
        <span>
          <BellIcon />
        </span>
        <span>Vous serez notifié.e par email !</span>
      </p>
      <NavLink to="/">
        <button className="primary">Retour au suivi des étapes</button>
      </NavLink>
    </div>
  );
}
