import React from 'react';
import { LoaderFullscreen } from '../../components/layout/Loader';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { Referential } from '../../components/pro/stakes/Referential';
import { useTranslation } from '@hooks/useTranslation';

export function StakesScreen() {
  const currentUser = useCurrentUser();
  if (currentUser) {
    return (
      <>
        <StakesHeader />
        <div className="p-8 w-full">
          {currentUser?.company?.referential?.id && (
            <Referential
              referentialId={currentUser.company.referential.id}
              readonly={true}
            />
          )}
        </div>
      </>
    );
  } else {
    return <LoaderFullscreen />;
  }
}

function StakesHeader() {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 py-10">
      <div className="main-content flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <h2>{t('referential.header.title')}</h2>
          <p className="text-gray-500 max-w-3xl">
            {t('referential.header.description')}
          </p>
        </div>
      </div>
    </div>
  );
}
