import { createContext, useContext } from 'react';
import {
  initializeApp as firebaseInitializeApp,
  FirebaseApp,
} from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';

export function initializeApp() {
  return firebaseInitializeApp(JSON.parse(process.env.FIREBASE_CONFIG ?? '{}'));
}

export const FirebaseAppContext = createContext<FirebaseApp>(initializeApp());

export const useFirebaseApp = () => useContext(FirebaseAppContext);
export const useFirebaseAuth = () => getAuth(useFirebaseApp());

export const useSignOut = () => {
  const auth = useFirebaseAuth();
  return () => signOut(auth);
};
