import './App.css';
import { ScreensRouter } from './screens/ScreensRouter';
import { CurrentAuthProvider } from './providers/CurrentAuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { CurrentUserProvider } from './providers/CurrentUserProvider';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <CurrentAuthProvider>
          <CurrentUserProvider>
            <ScreensRouter />
          </CurrentUserProvider>
        </CurrentAuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
