import { NavLink } from 'react-router-dom';
import {
  CompanyTemplateSource,
  CompanyType,
  ProjectTemplatesList_ProjectItemFragment,
  useProjectTemplatesListLazyQuery,
} from 'graphql/generated';
import { useProContext } from '../../providers/ProContextProvider';
import { useModal } from '../layout/Modal';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { CompanyLogo } from './project/CompanyLogo';
import { getFrDateFromISOString } from '../../services/DateService';
import React, { useEffect, useState } from 'react';
import { RegisterNewProjectTemplateModal } from './RegisterNewProjectTemplateModal';
import hammerIllustration from '../../assets/images/illustrations/diagnostic/hammer.png';
import { IllustratedExplanationMessage } from '../generic/IllustratedExplanationMessage';
import { LoaderFullscreen } from '../layout/Loader';
import { ChevronRightIcon, PlusIcon } from '../icons';
import { ProRoutes } from '../../screens/ScreensRouter';

export function ProjectTemplatesList() {
  const currentUser = useCurrentUser();
  const modal = useModal();

  const [templates, setTemplates] = useState<
    ProjectTemplatesList_ProjectItemFragment[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  // Trigger templates search mutation at first load
  const [searchCompanyTemplatesQuery] = useProjectTemplatesListLazyQuery();

  useEffect(() => {
    setIsLoading(true);
    searchCompanyTemplatesQuery({
      variables: {
        input: {
          companyId: currentUser?.company?.id || '',
          source: CompanyTemplateSource.OwnTemplates,
        },
      },
    })
      .then((result) => {
        result.data?.searchCompanyTemplates &&
          setTemplates(result.data.searchCompanyTemplates);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsLoading(false));
    /* eslint-disable-next-line */
  }, []);

  if (isLoading) {
    return <LoaderFullscreen />;
  }

  const openRegisterDiagnosticModal = () => {
    modal.openModalWithComponent(
      <RegisterNewProjectTemplateModal companyType={CompanyType.Template} />,
      'Créer un modèle de projet',
    );
  };

  return (
    <div className="p-8 space-y-8">
      <IllustratedExplanationMessage
        illustration={<img src={hammerIllustration} alt="" />}
        title="Gagnez du temps avec les modèles de projet !"
        description="Créez des modèles pour ne pas repartir à 0 à chaque projet"
      >
        <button
          className="primary small purple shrink-0"
          onClick={openRegisterDiagnosticModal}
        >
          <PlusIcon />
          <span>Créer un modèle de projet</span>
        </button>
      </IllustratedExplanationMessage>
      {templates.length > 0 ? (
        <CompaniesList companies={templates} />
      ) : (
        <MessageBox type={MessageBoxType.Info}>
          <p>Créez votre premier modèle de projet dès maintenant !</p>
        </MessageBox>
      )}
    </div>
  );
}

function CompaniesList({
  companies,
}: {
  companies: ProjectTemplatesList_ProjectItemFragment[];
}) {
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="w-8/12">Modèle</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {companies
          // Sort by creation date
          .toSorted((a, b) => {
            if (a.createdAt && b.createdAt) {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            }
            return 0;
          })
          .map((company) => (
            <CompanyItem company={company} key={company.id} />
          ))}
      </tbody>
    </table>
  );
}

function CompanyItem({
  company,
}: {
  company: ProjectTemplatesList_ProjectItemFragment;
}) {
  const proContext = useProContext();
  const chooseItem = () => {
    if (proContext) {
      proContext.updateCompany(company.id, true);
    }
  };

  return (
    <tr key={company.id}>
      <td className="">
        <NavLink
          to={ProRoutes.Project}
          onClick={chooseItem}
          className="flex items-center gap-2"
        >
          <CompanyLogo companyId={company.id} size={'small'} />
          <div className="">
            <div className="font-bold">{company.name}</div>
            <div className="text-xs text-gray-500 italic font-light">
              Créé le {getFrDateFromISOString(company.createdAt)}
              {company.createdBy?.firstName &&
                ` par ${company.createdBy?.firstName} ${company.createdBy?.lastName}`}
            </div>
          </div>
        </NavLink>
      </td>
      <td>
        <div className="flex justify-end w-full">
          <NavLink to={ProRoutes.Project} onClick={chooseItem}>
            <button className="tertiary">
              <ChevronRightIcon />
            </button>
          </NavLink>
        </div>
      </td>
    </tr>
  );
}
