import {
  AuthRoles,
  CompanySummaryFieldsFragment,
  CurrentUserFieldsFragment,
  RoadmapSummaryFieldsFragment,
} from '../graphql/generated';

export function isProUser(
  currentUser: CurrentUserFieldsFragment | undefined | null,
): boolean {
  return (
    (currentUser?.auth?.roles || []).filter((role) =>
      [AuthRoles.Coach.valueOf(), AuthRoles.Superadmin.valueOf()].includes(
        role,
      ),
    ).length > 0
  );
}

export function isCoachUser(
  currentUser: CurrentUserFieldsFragment | undefined | null,
): boolean {
  return (
    (currentUser?.auth?.roles || []).filter((role) =>
      [AuthRoles.Coach.valueOf()].includes(role),
    ).length > 0
  );
}

export function isSuperadminUser(
  currentUser: CurrentUserFieldsFragment | undefined | null,
): boolean {
  return (
    (currentUser?.auth?.roles || []).filter((role) =>
      [AuthRoles.Superadmin.valueOf()].includes(role),
    ).length > 0
  );
}

export function getLatestRoadmapSummary(
  currentUser: CurrentUserFieldsFragment | undefined | null,
): RoadmapSummaryFieldsFragment | undefined {
  if (
    currentUser?.company?.roadmaps &&
    currentUser.company.roadmaps.length > 0
  ) {
    return currentUser.company.roadmaps[
      currentUser.company.roadmaps.length - 1
    ];
  }
}

// TODO: refactor this to use the same function as getLatestRoadmapSummary
export function getLatestCompanyRoadmapSummary(
  company: CompanySummaryFieldsFragment | undefined | null,
): RoadmapSummaryFieldsFragment | undefined {
  if (company?.roadmaps && company.roadmaps.length > 0) {
    return company.roadmaps[company.roadmaps.length - 1];
  }
}

export function getInvitationLink(companyId: string, role: AuthRoles | null) {
  switch (role) {
    case AuthRoles.Coach:
      return `${window.location.origin}/register/${companyId}/${role}`;
    default:
      return `${window.location.origin}/register/${companyId}/collaborator`;
  }
}
