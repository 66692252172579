import { useParams } from 'react-router-dom';
import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useProContext } from '../../../providers/ProContextProvider';
import {
  ProMaturityEvaluationScreen_MaturityEvaluationFragment,
  useCompanyAbstractQuery,
  useProMaturityEvaluationScreenQuery,
} from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { ProMaturityEvaluation } from '../../../components/pro/maturity/ProMaturityEvaluation';

export function ProMaturityEvaluationScreen() {
  const { surveyId } = useParams();

  const { data, loading } = useProMaturityEvaluationScreenQuery({
    variables: {
      maturityEvaluationId: surveyId || '',
    },
    skip: !surveyId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.maturityEvaluation) {
    return (
      <ProMaturityEvaluationScreenInner
        maturityEvaluation={data.maturityEvaluation}
      />
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de l&apos;évaluation
          de maturité.
        </p>
      </MessageBox>
    );
  }
}

function ProMaturityEvaluationScreenInner({
  maturityEvaluation,
}: {
  maturityEvaluation: ProMaturityEvaluationScreen_MaturityEvaluationFragment;
}) {
  // Select and load automatically the corresponding company
  const proContext = useProContext();
  const { data } = useCompanyAbstractQuery({
    variables: {
      id: maturityEvaluation.company?.id || '',
    },
    skip: !maturityEvaluation.company,
    fetchPolicy: 'network-only',
  });
  if (proContext && !proContext?.company && data?.company) {
    proContext.updateCompany(data?.company.id, false);
  }

  return (
    <>
      <PageTitle title="Évaluation de maturité" />
      <ProMaturityEvaluation survey={maturityEvaluation} />
    </>
  );
}
