import React, { useState } from 'react';
import { ConsultationRawMateriality_ResultFragment } from '../../../graphql/generated';
import { ConsultationRawMaterialityResults } from './ConsultationRawMaterialityResults';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../nav/Tabs';

const enum CONSULTATION_RAW_MATERIALITY_TABS {
  RESULTS = 'RESULTS',
}

export const ConsultationRawMateriality = ({
  consultation,
}: {
  consultation: ConsultationRawMateriality_ResultFragment;
}) => {
  const tabs: TabItem[] = [
    { key: CONSULTATION_RAW_MATERIALITY_TABS.RESULTS, name: 'Résultats' },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="p-4">
        <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
      </div>
      <div className="grow overflow-scroll flex">
        <InnerTab currentTab={currentTab} consultation={consultation} />
      </div>
    </div>
  );
};

function InnerTab({
  currentTab,
  consultation,
}: {
  currentTab: TabItem;
  consultation: ConsultationRawMateriality_ResultFragment;
}) {
  switch (currentTab.key) {
    case CONSULTATION_RAW_MATERIALITY_TABS.RESULTS:
      return (
        <div className="main-content py-8 w-full flex flex-col gap-8">
          <ConsultationRawMaterialityResults consultation={consultation} />
        </div>
      );
    default:
      return null;
  }
}
