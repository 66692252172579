import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  CsrStandardTopicTag_CsrStandardTopicFragment,
  Repository,
  SurveyLanguage,
} from '../../../graphql/generated';
import { EuIcon, Iso26000Icon } from '../../icons';
import clsx from 'clsx';

export function CsrStandardTopicTag({
  csrStandardTopic,
  language,
}: {
  csrStandardTopic: CsrStandardTopicTag_CsrStandardTopicFragment;
  language?: SurveyLanguage;
}) {
  switch (csrStandardTopic.standard.type) {
    case Repository.EfragEsrs:
      return (
        <CsrStandardTopicStyledTag
          csrStandardTopic={csrStandardTopic}
          language={language}
          textCssClass="text-blue-900"
          backgroundCssClass="bg-gray-50"
          icon={
            <div className="bg-blue-900 rounded px-1.5 py-0.5">
              <EuIcon className="w-3.5 h-3.5 text-yellow-500" />
            </div>
          }
        />
      );
    case Repository.Iso26000:
      return (
        <CsrStandardTopicStyledTag
          csrStandardTopic={csrStandardTopic}
          language={language}
          textCssClass="text-red-900"
          backgroundCssClass="bg-red-50"
          icon={
            <div className="bg-red-900 rounded px-1.5 py-0">
              <Iso26000Icon className="w-5 h-5 text-red-50" />
            </div>
          }
        />
      );
    default:
      return (
        <CsrStandardTopicStyledTag
          csrStandardTopic={csrStandardTopic}
          language={language}
          textCssClass="text-blue-900"
          backgroundCssClass="bg-gray-50"
        />
      );
  }
}

function CsrStandardTopicStyledTag({
  csrStandardTopic,
  language,
  textCssClass,
  backgroundCssClass,
  icon,
}: {
  csrStandardTopic: CsrStandardTopicTag_CsrStandardTopicFragment;
  language?: SurveyLanguage;
  textCssClass: string;
  backgroundCssClass: string;
  icon?: React.ReactNode;
}) {
  const { i18n } = useTranslation();

  const getTranslatedName = () => {
    // If forced language, use it. Otherwise, use the app language
    if (language) {
      return csrStandardTopic.i18n?.find((i) => i.language === language)?.name;
    } else {
      return csrStandardTopic.i18n?.find((i) => i.language === i18n.language)
        ?.name;
    }
  };

  const name = getTranslatedName();

  return (
    <div
      className={clsx('tag font-semibold', textCssClass, backgroundCssClass)}
    >
      {icon}
      <div
        className="xl:max-w-[20rem] max-w-[10rem] truncate text-ellipsis"
        title={name || ''}
      >
        {name}
      </div>
    </div>
  );
}
