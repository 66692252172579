import {
  CompanyType,
  CurrentUserDocument,
  ProjectContextualMenu_CompanyFragment,
  ProjectContextualMenuDocument,
  useProjectContextualMenu_ChooseTemplateMutation,
  useProjectContextualMenu_CreateCompanyMutation,
  useProjectContextualMenu_DeleteCompanyMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { useModal } from '../../layout/Modal';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { NavLink } from 'react-router-dom';
import { useProContext } from '../../../providers/ProContextProvider';
import { isSuperadminUser } from '../../../services/CompanyService';
import { CopyIcon, DeleteIcon, EyeIcon, TemplateIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { ProRoutes } from '../../../screens/ScreensRouter';
import { ContextualMenu } from '../../generic/ContextualMenu';

export function ProjectContextualMenu({
  company,
}: {
  company: ProjectContextualMenu_CompanyFragment;
}) {
  const proContext = useProContext();
  const { t } = useTranslation();
  const goToProjectHome = () => {
    if (proContext) {
      proContext.updateCompany(company.id, true);
    }
  };

  return (
    <ContextualMenu preventFromClosingAutomatically={true}>
      <NavLink
        to={ProRoutes.Project}
        onClick={goToProjectHome}
        className="w-full"
      >
        <button className="contextual-menu-item">
          <EyeIcon />
          <div>{t('global:open')}</div>
        </button>
      </NavLink>
      <CreateModelCompanyButton company={company} callback={close} />
      <DuplicateCompanyButton company={company} callback={close} />
      <DeleteCompanyButton company={company} callback={close} />
    </ContextualMenu>
  );
}

function CreateModelCompanyButton({
  company,
  callback,
}: {
  company: ProjectContextualMenu_CompanyFragment;
  callback: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const currentUser = useCurrentUser();

  const [isDuplicating, setIsDuplicating] = useState(false);

  const [createCompanyMutation] =
    useProjectContextualMenu_CreateCompanyMutation();
  const [chooseTemplateMutation] =
    useProjectContextualMenu_ChooseTemplateMutation();

  const duplicate = () => {
    setIsDuplicating(true);
    createCompanyMutation({
      variables: {
        input: {
          company: {
            name: `Copie de ${company.name}`,
            createdBy: {
              id: currentUser?.id || '',
            },
            type: CompanyType.Template,
          },
          coach: {
            id: currentUser?.id || '',
          },
        },
      },
    })
      .then((copy) => {
        if (copy?.data?.companyCreate) {
          chooseTemplateMutation({
            variables: {
              input: {
                companyId: copy.data.companyCreate.id,
                templateId: company.id,
              },
            },
            refetchQueries: () => [{ query: CurrentUserDocument }],
          })
            .then(() => {
              toast.openToastWithMessage(
                t('project.contextualMenu.createModelCompany.success'),
              );
              setIsDuplicating(false);
              callback();
            })
            .catch((err) => {
              toast.openToastWithError(err.message);
            });
        }
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDuplicating}
      onClick={duplicate}
    >
      {isDuplicating ? <Loader /> : <TemplateIcon />}
      {isDuplicating ? (
        <div>{t('project.contextualMenu.createModelCompany.duplicating')}</div>
      ) : (
        <div>{t('project.contextualMenu.createModelCompany.button')}</div>
      )}
    </button>
  );
}

function DuplicateCompanyButton({
  company,
  callback,
}: {
  company: ProjectContextualMenu_CompanyFragment;
  callback: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const currentUser = useCurrentUser();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [importUserData, setImportUserData] = useState(false);

  const [createCompanyMutation] =
    useProjectContextualMenu_CreateCompanyMutation({
      refetchQueries: () => [
        { query: CurrentUserDocument },
        {
          query: ProjectContextualMenuDocument,
          variables: {
            companyId: currentUser?.company?.id || '',
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  const [chooseTemplateMutation] =
    useProjectContextualMenu_ChooseTemplateMutation();

  const duplicate = () => {
    setIsDuplicating(true);
    toast.openToastWithMessage(
      t('project.contextualMenu.duplicate.duplicating'),
    );
    createCompanyMutation({
      variables: {
        input: {
          company: {
            name: `Copie de ${company.name}`,
            createdBy: {
              id: currentUser?.id || '',
            },
            type: company.type,
          },
          coach: {
            id: currentUser?.id || '',
          },
        },
      },
    })
      .then((copy) => {
        if (copy?.data?.companyCreate) {
          chooseTemplateMutation({
            variables: {
              input: {
                companyId: copy.data.companyCreate.id,
                templateId: company.id,
                importUserData,
              },
            },
            refetchQueries: () => [{ query: CurrentUserDocument }],
          })
            .then(() => {
              toast.openToastWithMessage(t('toast:project.duplicate.success'));
              setIsDuplicating(false);
              callback();
            })
            .catch((err) => {
              toast.openToastWithError(err.message);
            });
        }
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <div className="flex items-center gap-1 w-full">
      <button
        className={clsx(
          'contextual-menu-item',
          isSuperadminUser(currentUser) && ' w-auto',
        )}
        disabled={isDuplicating}
        onClick={duplicate}
      >
        {isDuplicating ? <Loader /> : <CopyIcon />}
        {isDuplicating ? (
          <div>{t('project.contextualMenu.duplicate.duplicating')}</div>
        ) : (
          <div>{t('project.contextualMenu.duplicate.button')}</div>
        )}
      </button>
      {!isDuplicating && isSuperadminUser(currentUser) && (
        <div className="flex items-center gap-1">
          <div className="text-xs italic shrink-0 text-gray-500">
            {t('project.contextualMenu.duplicate.withData')}
          </div>
          <input
            type="checkbox"
            className="form-input-checkbox"
            checked={importUserData}
            onChange={() => setImportUserData(!importUserData)}
          />
        </div>
      )}
    </div>
  );
}

function DeleteCompanyButton({
  company,
  callback,
}: {
  company: ProjectContextualMenu_CompanyFragment;
  callback: () => void;
}) {
  const toast = useToast();
  const modal = useModal();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  const [deleteCompanyMutation] =
    useProjectContextualMenu_DeleteCompanyMutation({
      refetchQueries: () => [
        { query: CurrentUserDocument },
        {
          query: ProjectContextualMenuDocument,
          variables: {
            companyId: currentUser?.company?.id || '',
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteCompanyMutation({
      variables: {
        companyId: company.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:project.delete.success'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
        callback();
      });
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.contextualMenu.deleteProject.confirmMessage', {
              companyName: company.name,
            })}
            callbackIfConfirmed={deleteCompany}
          />,
          t('project.contextualMenu.deleteProject.confirmTitle', {
            companyName: company.name,
          }),
        )
      }
    >
      {isDeleting ? <Loader /> : <DeleteIcon />}
      <div>{t('global:delete')}</div>
    </button>
  );
}
