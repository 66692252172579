import React from 'react';
import {
  ProMaturityEvaluationsListScreen_MaturityEvaluationFragment,
  SurveyStatus,
  useMaturityEvaluationWithRecipientsQuery,
} from '../../../graphql/generated';
import { NavLink } from 'react-router-dom';
import stakesIllustration from '../../../assets/images/illustrations/stakes/stakes.png';
import { Loader } from '../../generic/Loader';
import { getFrDateFromISOString } from '../../../services/DateService';
import { OpenMaturityEvaluationTemplateModalButton } from './OpenMaturityEvaluationTemplateModalButton';
import {
  StepIndicatorItem,
  StepsIndicator,
} from '../../generic/StepsIndicator';
import { useProContext } from '../../../providers/ProContextProvider';
import { TooltipWrapper } from '../../generic/TooltipWrapper';
import { useModal } from '../../layout/Modal';
import { ShareSurveyModal } from '../../survey/ShareSurveyModal';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { MaturityEvaluationContextualMenu } from './MaturityEvaluationContextualMenu';
import { MaturityEvaluationRespondModal } from './MaturityEvaluationRespondModal/MaturityEvaluationRespondModal';
import { UserIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { InnerHtml } from '../../generic/InnerHtml';

export function ProEvaluationsList({
  maturityEvaluations,
}: {
  maturityEvaluations: ProMaturityEvaluationsListScreen_MaturityEvaluationFragment[];
}) {
  const proContext = useProContext();
  const modal = useModal();
  const { t } = useTranslation();

  const hasSurveys = maturityEvaluations.length > 0;
  const hasPublishedSurveys = maturityEvaluations.some(
    (maturityEvaluation) =>
      maturityEvaluation.status &&
      [SurveyStatus.Ready, SurveyStatus.Sent, SurveyStatus.Closed].includes(
        maturityEvaluation.status,
      ),
  );
  const hasAnswers = maturityEvaluations.some(
    (maturityEvaluation) => (maturityEvaluation.responses?.length ?? 0) > 0,
  );

  return (
    <div className="pb-16 space-y-8">
      <div className="bg-green-50 rounded-xl p-8 space-y-4">
        <div className="flex items-center justify-between gap-8">
          <div className="space-y-4">
            <h2>
              {t('maturity.evaluation.messageBox.title', {
                companyName: proContext?.company?.name || "l'entreprise",
              })}
            </h2>
            <p className="max-w-xl">
              {t('maturity.evaluation.messageBox.description')}
            </p>
          </div>

          <img className=" w-36" src={stakesIllustration} alt="" />
        </div>
        <StepsIndicator
          steps={getMaturityEvaluationStepsItems(
            hasSurveys,
            hasPublishedSurveys,
            hasAnswers,
          )}
        />
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between gap-8">
          <h6>{t('maturity.evaluation.title')}</h6>
          {hasAnswers && (
            <NavLink to={`/pro/publications/maturity`}>
              <button className="primary purple small">
                {t('maturity.evaluation.maturityScore.button')}
              </button>
            </NavLink>
          )}
        </div>

        <table className="">
          <thead>
            <tr>
              <th className="text-left">
                {t('maturity.evaluation.table.name')}
              </th>
              <th className="text-center">
                {t('maturity.evaluation.table.status')}
              </th>
              <th className="text-center">
                {t('maturity.evaluation.table.progress')}
              </th>
              <th className="text-center">
                {t('maturity.evaluation.table.lastUpdatedAt')}
              </th>
              <th className=""></th>
            </tr>
          </thead>
          {maturityEvaluations?.length > 0 ? (
            <tbody>
              {maturityEvaluations.map((maturityEvaluation) => {
                return (
                  <MaturityEvaluationRow
                    maturityEvaluation={maturityEvaluation}
                    key={maturityEvaluation.id}
                  />
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <div className="flex flex-col items-center justify-center gap-4 text-gray-500 p-8">
                    <p>{t('maturity.evaluation.noMaturityEvaluation.title')}</p>
                    <div className="flex items-center gap-2">
                      <OpenMaturityEvaluationTemplateModalButton />
                      <NavLink
                        to={`/pro/maturity/import`}
                        onClick={() => modal.closeModal()}
                      >
                        <button className="secondary purple">
                          {t('maturity.evaluation.importExcel')}
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

function getMaturityEvaluationStepsItems(
  hasSurveys: boolean,
  hasPublishedSurveys: boolean,
  hasAnswers: boolean,
) {
  const step1Status = hasSurveys ? 'complete' : 'current';
  const step2Status = hasSurveys
    ? hasPublishedSurveys
      ? 'complete'
      : 'current'
    : 'upcoming';
  const step3Status = hasAnswers
    ? 'complete'
    : step2Status === 'complete'
      ? 'current'
      : 'upcoming';
  const step4Status = step3Status === 'complete' ? 'current' : 'upcoming';
  const { t } = useTranslation();

  const maturityEvaluationSteps: StepIndicatorItem[] = [
    {
      id: 1,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step1')} />
      ),
      status: step1Status,
    },
    {
      id: 2,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step2')} />
      ),
      status: step2Status,
    },
    {
      id: 3,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step3')} />
      ),
      status: step3Status,
    },
    {
      id: 4,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step4')} />
      ),
      status: step4Status,
    },
  ];

  return maturityEvaluationSteps;
}

function MaturityEvaluationRow({
  maturityEvaluation,
}: {
  maturityEvaluation: ProMaturityEvaluationsListScreen_MaturityEvaluationFragment;
}) {
  const modal = useModal();
  const currentUser = useCurrentUser();

  // Fetch Recipients data
  const surveyWithRecipientsQuery = useMaturityEvaluationWithRecipientsQuery({
    variables: { id: maturityEvaluation.id },
    fetchPolicy: 'network-only',
  });

  const latestResponse =
    surveyWithRecipientsQuery.data?.surveyLastResponse || null;

  const amIRecipient =
    surveyWithRecipientsQuery.data?.maturityEvaluation?.maturityEvaluationRecipients?.some(
      (recipient) => recipient.email === currentUser?.auth?.email,
    );

  const openShareModal = () => {
    modal.openModalWithComponent(
      <ShareSurveyModal survey={maturityEvaluation} />,
      null,
      false,
      false,
    );
  };

  const openAnswerModal = () => {
    modal.openModalWithComponent(
      <MaturityEvaluationRespondModal
        maturityEvaluation={maturityEvaluation}
      />,
      null,
      true,
      false,
      'w-3/4',
    );
  };
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        <div className="font-title font-extrabold">
          {maturityEvaluation.name}
        </div>
      </td>
      <td>
        <div className="flex justify-center">
          <MaturityEvaluationStatusTag
            maturityEvaluation={maturityEvaluation}
            completionRate={latestResponse?.completionRate}
          />
        </div>
      </td>
      <td className="text-center">
        {surveyWithRecipientsQuery.loading ? (
          <Loader />
        ) : (
          latestResponse?.completionRate && (
            <div className="font-bold">
              {Math.round(latestResponse?.completionRate * 100)}%
            </div>
          )
        )}
      </td>
      <td>
        {latestResponse?.submittedAt && (
          <div className="text-gray-500">
            {getFrDateFromISOString(latestResponse.submittedAt)}
          </div>
        )}
      </td>
      <td>
        <div className="flex items-center justify-end gap-2">
          <MaturityEvaluationContextualMenu survey={maturityEvaluation} />
          {maturityEvaluation.status === SurveyStatus.Created && (
            <NavLink to={`/pro/maturity/${maturityEvaluation.id}/assign`}>
              <TooltipWrapper
                place={'left'}
                label={t(
                  'maturity.evaluation.answerModal.assign.button.tooltip',
                )}
              >
                <button className="primary purple small shrink-0">
                  <UserIcon />
                  <div>
                    {t('maturity.evaluation.answerModal.assign.button.label')}
                  </div>
                </button>
              </TooltipWrapper>
            </NavLink>
          )}
          {maturityEvaluation.status === SurveyStatus.Ready && amIRecipient && (
            <button
              className="primary purple small"
              onClick={() => openAnswerModal()}
            >
              {t('maturity.evaluation.answerModal.respond.button.label')}
            </button>
          )}
          {maturityEvaluation.status === SurveyStatus.Ready &&
            !amIRecipient && (
              <button
                className="secondary purple small"
                onClick={() => openAnswerModal()}
              >
                {t('maturity.evaluation.answerModal.respond.button.label')}
              </button>
            )}
          {maturityEvaluation.status === SurveyStatus.Ready &&
            !amIRecipient && (
              <button
                className="primary purple small"
                onClick={() => openShareModal()}
              >
                {t('maturity.evaluation.answerModal.share.button.label')}
              </button>
            )}
        </div>
      </td>
    </tr>
  );
}

function MaturityEvaluationStatusTag({
  maturityEvaluation,
  completionRate,
}: {
  maturityEvaluation: ProMaturityEvaluationsListScreen_MaturityEvaluationFragment;
  completionRate: number | null | undefined;
}) {
  const { t } = useTranslation();
  if (maturityEvaluation.isTemplate) {
    return (
      <div className="tag purple">
        {t('maturity.evaluation.surveyStatus.tag.template')}
      </div>
    );
  }

  if (completionRate && completionRate >= 1) {
    return (
      <div className="tag green">
        {t('maturity.evaluation.surveyStatus.tag.completed')}
      </div>
    );
  }

  if (completionRate && completionRate >= 0) {
    return (
      <div className="tag blue">
        {t('maturity.evaluation.surveyStatus.tag.started')}
      </div>
    );
  }

  switch (maturityEvaluation.status) {
    case SurveyStatus.Created:
      return (
        <div className="tag gray">
          {t('maturity.evaluation.surveyStatus.tag.unassigned')}
        </div>
      );
    case SurveyStatus.Ready:
    case SurveyStatus.Sent:
      return (
        <div className="tag green">
          {t('maturity.evaluation.surveyStatus.tag.published')}
        </div>
      );
    case SurveyStatus.Closed:
      return (
        <div className="tag green">
          {t('maturity.evaluation.surveyStatus.tag.closed')}
        </div>
      );
    default:
      return null;
  }
}
