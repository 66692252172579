import React from 'react';
import { CoachLeftNavInner } from './LeftNav';
import { useLeftDrawer } from '../layout/LeftDrawer';
import { useProContext } from '../../providers/ProContextProvider';
import { BurgerIcon } from '../icons';

export function OpenLeftDrawerMenuButton() {
  const leftDrawer = useLeftDrawer();
  const proContext = useProContext();
  const openLeftMenu = () => {
    leftDrawer.openDrawerWithComponent(
      <div className="p-4 h-full">
        <CoachLeftNavInner />
      </div>,
    );
  };
  if (!proContext?.isNavCollapsed) {
    return null;
  }
  return (
    <button className="unstyled" onClick={openLeftMenu}>
      <BurgerIcon className="w-3 h-3" />
    </button>
  );
}
