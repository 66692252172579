import React from 'react';
import {
  ActionFieldsFragment,
  ActionPriorityLevel,
  CatalystFieldsFragment,
  RoadmapFieldsFragment,
} from '../../../graphql/generated';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  colorFromPriorityLevel,
  PriorityBadge,
  titleFromPriorityLevel,
} from '../../badges/PriorityBadge';
import { CatalystTitle } from './CatalystTitle';
import { sortByNullablePosition } from '../../../services/UtilsService';
import { ActionIllustrationIcon } from './ActionIllustrationIcon';
import { getActionLink } from '../../../screens/ScreensRouter';
import { useProContext } from '../../../providers/ProContextProvider';

export function RoadmapPriorityView({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  return (
    <div>
      {roadmap.catalysts?.length > 0 && (
        <div className="flex flex-col divide-y divide-gray-100 space-y-12">
          {roadmap.catalysts
            .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
            .map((catalyst, index) => (
              <div key={catalyst.id} className="pt-6">
                <Catalyst catalyst={catalyst} index={index} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

function Catalyst({
  catalyst,
  index,
}: {
  catalyst: CatalystFieldsFragment;
  index: number;
}) {
  return (
    <div className="flex flex-col">
      <CatalystTitle catalyst={catalyst} index={index} />
      {catalyst.actions?.length > 0 && (
        <div className="flex flex-col gap-4">
          <div className={clsx('flex gap-4 mt-2')}>
            <PriorityColumn
              catalyst={catalyst}
              priorityLevel={ActionPriorityLevel.Urgent}
            />
            <PriorityColumn
              catalyst={catalyst}
              priorityLevel={ActionPriorityLevel.Priority}
            />
            <PriorityColumn
              catalyst={catalyst}
              priorityLevel={ActionPriorityLevel.Important}
            />
            <PriorityColumn
              catalyst={catalyst}
              priorityLevel={ActionPriorityLevel.Unimportant}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function PriorityColumn({
  catalyst,
  priorityLevel,
}: {
  catalyst: CatalystFieldsFragment;
  priorityLevel: ActionPriorityLevel;
}) {
  const actions = catalyst.actions?.filter(
    (action) => action.priorityLevel === priorityLevel,
  );
  return (
    <div
      className={clsx(
        'flex flex-col gap-2 w-1/4 p-4 rounded-xl',
        colorFromPriorityLevel(priorityLevel).bgColor,
      )}
    >
      <PriorityHeader priorityLevel={priorityLevel} />
      {actions.length > 0 &&
        actions
          .toSorted((a, b) => a.title.localeCompare(b.title))
          .map((action) => <ActionCard key={action.id} action={action} />)}
    </div>
  );
}

function PriorityHeader({
  priorityLevel,
}: {
  priorityLevel: ActionPriorityLevel;
}) {
  return (
    <div className="flex items-center justify-center gap-2 rounded-lg p-2 bg-white">
      <PriorityBadge priorityLevel={priorityLevel} />
      <div className="text-gray-500 text-sm font-semibold">
        {titleFromPriorityLevel(priorityLevel)}
      </div>
    </div>
  );
}

function ActionCard({ action }: { action: ActionFieldsFragment }) {
  const proContext = useProContext();

  return (
    <div className="flex flex-col items-center gap-4 w-full hover:border-gray-900 cursor-pointer rounded-lg bg-white border-2 border-white">
      <NavLink
        to={getActionLink(
          proContext?.company !== undefined && proContext?.company !== null,
          action.id,
        )}
        className="w-full"
      >
        <div className="flex flex-col items-center w-full grow">
          <div className="flex items-center gap-2 w-full grow p-1">
            <ActionIllustrationIcon action={action} />
            <div className="text-sm font-semibold">{action.title}</div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}
