import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import logo from '../../assets/images/logo.svg';
import React, { ReactNode } from 'react';
import { UserProfileButton } from '../user/UserProfileButton';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { useProContext } from '../../providers/ProContextProvider';
import { CoachIndicator } from '../user/CoachIndicator';
import { ProjectSelect } from '../pro/ProjectSelect';
import { isCoachUser, isSuperadminUser } from '../../services/CompanyService';
import { useLeftDrawer } from '../layout/LeftDrawer';
import { CompanyLogo } from '../pro/project/CompanyLogo';
import { CompanyType } from '../../graphql/generated';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { ProCompanyMaxProjectsCountProgressBar } from '../pro/project/ProCompanyMaxProjectsCountProgressBar';
import { CreateNoteButton } from '../pro/notebook/CreateNoteButton';
import { useTranslation } from '@hooks/useTranslation';
import {
  CalendarIcon,
  ChevronRightIcon,
  HomeIcon,
  MaterialityIcon,
  MaturityIcon,
  PriorityUpIcon,
  RoadmapIcon,
  StakesIcon,
  SurveyIcon,
  TSvgIcon,
  UserIcon,
} from '../icons';
import { RequiredBadge } from '../badges/RequiredBadge';
import { ProRoutes } from '../../screens/ScreensRouter';

export function LeftNav() {
  const currentUser = useCurrentUser();
  if (!currentUser) return null;
  if (isSuperadminUser(currentUser)) return <CoachLeftNav />;
  if (isCoachUser(currentUser)) return <CoachLeftNav />;
  return <UserLeftNav />;
}

function UserLeftNav() {
  const currentUser = useCurrentUser();
  const companyName = currentUser?.company
    ? currentUser.company.name
    : 'Good Steps';

  return (
    <div className="z-30 flex w-[280px] flex-col fixed inset-y-0 px-4 py-6 border-r border-gray-200 bg-white text-sm">
      <div className="flex-1 flex flex-col overflow-y-auto gap-5">
        <div className="flex items-center pl-1 gap-3">
          <NavLink to={'/'}>
            {currentUser?.company ? (
              <CompanyLogo companyId={currentUser?.company.id} size={'small'} />
            ) : (
              <img className="h-7 w-7" src={logo} alt="" />
            )}
          </NavLink>
          <span className="grow font-bold">{companyName}</span>
        </div>
        <nav
          className="flex flex-col text-gray-500 gap-2 mt-2"
          aria-label="Sidebar"
        >
          <UserNavItems />
        </nav>
      </div>
      <div className="divide-y divide-gray-100">
        <CoachIndicator />
        <UserProfileButton />
      </div>
    </div>
  );
}

function CoachLeftNav() {
  const proContext = useProContext();
  return (
    <div
      className={clsx(
        'z-30 w-[230px] xl:w-[280px] fixed inset-y-0 px-4 py-6 transition-transform duration-300 ease-in-out bg-white text-gray-900 border-r border-gray-200',
        proContext?.isNavCollapsed ? '-translate-x-full' : '',
      )}
    >
      <CoachLeftNavInner />
    </div>
  );
}

export function CoachLeftNavInner() {
  const currentUser = useCurrentUser();
  const proContext = useProContext();
  const leftDrawer = useLeftDrawer();
  const companyName = currentUser?.company
    ? currentUser.company.name
    : 'Good Steps';
  return (
    <div className="text-sm flex flex-col h-full">
      <div className="flex-1 flex flex-col overflow-y-auto gap-5">
        <div className="flex items-center pl-1 gap-3">
          <div className="flex items-center grow bg-gray-50 px-4 py-3 rounded-xl">
            <div className="grow">
              <NavLink to={'/pro'}>
                <div className="flex items-center gap-3">
                  {currentUser?.company ? (
                    <CompanyLogo
                      companyId={currentUser?.company.id}
                      size={'small'}
                    />
                  ) : (
                    <img className="h-7 w-7" src={logo} alt="" />
                  )}
                  <span className="grow font-bold">{companyName}</span>
                </div>
              </NavLink>
            </div>
            <div className="py-1 px-2 text-xs rounded-md shrink-0 flex gap-1 bg-purple-500 text-white font-bold">
              Pro
            </div>
          </div>
          <button
            className="unstyled"
            onClick={() => {
              if (proContext?.isNavCollapsed) {
                leftDrawer.closeDrawer();
                proContext?.setIsNavCollapsed(false);
              } else {
                proContext?.setIsNavCollapsed(true);
              }
            }}
          >
            {proContext?.isNavCollapsed ? (
              <>
                <ChevronRightIcon />
                <ChevronRightIcon className="-ml-4" />
              </>
            ) : (
              <>
                <ChevronRightIcon className="text-gray-500 rotate-180" />
                <ChevronRightIcon className="text-gray-500 rotate-180 -ml-4" />
              </>
            )}
          </button>
        </div>
        <nav
          className="flex flex-col gap-2 mt-2 text-gray-500"
          aria-label="Sidebar"
        >
          {proContext?.company?.type === CompanyType.Template ? (
            <TemplateNavItems />
          ) : (
            <CoachNavItems />
          )}
        </nav>
      </div>
      <div className="space-y-2">
        <UserProfileButton />
        {currentUser?.company && (
          <div className="border border-gray-100 px-4 py-3 shadow-sm rounded-md">
            <ProCompanyMaxProjectsCountProgressBar
              companyId={currentUser.company.id}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function CoachNavItems() {
  const proContext = useProContext();
  const hasReferential = proContext?.company?.referential !== null;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <ProjectSelect />

      {proContext?.company && (
        <div className="mt-4 space-y-0.5">
          <NavItem
            name={t('nav:page.project_management')}
            Icon={CalendarIcon}
            href={ProRoutes.Project}
          />
          <NavItem
            name={t('nav:page.referential')}
            Icon={StakesIcon}
            href={ProRoutes.Stakes}
            badge={!hasReferential && <RequiredBadge />}
          />
          <NavItem
            name={t('nav:page.stakeholder_mapping')}
            Icon={UserIcon}
            href={ProRoutes.StakeholderMapping}
          />
          <NavItem
            name={t('nav:page.iro_rating')}
            Icon={StakesIcon}
            href={ProRoutes.Iro}
          />
          <NavSection label={t('nav:leftNav.section.data_collect')}>
            <NavItem
              name={t('nav:page.maturity_evaluation')}
              Icon={MaturityIcon}
              href={ProRoutes.MaturityEvaluations}
            />
            <NavItem
              name={t('nav:page.surveys')}
              Icon={SurveyIcon}
              href={ProRoutes.Survey}
            />
          </NavSection>
          <NavSection label={t('nav:leftNav.section.publications')}>
            <NavItem
              name={t('nav:page.maturity_score')}
              Icon={MaturityIcon}
              href={ProRoutes.PublicationMaturity}
            />
            <NavItem
              name={t('nav:page.materiality_matrix')}
              Icon={MaterialityIcon}
              href={ProRoutes.PublicationMaterialityMatrix}
            />
            <NavItem
              name={t('nav:page.double_materiality_matrix')}
              Icon={MaterialityIcon}
              href={ProRoutes.PublicationDoubleMaterialityMatrix}
            />
            <NavItem
              name={t('nav:page.prioritization_matrix')}
              Icon={PriorityUpIcon}
              href={ProRoutes.PublicationPrioritizationMatrix}
            />
            <NavItem
              name={t('nav:page.roadmap')}
              Icon={RoadmapIcon}
              href={ProRoutes.Roadmap}
            />
          </NavSection>
          {proContext?.company?.notebook && (
            <div className="py-4">
              <CreateNoteButton
                notebook={proContext.company.notebook}
                className="w-full secondary purple small"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function TemplateNavItems() {
  const proContext = useProContext();
  const hasReferential = proContext?.company?.referential !== null;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {proContext?.company && (
        <>
          <MessageBox type={MessageBoxType.Warning}>
            <div>
              <div className="text-xs">
                {t('nav:leftNav.you_are_editing_the_template')}
              </div>
              <div className="font-bold text-yellow-900">
                {proContext?.company?.name}
              </div>
            </div>
          </MessageBox>

          <div className="mt-4">
            <NavItem
              name={t('nav:leftNav.template_management')}
              Icon={CalendarIcon}
              href="/pro/project"
            />
            <NavItem
              name={t('nav:page.referential')}
              Icon={StakesIcon}
              href="/pro/stakes"
              badge={!hasReferential && <RequiredBadge />}
            />
            <NavItem
              name={t('nav:page.maturity_evaluation')}
              Icon={MaturityIcon}
              href="/pro/maturity"
            />
            <NavItem
              name={t('nav:page.surveys')}
              Icon={SurveyIcon}
              href="/pro/survey"
            />
            <NavItem
              name={t('nav:page.stakeholder_mapping')}
              Icon={UserIcon}
              href={ProRoutes.StakeholderMapping}
            />
          </div>
        </>
      )}
    </div>
  );
}

function UserNavItems() {
  const { t } = useTranslation();
  return (
    <>
      <NavItem name={t('nav:page.home')} Icon={HomeIcon} href="/" end={true} />
      <NavItem
        name={t('nav:page.referential')}
        Icon={StakesIcon}
        href="/diag/stakes"
      />
      <NavItem name="Consultations" Icon={SurveyIcon} href="/diag/survey" />
      <NavItem
        name={t('nav:page.maturity_score')}
        Icon={MaturityIcon}
        href="/diag/publications/maturity"
      />
      <NavItem
        name={t('nav:page.materiality_matrix')}
        Icon={MaterialityIcon}
        href="/diag/publications/materiality-matrix"
      />
      <NavItem
        name={t('nav:page.prioritization_matrix')}
        Icon={PriorityUpIcon}
        href="/diag/publications/prioritization-matrix"
      />
      <NavItem
        name={t('nav:page.roadmap')}
        Icon={RoadmapIcon}
        href="/diag/roadmap"
      />
    </>
  );
}

function NavSection({
  label,
  children,
}: {
  label?: string;
  children: ReactNode;
}) {
  return (
    <div className="pt-2">
      {label && <div className="uppercase text-xs mt-2 px-3 py-2">{label}</div>}
      <div className="flex flex-col space-y-0.5">{children}</div>
    </div>
  );
}

function NavItem({
  name,
  Icon,
  href,
  children,
  end,
  badge,
}: {
  name: string;
  Icon?: TSvgIcon;
  href: string;
  children?: ReactNode;
  end?: boolean;
  badge?: ReactNode;
}) {
  return (
    <div>
      <NavLink to={href} end={end}>
        {({ isActive }) => {
          return (
            <>
              <div
                className={clsx(
                  isActive &&
                    href !== '#' &&
                    'font-semibold text-gray-900 bg-gray-50',
                  'text-gray-500 relative group w-full flex items-center px-3 py-2 text-left rounded-md tertiary hover:bg-gray-50',
                )}
              >
                <div className="h-3.5 w-3.5 mr-2">
                  {Icon && (
                    <Icon className="h-full w-full" aria-hidden="true" />
                  )}
                </div>
                <div className="flex-1 flex items-center gap-1">
                  <span>{name}</span>
                  {badge}
                </div>
              </div>
              {isActive && <div className="ml-6">{children}</div>}
            </>
          );
        }}
      </NavLink>
    </div>
  );
}
