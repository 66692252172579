import {
  MaterialityTypes,
  SurveyQuestionUpdateInput,
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { ToggleRequired } from './atoms/ToggleRequired';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { HelpTooltip } from '../../../../generic/HelpTooltip';
import StringDropdown, {
  StringDropdownItem,
} from '../../../../generic/form/StringDropdown';
import { Toggle } from '../../../../generic/form/Toggle';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { QuestionPillarStake } from './atoms/QuestionPillarStake';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';

export function QuestionStakesDoubleMaterialityForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  const [canAddOtherChoice, setCanAddOtherChoice] = useState(
    questionDraft.canAddOtherChoice,
  );

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateQuestion = (input: SurveyQuestionUpdateInput) => {
    updateQuestionMutation({
      variables: {
        input: input,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const updateMaterialityType = (materialityType: StringDropdownItem) => {
    const materialityTypeEnum = Object.values(MaterialityTypes).find(
      (type) => type === materialityType.id,
    );
    if (materialityTypeEnum) {
      setQuestionDraft({
        ...questionDraft,
        stakeOptions: {
          ...questionDraft.stakeOptions,
          materialityType: materialityTypeEnum,
        },
      });
      updateQuestion({
        id: questionDraft.id,
        stakeOptions: {
          ...questionDraft.stakeOptions,
          materialityType: materialityTypeEnum,
        },
      });
    }
  };

  const toggleCanAddOtherChoice = (newState: boolean): void => {
    setCanAddOtherChoice(newState);
    updateQuestion({
      id: questionDraft.id,
      canAddOtherChoice: newState,
    });
  };

  const materialityItems: StringDropdownItem[] = [
    {
      id: MaterialityTypes.Financial,
      label: `Matérialité financière`,
    },
    {
      id: MaterialityTypes.Impact,
      label: `Matérialité d'impact`,
    },
  ];
  const materialityTypeItem =
    materialityItems.find(
      (item) => item.id === questionDraft?.stakeOptions?.materialityType,
    ) || null;

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>
      <div className="pt-8 space-y-4">
        <QuestionPillarStake
          question={questionDraft}
          enableStakePicker={false}
          enablePillarPicker={true}
        />
        {question.pillar === null && (
          <MessageBox type={MessageBoxType.Error}>
            Cette question doit être reliée à un pilier afin de permettre
            l&apos;`affichage des enjeux associés
          </MessageBox>
        )}
        <div className="w-1/2">
          <label className="form-input-label mb-2 flex items-center gap-1">
            <div>Type de matérialité</div>
            <HelpTooltip>
              <div className="font-normal space-y-1">
                <p>
                  Vous avez le choix entre{' '}
                  <strong>2 types de matérialité</strong> :{' '}
                </p>
                <ul className="list-disc ml-4 space-y-1">
                  <li>
                    <strong>Matérialité financière</strong> (ou matérialité
                    simple) : l’impact de facteurs ESG sur la performance
                    financière d’une organisation
                  </li>
                  <li>
                    <strong>Matérialité d&apos;impact</strong> : l’impact des
                    activités de l’organisation sur l’environnement, la société
                    et la gouvernance.
                  </li>
                </ul>
              </div>
            </HelpTooltip>
          </label>
          <div className="flex flex-col gap-2">
            <StringDropdown
              availableItems={materialityItems}
              item={materialityTypeItem}
              setItem={(item) => {
                updateMaterialityType(item || null);
              }}
            />
          </div>
        </div>
      </div>
      <div className="pt-8 space-y-2">
        <h3>Contenu</h3>
        <div>
          <label htmlFor="title" className="form-input-label mb-2">
            Intitulé de la question
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({
                ...questionDraft,
                title: e.target.value,
              });
            }}
            onBlur={() =>
              updateQuestion({
                id: questionDraft.id,
                title: questionDraft.title,
              })
            }
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>

        <div className="space-y-2 bg-gray-50 rounded-xl p-4 border border-gray-100">
          <div>
            <label htmlFor="impactLabel" className="form-input-label mb-2">
              Sévérité de l&apos;impact
            </label>
            <input
              type="text"
              name="impactLabel"
              id="impactLabel"
              className="form-input-text"
              value={
                questionDraft.stakeOptions?.materialityOptions?.impactLabel ||
                ''
              }
              onChange={(e) => {
                setQuestionDraft({
                  ...questionDraft,
                  stakeOptions: {
                    ...questionDraft.stakeOptions,
                    materialityOptions: {
                      ...questionDraft.stakeOptions?.materialityOptions,
                      impactLabel: e.target.value,
                    },
                  },
                });
              }}
              onBlur={() =>
                updateQuestion({
                  id: questionDraft.id,
                  stakeOptions: {
                    ...questionDraft.stakeOptions,
                    materialityOptions: {
                      ...questionDraft.stakeOptions?.materialityOptions,
                      impactLabel:
                        questionDraft.stakeOptions?.materialityOptions
                          ?.impactLabel,
                    },
                  },
                })
              }
            />
          </div>

          <div>
            <div className="space-y-2 ml-4">
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactNoneLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 1
                </label>
                <input
                  type="text"
                  name="impactNoneLabel"
                  id="impactNoneLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactNoneLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactNoneLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactNoneLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactNoneLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactLowLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 2
                </label>
                <input
                  type="text"
                  name="impactLowLabel"
                  id="impactLowLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactLowLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactLowLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactLowLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactLowLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactMediumLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 3
                </label>
                <input
                  type="text"
                  name="impactMediumLabel"
                  id="impactMediumLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactMediumLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactMediumLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactMediumLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactMediumLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactHighLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 4
                </label>
                <input
                  type="text"
                  name="impactHighLabel"
                  id="impactHighLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactHighLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactHighLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactHighLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactHighLabel,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-2 bg-gray-50 rounded-xl p-4 border border-gray-100">
          <div>
            <label htmlFor="probabilityLabel" className="form-input-label mb-2">
              Probabilité de l&apos;impact
            </label>
            <input
              type="text"
              name="probabilityLabel"
              id="probabilityLabel"
              className="form-input-text"
              value={
                questionDraft.stakeOptions?.materialityOptions
                  ?.probabilityLabel || ''
              }
              onChange={(e) => {
                setQuestionDraft({
                  ...questionDraft,
                  stakeOptions: {
                    ...questionDraft.stakeOptions,
                    materialityOptions: {
                      ...questionDraft.stakeOptions?.materialityOptions,
                      probabilityLabel: e.target.value,
                    },
                  },
                });
              }}
              onBlur={() =>
                updateQuestion({
                  id: questionDraft.id,
                  stakeOptions: {
                    ...questionDraft.stakeOptions,
                    materialityOptions: {
                      ...questionDraft.stakeOptions?.materialityOptions,
                      probabilityLabel:
                        questionDraft.stakeOptions?.materialityOptions
                          ?.probabilityLabel,
                    },
                  },
                })
              }
            />
          </div>

          <div>
            <div className="space-y-2 ml-4">
              <div className="flex items-center gap-4">
                <label
                  htmlFor="probabilityLowLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 1
                </label>
                <input
                  type="text"
                  name="probabilityLowLabel"
                  id="probabilityLowLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.probabilityLowLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityLowLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityLowLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.probabilityLowLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="probabilityMediumLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 2
                </label>
                <input
                  type="text"
                  name="probabilityMediumLabel"
                  id="probabilityMediumLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.probabilityMediumLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityMediumLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityMediumLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.probabilityMediumLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="probabilityHighLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 3
                </label>
                <input
                  type="text"
                  name="probabilityHighLabel"
                  id="probabilityHighLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.probabilityHighLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityHighLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityHighLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.probabilityHighLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="probabilityCertainLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  Niveau 4
                </label>
                <input
                  type="text"
                  name="probabilityCertainLabel"
                  id="probabilityCertainLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.probabilityCertainLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityCertainLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          probabilityCertainLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.probabilityCertainLabel,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 space-y-4">
        <h3>Options</h3>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-sm">
            <Toggle
              state={canAddOtherChoice}
              setState={toggleCanAddOtherChoice}
            />
            <label className="form-input-label">Commentaire libre</label>
          </div>
        </div>
      </div>
    </div>
  );
}
