import {
  ActionFieldsFragment,
  CatalystFieldsFragment,
  useActionMoveToCatalystMutation,
  useRoadmapQuery,
} from '../../../graphql/generated';
import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { useProContext } from '../../../providers/ProContextProvider';
import { getLatestCompanyRoadmapSummary } from '../../../services/CompanyService';
import { sortByNullablePosition } from '../../../services/UtilsService';
import { DeleteActionButton } from './DeleteActionButton';
import { MarkActionAsTemplateButton } from './MarkActionAsTemplateButton';
import { DuplicateActionButton } from './DuplicateActionButton';
import { ChevronRightIcon, MenuIcon, MoveCatalystIcon } from '../../icons';

export function EditActionMenu({ action }: { action: ActionFieldsFragment }) {
  const [displayMoveToCatalystsList, setDisplayMoveToCatalystsList] =
    useState(false);
  const proContext = useProContext();
  const roadmapSummary = getLatestCompanyRoadmapSummary(proContext?.company);
  const { data, loading } = useRoadmapQuery({
    variables: {
      id: roadmapSummary?.id || '',
    },
    skip: !roadmapSummary || !displayMoveToCatalystsList,
  });
  const catalysts = data?.roadmap.catalysts || [];

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            onClick={() => setDisplayMoveToCatalystsList(false)}
            className={clsx(
              'text-gray-900 hover:bg-gray-50 hover:text-gray-900 p-0 w-full flex group rounded-md inline-flex items-center text-base font-medium border-transparent hover:border-transparent',
            )}
          >
            <div className="flex items-center rounded-xl p-2 w-full gap-3">
              <MenuIcon className="flex-shrink-0 h-3.5 w-3.5 text-gray-500 rotate-90" />
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="z-50 absolute bg-white top-0 left-0 transform translate-y-6 -translate-x-1/3 mt-3 px-2 w-56">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                {displayMoveToCatalystsList ? (
                  loading ? (
                    <Loader />
                  ) : (
                    <div className="flex flex-col items-start p-1">
                      {catalysts
                        ?.toSorted((a, b) =>
                          sortByNullablePosition(a.position, b.position),
                        )
                        .map((catalyst) => (
                          <MoveActionToCatalystButton
                            action={action}
                            catalyst={catalyst}
                          />
                        ))}
                    </div>
                  )
                ) : (
                  <div className="flex flex-col items-start p-1">
                    <DuplicateActionButton action={action} />
                    <MarkActionAsTemplateButton action={action} />
                    <button
                      className="contextual-menu-item"
                      onClick={() => setDisplayMoveToCatalystsList(true)}
                    >
                      <MoveCatalystIcon className="text-gray-500" />
                      <span>Changer d'axe</span>
                      <ChevronRightIcon className="text-gray-300" />
                    </button>
                    <DeleteActionButton action={action} />
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function MoveActionToCatalystButton({
  action,
  catalyst,
}: {
  action: ActionFieldsFragment;
  catalyst: CatalystFieldsFragment;
}) {
  const toast = useToast();
  const [moveAction] = useActionMoveToCatalystMutation();
  const moveActionToCatalyst = () => {
    moveAction({
      variables: {
        input: {
          id: action.id,
          catalyst: {
            id: catalyst.id,
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage("L'action a bien été déplacée");
      })
      .catch((err) => console.error(err));
  };

  return (
    <button className="contextual-menu-item" onClick={moveActionToCatalyst}>
      <span className="shrink-0 overflow-hidden w-24 whitespace-nowrap grow">
        {catalyst.title}
      </span>
      <ChevronRightIcon className="text-gray-300 shrink-0" />
    </button>
  );
}
