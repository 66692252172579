import React, { FC, useEffect, useState } from 'react';
import GroupedStringDropdown, {
  GroupedStringDropdownItem,
} from '../../../generic/form/GroupedStringDropdown';
import { useTranslation } from '@hooks/useTranslation';
import { getGroupedLabels } from '../../../generic/form/GroupedStringDropdown.utils';

type StakeholderSegmentDropdownProps = {
  selectedSegmentIds?: string[];
  handleChange: (items: GroupedStringDropdownItem[]) => void;
  readonly?: boolean;
  multiple?: boolean;
  availableItems: GroupedStringDropdownItem[];
};

export const GroupedStakeholderSegmentDropdown: FC<
  StakeholderSegmentDropdownProps
> = ({
  selectedSegmentIds = [],
  handleChange,
  readonly,
  multiple = false,
  availableItems,
}) => {
  const [selectedItems, setSelectedItems] = useState<
    GroupedStringDropdownItem[]
  >([]);

  useEffect(() => {
    if (selectedSegmentIds && availableItems.length > 0) {
      const initialSelectedItems = availableItems.filter((item) =>
        selectedSegmentIds.includes(item.id),
      );
      setSelectedItems(initialSelectedItems);
    }
  }, [selectedSegmentIds, availableItems]);

  const handleItemChange = (items: GroupedStringDropdownItem[]) => {
    setSelectedItems(items);
    handleChange(items);
  };

  return (
    <GroupedStringDropdown
      availableItems={availableItems}
      readonly={readonly}
      items={selectedItems}
      setItem={handleItemChange}
      multiple={multiple}
      labelFn={(multiple: boolean, items: GroupedStringDropdownItem[]) => {
        const { t } = useTranslation();
        if (multiple) {
          if (items.length === 0) {
            return t('stakeholder.dropdown.choice.all');
          }
          if (items.length > 2) {
            return t('stakeholder.dropdown.choice.nbItemsSelected', {
              count: items.length,
            });
          }
          return getGroupedLabels(items);
        }
        return (
          (items[0] ? `${items[0].group.name} > ${items[0].label}` : null) ||
          '-'
        );
      }}
    />
  );
};
