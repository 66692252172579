import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import { CompanyFieldsFragment, useCompanyQuery } from '../graphql/generated';
import { useLocation, useNavigate } from 'react-router-dom'; // Importe useLocation
import { useToast } from '../components/layout/Toast';
import { LoaderFullscreen } from '../components/layout/Loader';
import clsx from 'clsx';
import { ProRoutes } from '../screens/ScreensRouter';

export type ProContextType = {
  company: CompanyFieldsFragment | null;
  updateCompany: (
    companyId: string,
    redirectToHomePageAfterUpdate: boolean,
  ) => void;
  refreshCompany: () => void;
  isNavCollapsed: boolean;
  setIsNavCollapsed: (isNavCollapsed: boolean) => void;
  isLoadingCompany: boolean;
};
const ProContext = createContext<ProContextType | null>(null);
export const useProContext = () => useContext(ProContext);

export function ProContextProvider({ children }: { children: ReactNode }) {
  const toast = useToast();
  const [company, setCompany] = useState<CompanyFieldsFragment | null>(null);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Reload previous company from cookie if it exists
  const ACTIVE_COMPANY_COOKIE_NAME = 'activeCompanyId';
  const [activeCompanyId, setActiveCompanyId] = useState<string | null>(
    Cookies.get(ACTIVE_COMPANY_COOKIE_NAME) || null,
  );
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { refetch } = useCompanyQuery({
    variables: {
      id: activeCompanyId || '',
    },
    skip: !activeCompanyId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      const excludedPaths = [ProRoutes.Home];
      if (excludedPaths.includes(location.pathname as ProRoutes)) {
        setIsLoadingCompany(false);
        return;
      }
      if (activeCompanyId) {
        setIsLoadingCompany(true);
        refetch({ id: activeCompanyId })
          .then((res) => {
            setCompany(res.data?.company || null);
          })
          .catch((err) => {
            // Company does not exist or user does not have access anymore
            console.error(err);
            setActiveCompanyId(null);
            Cookies.remove(ACTIVE_COMPANY_COOKIE_NAME);
          })
          .finally(() => setIsLoadingCompany(false));
      } else {
        setIsLoadingCompany(false);
      }
    }
  }, [activeCompanyId, refetch, location.pathname, isFirstRender]);

  const updateCompany = useCallback(
    (companyId: string, redirectToHomePageAfterUpdate: boolean) => {
      setIsLoadingCompany(true);
      refetch({ id: companyId })
        .then((res) => {
          setCompany(res.data?.company || null);
          Cookies.set(ACTIVE_COMPANY_COOKIE_NAME, companyId, { expires: 7 });
          if (redirectToHomePageAfterUpdate) {
            navigate(ProRoutes.Project);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.openToastWithError(err.message);
          setCompany(null);
          Cookies.remove(ACTIVE_COMPANY_COOKIE_NAME);
          navigate(ProRoutes.Home);
        })
        .finally(() => setIsLoadingCompany(false));
    },
    [navigate, refetch, toast],
  );

  const refreshCompany = useCallback(() => {
    if (company?.id) {
      refetch({
        id: company.id,
      })
        .then((res) => {
          setCompany(res.data.company);
        })
        .catch((err) => console.error(err));
    }
  }, [company, refetch]);

  const contextValue = useMemo(
    () => ({
      company,
      updateCompany,
      refreshCompany,
      isNavCollapsed,
      setIsNavCollapsed,
      isLoadingCompany,
    }),
    [
      company,
      updateCompany,
      refreshCompany,
      isNavCollapsed,
      setIsNavCollapsed,
      isLoadingCompany,
    ],
  );

  return (
    <ProContext.Provider value={contextValue}>
      <div className={clsx('relative', isLoadingCompany && 'opacity-50')}>
        {isLoadingCompany && (
          <div className="absolute w-full h-screen">
            <LoaderFullscreen />
          </div>
        )}
        {children}
      </div>
    </ProContext.Provider>
  );
}
