import { ProIroScreenDocument } from '../../../../../graphql/generated';
import { useProContext } from '../../../../../providers/ProContextProvider';

export const useIroPickerRefetchQueries = () => {
  const proContext = useProContext();
  return [
    {
      query: ProIroScreenDocument,
      variables: {
        id: proContext?.company?.referential?.id || '',
      },
    },
  ];
};
