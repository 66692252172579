import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import {
  DiagnosticStakeUpdateInput,
  MaturityLevel,
  StakeMaturitySelect_StakeFragment,
  useStakeMaturitySelect_StakeUpdateMutation,
} from '../../../graphql/generated';
import { NumberCircle } from '../../generic/NumberCircle';
import { getMaturityLabel } from '../../../services/DiagnosticService';
import { Loader } from '../../generic/Loader';
import { getMaturityLevelFromComputedValue } from '../../../services/ReferentialService';
import { AutoIcon, CheckIcon, ChevronDownIcon, NoEyeIcon } from '../../icons';

type MaturityListItem = {
  id: MaturityLevel | null;
  name: string;
  icon: ReactElement;
};

const noMaturityListItem: MaturityListItem = {
  id: null,
  name: getMaturityLabel(null),
  icon: <NoEyeIcon />,
};
const computedMaturityListItem: MaturityListItem = {
  id: MaturityLevel.Computed,
  name: getMaturityLabel(MaturityLevel.Computed),
  icon: <AutoIcon />,
};
const maturityListItems: MaturityListItem[] = [
  computedMaturityListItem,
  {
    id: MaturityLevel.High,
    name: getMaturityLabel(MaturityLevel.High),
    icon: <NumberCircle number={4} size={5} />,
  },
  {
    id: MaturityLevel.Medium,
    name: getMaturityLabel(MaturityLevel.Medium),
    icon: <NumberCircle number={3} size={5} />,
  },
  {
    id: MaturityLevel.Low,
    name: getMaturityLabel(MaturityLevel.Low),
    icon: <NumberCircle number={2} size={5} />,
  },
  {
    id: MaturityLevel.None,
    name: getMaturityLabel(MaturityLevel.None),
    icon: <NumberCircle number={1} size={5} />,
  },
  noMaturityListItem,
];

export default function StakeMaturitySelect({
  stake,
  disabled,
}: {
  stake: StakeMaturitySelect_StakeFragment;
  disabled: boolean;
}) {
  const [selected, setSelected] = useState(computedMaturityListItem);
  const [editDiagnosticStakes] = useStakeMaturitySelect_StakeUpdateMutation();
  const [isLoading, setIsLoading] = useState(false);

  const editDiagnosticStake = (input: DiagnosticStakeUpdateInput) => {
    setIsLoading(true);
    editDiagnosticStakes({
      variables: {
        input,
      },
    })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSelected(defineMaturityListItem(stake.maturityLevel));
  }, [stake.maturityLevel]);

  const chooseItem = (maturityListItem: MaturityListItem) => {
    setSelected(maturityListItem);
    editDiagnosticStake({
      id: stake.id,
      maturityLevel: maturityListItem.id,
    });
  };

  const maturityLevelFromComputedValue = getMaturityLevelFromComputedValue(
    stake.maturity?.score,
  );
  const iconForComputedValue = maturityListItems.find(
    (maturityListItem) =>
      maturityListItem.id === maturityLevelFromComputedValue,
  )?.icon;

  return (
    <Listbox
      value={selected}
      onChange={chooseItem}
      disabled={disabled || isLoading}
    >
      {({ open }) => (
        <div className="relative mt-1 w-40">
          <ListboxButton className="form-input-select">
            {selected.id === MaturityLevel.Computed ? (
              <span className="flex items-center w-full">
                {isLoading ? <Loader /> : iconForComputedValue}
                <span className="ml-3 flex items-center gap-1">
                  {selected.icon}
                  {selected.name}
                </span>
              </span>
            ) : (
              <span className="flex items-center w-full">
                {isLoading ? <Loader /> : selected.icon}
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {maturityListItems.map((maturityListItem) => (
                <ListboxOption
                  key={maturityListItem.id}
                  className={clsx(
                    'listbox-option relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                  )}
                  value={maturityListItem}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        {maturityListItem.icon}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate',
                          )}
                        >
                          {maturityListItem.name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={clsx(
                            'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

function defineMaturityListItem(
  currentPriority: MaturityLevel | null | undefined,
): MaturityListItem {
  if (currentPriority) {
    const found = maturityListItems.find(
      (priorityListItem) => priorityListItem.id === currentPriority,
    );
    if (found) {
      return found;
    }
  }

  return noMaturityListItem;
}
