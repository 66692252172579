import React, { useState } from 'react';
import { ProjectTemplates } from './ProjectTemplates';
import { PreviewCompanyTemplate } from './PreviewCompanyTemplate';
import { usePreviewCompanyTemplateQuery } from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';

export function ChooseProjectTemplateModal() {
  const [previewCompanyTemplateId, setPreviewCompanyTemplateId] = useState<
    string | null
  >(null);

  const companyTemplateQuery = usePreviewCompanyTemplateQuery({
    variables: {
      id: previewCompanyTemplateId || '',
    },
    skip: !previewCompanyTemplateId,
    fetchPolicy: 'network-only',
  });

  const refreshCompanyTemplate = (companyTemplateId: string | null) => {
    setPreviewCompanyTemplateId(companyTemplateId);
    if (companyTemplateId) {
      companyTemplateQuery
        .refetch({
          id: companyTemplateId,
        })
        .catch((err) => {
          console.error(err);
          setPreviewCompanyTemplateId(null);
        });
    }
  };

  if (companyTemplateQuery.loading) return <LoaderFullscreen />;

  return previewCompanyTemplateId &&
    companyTemplateQuery.data?.getCompanyTemplate ? (
    <PreviewCompanyTemplate
      template={companyTemplateQuery.data.getCompanyTemplate}
      surveys={companyTemplateQuery.data.surveys}
      maturityEvaluations={companyTemplateQuery.data.maturityEvaluations}
      setPreviewCompanyTemplateId={refreshCompanyTemplate}
    />
  ) : (
    <ProjectTemplates setPreviewCompanyTemplateId={refreshCompanyTemplate} />
  );
}
