import React, { useState } from 'react';
import { useProContext } from '../../../../providers/ProContextProvider';
import {
  RoadmapFieldsFragment,
  useRoadmapQuery,
} from '../../../../graphql/generated';
import {
  getLatestCompanyRoadmapSummary,
  isCoachUser,
} from '../../../../services/CompanyService';
import { RoadmapContent } from '../../../../components/publications/roadmap/Roadmap';
import { CreateRoadmap } from '../../../../components/pro/roadmap/CreateRoadmap';
import { EditRoadmap } from '../../../../components/pro/roadmap/EditRoadmap';
import {
  getFrDateFromISOString,
  getFrTimeFromISOString,
} from '../../../../services/DateService';
import { PublishRoadmapButton } from '../../../../components/pro/roadmap/PublishRoadmapButton';
import { useRightDrawer } from '../../../../components/layout/RightDrawer';
import { RoadmapSettingsDrawer } from '../../../../components/pro/roadmap/RoadmapSettingsDrawer';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { ProHomeScreen } from '../../home/ProHomeScreen';
import { EditIcon, XIcon } from '../../../../components/icons';

export function ProRoadmapScreen() {
  const [isEditMode, setIsEditMode] = useState(false);

  const proContext = useProContext();
  const roadmapSummary = getLatestCompanyRoadmapSummary(proContext?.company);

  const { data, loading } = useRoadmapQuery({
    variables: {
      id: roadmapSummary?.id || '',
    },
    skip: !roadmapSummary,
    fetchPolicy: 'cache-and-network',
  });

  if (loading || proContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  if (!proContext?.company) {
    return <ProHomeScreen />;
  }

  return (
    <>
      <PageTitle title="Feuille de route" />
      {data?.roadmap && (
        <ScreenTopBar
          roadmap={data.roadmap}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}

      {data?.roadmap ? (
        isEditMode ? (
          <EditRoadmap roadmap={data.roadmap} />
        ) : (
          <RoadmapContent roadmap={data.roadmap} />
        )
      ) : (
        <CreateRoadmap />
      )}
    </>
  );
}

function ScreenTopBar({
  roadmap,
  isEditMode,
  setIsEditMode,
}: {
  roadmap: RoadmapFieldsFragment;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const drawer = useRightDrawer();
  const proContext = useProContext();
  const currentUser = useCurrentUser();
  const openSettings = () => {
    drawer.openDrawerWithComponent(<RoadmapSettingsDrawer roadmap={roadmap} />);
  };

  const editMode = () => {
    setIsEditMode(true);
    proContext?.setIsNavCollapsed(true);
  };

  const closeEditMode = () => {
    setIsEditMode(false);
    proContext?.setIsNavCollapsed(false);
  };

  if (isEditMode) {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <button className="tertiary" onClick={closeEditMode}>
          <XIcon />
        </button>
        <h1 className="title-h5 grow text-center">
          Feuille de route ・ Édition
        </h1>
        <div className="flex items-center gap-4">
          <RoadmapPublicationDate roadmap={roadmap} />
          <PublishRoadmapButton roadmap={roadmap} />
          <button className="secondary purple" onClick={openSettings}>
            <span>Paramètres</span>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <h1 className="title-h5">Feuille de route</h1>
        <div className="flex items-center gap-4">
          <RoadmapPublicationDate roadmap={roadmap} />
          {isCoachUser(currentUser) && (
            <button className="primary purple" onClick={editMode}>
              <EditIcon className="w-4 h-4" />
              <span>Modifier</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

function RoadmapPublicationDate({
  roadmap,
}: {
  roadmap: RoadmapFieldsFragment;
}) {
  return (
    <div className="text-sm text-gray-500">
      {roadmap.publishedAt
        ? `Publiée le ${getFrDateFromISOString(
            roadmap.publishedAt,
          )} à ${getFrTimeFromISOString(roadmap.publishedAt)}`
        : 'Non publiée'}
    </div>
  );
}
