import {
  AuthRoles,
  CompanyFieldsFragment,
  ContributionType,
  ContributorFieldsFragment,
  useAuthQuery,
} from 'graphql/generated';
import React from 'react';
import { CopyTextToClipboardButton } from '../../generic/CopyTextToClipboardButton';
import { getInvitationLink } from '../../../services/CompanyService';
import { RemoveContributorButton } from './RemoveContributorButton';
import { IllustratedExplanationMessage } from '../../generic/IllustratedExplanationMessage';
import loginIllustration from '../../../assets/images/illustrations/login.png';
import { useProContext } from '../../../providers/ProContextProvider';
import { getFrDateFromISOString } from '../../../services/DateService';

export function CoachesTable({ company }: { company: CompanyFieldsFragment }) {
  const proContext = useProContext();
  const coachInvitationLink = getInvitationLink(company.id, AuthRoles.Coach);
  const companyCoaches =
    company.contributors?.filter(
      (contributor) => contributor.type === ContributionType.Coach,
    ) || [];

  return (
    <div className="w-full max-w-5xl space-y-8">
      <IllustratedExplanationMessage
        illustration={<img src={loginIllustration} alt="" />}
        title="Invitez vos collègues coachs"
        description="Utilisez ce lien pour inviter vos collaborateurs à administrer ce modèle de projet"
      >
        <CopyTextToClipboardButton
          buttonLabel="Copier le lien d'invitation"
          textToCopy={coachInvitationLink}
          className="purple"
        />
      </IllustratedExplanationMessage>

      <table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Dernière connexion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companyCoaches.map((coach, index) => (
            <CoachRow
              coach={coach}
              key={index}
              companyId={proContext?.company?.id || ''}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function CoachRow({
  coach,
  companyId,
}: {
  coach: ContributorFieldsFragment;
  companyId: string;
}) {
  const { data } = useAuthQuery({
    variables: {
      id: coach.user.authId || '',
    },
    skip: !coach.user.authId,
  });

  return (
    <tr>
      <td className="font-semibold">
        {coach.user.firstName} {coach.user.lastName}
      </td>
      <td>{data?.auth.email}</td>
      <td>
        {data?.auth.lastSignInDate
          ? getFrDateFromISOString(data.auth.lastSignInDate)
          : '-'}
      </td>
      <td>
        <RemoveContributorButton
          contributionId={coach.id}
          companyId={companyId}
        />
      </td>
    </tr>
  );
}
