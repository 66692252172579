import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { PageTitle } from '../../components/nav/PageTitle';
import { UserFieldsFragment } from '../../graphql/generated';
import { LoaderFullscreen } from '../../components/layout/Loader';
import { isProUser } from '../../services/CompanyService';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Checklist } from '../../components/task/Checklist';

export function HomeScreen() {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  // When the user is a pro, redirect to the pro dashboard
  useEffect(() => {
    if (isProUser(currentUser)) {
      navigate('/pro', {
        replace: true,
      });
    }
  });

  if (!currentUser) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="grow flex flex-col">
      <PageTitle title="Accueil" />
      {currentUser && <HomeGreeting currentUser={currentUser} />}
      {currentUser?.company?.checklist?.id && (
        <div className="bg-gray-50 w-full p-16 grow border-t border-gray-100">
          <div className="max-w-4xl">
            <Checklist checklistId={currentUser.company.checklist.id} />
          </div>
        </div>
      )}
    </div>
  );
}

function HomeGreeting({ currentUser }: { currentUser: UserFieldsFragment }) {
  const greeting = currentUser
    ? `Bonjour, ${currentUser.firstName}`
    : 'Bienvenue !';

  return (
    <div className="main-content py-8">
      <div className="flex place-content-center">
        {new Date().toLocaleDateString('fr', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        })}
      </div>
      <h1 className="flex place-content-center">{greeting}</h1>
    </div>
  );
}
