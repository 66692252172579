import React from 'react';
import { LoggedInOnlyGuard } from '../../guards/LoggedInOnlyGuard';
import { MainLayout } from '../../components/layout/MainLayout';
import { HomeScreen } from '../customer/HomeScreen';
import { Navigate } from 'react-router-dom';
import { SurveyListScreen } from '../customer/surveys/SurveyListScreen';
import { SurveyScreen } from '../customer/surveys/SurveyScreen';
import { MaturityScreen } from '../customer/publications/MaturityScreen';
import { PrioritizationMatrixScreen } from '../customer/publications/PrioritizationMatrixScreen';
import { MaterialityMatrixScreen } from '../customer/publications/MaterialityMatrixScreen';
import { StakesScreen } from '../customer/StakesScreen';
import { RoadmapScreen } from '../customer/publications/RoadmapScreen';
import { ActionScreen } from '../customer/ActionScreen';
import { AdminLayout } from '../../components/layout/AdminLayout';
import { AdminHomepageScreen } from '../admin/AdminHomepageScreen';
import { AdminUsersListScreen } from '../admin/users/list/AdminUserList';
import { AdminUserScreen } from '../admin/users/[id]/AdminUserScreen';
import { AdminCompaniesScreen } from '../admin/company/AdminCompaniesScreen';
import { useTranslation } from '@hooks/useTranslation';
import { ConsultationRawMaterialityScreen } from '../customer/consultationRawMateriality/ConsultationRawMaterialityScreen';

export const useEndUserRouteConfig = () => {
  const { t } = useTranslation();
  return [
    {
      path: '/',
      element: (
        <LoggedInOnlyGuard>
          <MainLayout />
        </LoggedInOnlyGuard>
      ),
      children: [
        {
          index: true,
          element: <HomeScreen />,
        },
        // End-users / CEO / diagnosed entity: diagnostic is defined through company...
        {
          path: '/diag',
          children: [
            { index: true, breadcrumb: null, element: <Navigate to="/" /> },
            {
              path: '/diag/survey',
              children: [
                {
                  index: true,
                  breadcrumb: null,
                  element: <SurveyListScreen />,
                },
                {
                  path: '/diag/survey/:surveyId',
                  breadcrumb: null,
                  children: [
                    {
                      index: true,
                      breadcrumb: null,
                      element: <SurveyScreen />,
                    },
                  ],
                },
              ],
            },
            {
              path: '/diag/rawMateriality/:consultationId',
              breadcrumb: null,
              children: [
                {
                  index: true,
                  breadcrumb: null,
                  element: <ConsultationRawMaterialityScreen />,
                },
              ],
            },
            {
              path: '/diag/publications',
              breadcrumb: null,
              children: [
                {
                  path: '/diag/publications/maturity',
                  breadcrumb: 'Score de maturité',
                  element: <MaturityScreen />,
                },
                {
                  path: '/diag/publications/prioritization-matrix',
                  breadcrumb: 'Matrice de priorisation',
                  element: <PrioritizationMatrixScreen />,
                },
                {
                  path: '/diag/publications/materiality-matrix',
                  breadcrumb: 'Matrice de matérialité',
                  element: <MaterialityMatrixScreen />,
                },
              ],
            },
            {
              path: '/diag/stakes',
              breadcrumb: "Référentiel d'enjeux",
              element: <StakesScreen />,
            },
            {
              path: '/diag/roadmap',
              children: [
                {
                  index: true,
                  breadcrumb: 'Feuille de route',
                  element: <RoadmapScreen />,
                },
                {
                  path: '/diag/roadmap/action/:actionId',
                  breadcrumb: null,
                  element: <ActionScreen />,
                },
              ],
            },
          ],
        },
        // Administration pages
        {
          path: '/admin',
          breadcrumb: t('admin:breadcrumb.admin'),
          element: <AdminLayout />,
          children: [
            {
              index: true,
              element: <AdminHomepageScreen />,
            },
            {
              path: '/admin/users',
              breadcrumb: t('admin:breadcrumb.users.list'),
              element: <AdminUsersListScreen />,
            },
            {
              path: '/admin/users/:id',
              breadcrumb: t('admin:breadcrumb.users.form'),
              element: <AdminUserScreen />,
            },
            {
              path: '/admin/companies',
              breadcrumb: t('admin:breadcrumb.companies'),
              element: <AdminCompaniesScreen />,
            },
          ],
        },
      ],
    },
  ];
};
