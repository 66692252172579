import React, { useState } from 'react';
import { useProContext } from '../../../../../providers/ProContextProvider';
import {
  SurveyAnswerFieldsFragment,
  SurveyFieldsFragment,
  SurveyQuestionType,
  useReferentialQuery,
  useSurveyQuestionsAnalysisQuery,
  useSurveysFullQuery,
} from '../../../../../graphql/generated';
import { SurveyQuestionIcon } from '../../../../survey/SurveyQuestionIcon';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { LoaderFullscreen } from '../../../../layout/Loader';
import {
  getTopStakesWithSimpleAlgorithm,
  StakeRankingDetail,
} from '../../../survey/questionsDataviz/StakesRankingDataviz';
import { ChevronDownIcon } from '../../../../icons';
import { StakesRankingChart } from '../../../survey/questionsDataviz/StakesRankingChart';

export function UtopiesStakesRankings() {
  const proContext = useProContext();

  const [selectedQuestionIds, setSelectedQuestionIds] = React.useState<
    string[]
  >([]);

  const surveysDocumentQuery = useSurveysFullQuery({
    variables: {
      companyId: proContext?.company?.id || '',
    },
    skip: !proContext?.company?.id,
    fetchPolicy: 'network-only',
  });
  const surveys: SurveyFieldsFragment[] =
    surveysDocumentQuery.data?.surveys || [];

  const surveysContainingStakeRankingQuestions = surveys.filter((survey) =>
    (survey.questions || []).some(
      (question) => question.type === SurveyQuestionType.StakesRanking,
    ),
  );

  return (
    <div className="main-content flex items-stretch gap-4">
      <div className="w-1/3 flex flex-col gap-4">
        {surveysContainingStakeRankingQuestions.map((survey) => (
          <div
            key={`utopiesStakesRanking-${survey.id}`}
            className="flex flex-col gap-2"
          >
            <div className="text-gray-900 font-semibold">{survey.name}</div>
            {(survey.questions || [])
              .filter((question) => question.type === 'stakes_ranking')

              .map((question) => (
                <div
                  key={`utopiesStakesRanking-${survey.id}-${question.id}`}
                  className="rounded-lg border-2 border-gray-100 p-2 text-sm flex items-center gap-2 shadow-sm text-gray-500 font-semibold hover:border-gray-900 hover:text-gray-900 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    className="form-input-checkbox"
                    checked={selectedQuestionIds.includes(question.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedQuestionIds([
                          ...selectedQuestionIds,
                          question.id,
                        ]);
                      } else {
                        setSelectedQuestionIds(
                          selectedQuestionIds.filter(
                            (id) => id !== question.id,
                          ),
                        );
                      }
                    }}
                  />
                  <SurveyQuestionIcon questionType={question.type} />
                  <div>{question.title}</div>
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="w-2/3 grow">
        {selectedQuestionIds.length > 0 ? (
          <StakesRanking questionIds={selectedQuestionIds} />
        ) : (
          <MessageBox type={MessageBoxType.Info}>
            Sélectionnez une question pour afficher les résultats
          </MessageBox>
        )}
      </div>
    </div>
  );
}

function StakesRanking({ questionIds }: { questionIds: string[] }) {
  const surveyQuestionsAnalysisQuery = useSurveyQuestionsAnalysisQuery({
    variables: {
      ids: questionIds,
    },
    fetchPolicy: 'cache-and-network',
  });

  // If at least one query is loading, show a loader
  if (surveyQuestionsAnalysisQuery.loading) {
    return <LoaderFullscreen />;
  }

  // Consolidate all answers from all questions
  const answers =
    surveyQuestionsAnalysisQuery.data?.surveyQuestions.flatMap(
      (question) => question.answers || [],
    ) || [];

  // Compute top: max value of all tops
  const top = Math.max(
    ...(surveyQuestionsAnalysisQuery.data?.surveyQuestions.map(
      (question) => question.valueMax || 0,
    ) || []),
  );

  return <UtopiesStakesRankingChart answers={answers} top={top} />;
}

function UtopiesStakesRankingChart({
  answers,
  top,
}: {
  answers: SurveyAnswerFieldsFragment[];
  top: number;
}) {
  const [displayDetails, setDisplayDetails] = useState(false);

  const proContext = useProContext();
  const companyReferentialDocumentQuery = useReferentialQuery({
    variables: { id: proContext?.company?.referential?.id || '' },
    skip: !proContext?.company?.id,
    fetchPolicy: 'cache-and-network',
  });

  const referential = companyReferentialDocumentQuery.data?.referential;
  const stakes = referential?.pillars.flatMap((pillar) => pillar.stakes) || [];

  if (answers.length === 0) {
    return <MessageBox type={MessageBoxType.Info}>Aucune réponse</MessageBox>;
  }

  const topStakes = getTopStakesWithSimpleAlgorithm(stakes, answers, top);

  return (
    <div className="p-4">
      <StakesRankingChart
        rankedStakes={topStakes}
        title="Classement consolidé des enjeux"
      />
      {displayDetails ? (
        <div className="space-y-2">
          <div
            className="flex items-center justify-center gap-2 text-gray-500 font-bold text-xs cursor-pointer"
            onClick={() => setDisplayDetails(false)}
          >
            <span>Cacher le détail</span>
            <ChevronDownIcon className="w-3 h-3 rotate-180" />
          </div>
          <h6>Détail des résultats</h6>
          <div className="space-y-4 divide-y divide-gray-100">
            {stakes.map((stake) => (
              <StakeRankingDetail
                key={`utopiesStakesRanking-${stake.id}`}
                top={top}
                stake={stake}
                answers={answers || []}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          className="flex items-center justify-center gap-2 text-gray-500 font-bold text-xs cursor-pointer"
          onClick={() => setDisplayDetails(true)}
        >
          <span>Voir le détail</span>
          <ChevronDownIcon className="w-3 h-3" />
        </div>
      )}
    </div>
  );
}
