import React, { useState } from 'react';
import { useProContext } from '../../../providers/ProContextProvider';
import { CompanyType, FeatureNames } from '../../../graphql/generated';
import { PageTitle } from '../../../components/nav/PageTitle';
import { CompanyLogo } from '../../../components/pro/project/CompanyLogo';
import Tabs, {
  TabItem,
  TabItemManager,
  TabsStyles,
} from '../../../components/nav/Tabs';
import { Checklist } from '../../../components/task/Checklist';
import { Users } from '../../../components/pro/project/Users';
import { ProProjectTemplateScreen } from './ProProjectTemplateScreen';
import { IllustratedExplanationMessage } from '../../../components/generic/IllustratedExplanationMessage';
import loginIllustration from '../../../assets/images/illustrations/login.png';
import { CreateChecklistButton } from '../../../components/task/CreateChecklistButton';
import { ProHomeScreen } from '../home/ProHomeScreen';
import { CreateNotebookButton } from '../../../components/pro/notebook/CreateNotebookButton';
import { Notebook } from '../../../components/pro/notebook/Notebook';
import { CompanyUpdateInfo } from '../../../components/pro/project/CompanyUpdateInfo';
import { CompanySettings } from '../../../components/pro/project/CompanySettings';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';
import { useTranslation } from '@hooks/useTranslation';
import { useParams } from 'react-router-dom';

export function ProProjectScreen() {
  const proContext = useProContext();

  if (proContext && !proContext.company) {
    return <ProHomeScreen />;
  }

  if (proContext?.company?.type === CompanyType.Template) {
    return <ProProjectTemplateScreen />;
  } else {
    return <ProProject />;
  }
}

export const enum PROJECT_HOME_TABS {
  METHODOLOGY = 'METHODOLOGY',
  NOTEBOOK = 'NOTEBOOK',
  USERS = 'USERS',
  COMPANY = 'COMPANY',
  SETTINGS = 'SETTINGS',
}

function ProProject() {
  const { tab } = useParams();
  const { t } = useTranslation();
  const tabs: TabItem[] = [
    {
      key: PROJECT_HOME_TABS.METHODOLOGY,
      name: t('project.tabs.methodology'),
    },
    {
      key: PROJECT_HOME_TABS.NOTEBOOK,
      name: t('project.tabs.notes'),
    },
    {
      key: PROJECT_HOME_TABS.COMPANY,
      name: t('project.tabs.company'),
    },
    { key: PROJECT_HOME_TABS.USERS, name: t('project.tabs.users') },
  ];

  const isSettingsEnabled = useFeatureEnabled(FeatureNames.CompanySettings);
  if (isSettingsEnabled) {
    tabs.push({
      key: PROJECT_HOME_TABS.SETTINGS,
      name: t('project.tabs.settings'),
    });
  }

  const [currentTab, setCurrentTab] = useState(
    tabs.find((t) => t.key === tab) || tabs[0],
  );
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <>
      <PageTitle title="Gestion du projet" />
      <div className="py-12 px-16">
        <ProProjectHeader />
      </div>
      <div className="px-16 border-b border-gray-100 w-full">
        <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
      </div>
      <ProProjectInnerTab currentTab={currentTab} />
    </>
  );
}

function ProProjectInnerTab({ currentTab }: { currentTab: TabItem }) {
  const proContext = useProContext();
  const isSettingsEnabled = useFeatureEnabled(FeatureNames.CompanySettings);
  if (currentTab.key === PROJECT_HOME_TABS.METHODOLOGY) {
    if (!proContext?.company?.checklist?.id) {
      return (
        <div className="grow w-full py-8 px-16 max-w-6xl">
          <IllustratedExplanationMessage
            illustration={
              <img className="w-1/2" src={loginIllustration} alt="" />
            }
            title="Créez une liste de tâches"
            description="Menez votre projet à terme en créant une liste de tâches !"
          >
            <CreateChecklistButton companyId={proContext?.company?.id || ''} />
          </IllustratedExplanationMessage>
        </div>
      );
    }

    return (
      <div className="grow bg-gray-50 w-full py-8 px-16">
        {proContext?.company?.checklist?.id && (
          <div className="max-w-4xl">
            <Checklist checklistId={proContext.company.checklist.id} />
          </div>
        )}
      </div>
    );
  }
  if (proContext?.company && currentTab.key === PROJECT_HOME_TABS.USERS) {
    return (
      <div className="w-full py-8 px-16 max-w-7xl">
        <Users company={proContext?.company} />
      </div>
    );
  }
  if (proContext?.company && currentTab.key === PROJECT_HOME_TABS.NOTEBOOK) {
    if (!proContext?.company?.notebook?.id) {
      return (
        <div className="grow w-full py-8 px-16 max-w-6xl">
          <IllustratedExplanationMessage
            illustration={
              <img className="w-1/2" src={loginIllustration} alt="" />
            }
            title="Créez des notes pour ce projet"
            description="Ne perdez plus d'information en créant des notes !"
          >
            <CreateNotebookButton companyId={proContext?.company?.id || ''} />
          </IllustratedExplanationMessage>
        </div>
      );
    }

    return (
      <div className="grow bg-gray-50 w-full py-8 px-16">
        {proContext?.company?.notebook?.id && (
          <div className="max-w-4xl">
            <Notebook notebookId={proContext.company.notebook.id} />
          </div>
        )}
      </div>
    );
  }
  if (proContext?.company && currentTab.key === PROJECT_HOME_TABS.COMPANY) {
    return (
      <div className="w-full py-8 px-16 max-w-5xl">
        <CompanyUpdateInfo companyId={proContext?.company.id} />
      </div>
    );
  }
  if (
    isSettingsEnabled &&
    proContext?.company &&
    currentTab.key === PROJECT_HOME_TABS.SETTINGS
  ) {
    return (
      <div className="w-full py-8 px-16 max-w-5xl">
        <CompanySettings companyId={proContext?.company.id} />
      </div>
    );
  }
  return null;
}

function ProProjectHeader() {
  const proContext = useProContext();
  return (
    <div className="flex items-center gap-4 justify-start">
      {proContext?.company && (
        <CompanyLogo companyId={proContext.company.id} size={'big'} />
      )}
      <div>
        <div className="text-gray-500 first-letter:capitalize text-sm">
          {new Date().toLocaleDateString('fr', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })}{' '}
          · Tableau de bord {proContext?.company?.name}
        </div>
        {
          <h1 className="text-5xl">
            Gestion du projet {proContext?.company?.name}
          </h1>
        }
      </div>
    </div>
  );
}
