import React from 'react';
import { useRoutes } from 'react-router-dom';

import { useCurrentAuth } from '../providers/CurrentAuthProvider';
import logo from '../assets/images/logo.svg';

import { useCurrentUser } from '../providers/CurrentUserProvider';
import { useRouteConfig } from './routeConfigs/useRouteConfig';

export const enum ProRoutes {
  Home = '/pro',
  Settings = '/pro/settings',
  SettingsCoach = '/pro/settings/coaches',
  SettingsCompany = '/pro/settings/company',
  SettingsTemplates = '/pro/settings/templates',
  Stakes = '/pro/stakes',
  Iro = '/pro/iro',
  IroRating = '/pro/iro/:pillarId/:stakeId',
  StakeholderMapping = '/pro/stakeholder-mapping',
  Project = '/pro/project',
  ProjectTab = '/pro/project/:tab',
  ProjectUsers = '/pro/project/USERS',
  ProjectCompany = '/pro/project/company',
  Publication = '/pro/publications',
  PublicationMaturity = '/pro/publications/maturity',
  PublicationPrioritizationMatrix = '/pro/publications/prioritization-matrix',
  PublicationMaterialityMatrix = '/pro/publications/materiality-matrix',
  PublicationDoubleMaterialityMatrix = '/pro/publications/double-materiality-matrix',
  Roadmap = '/pro/roadmap',
  RoadmapAction = '/pro/roadmap/action/:actionId',
  RoadmapActionEdit = '/pro/roadmap/action/:actionId/edit',
  Survey = '/pro/survey',
  SurveyItem = '/pro/survey/:surveyId',
  SurveyResults = '/pro/survey/results',
  SurveyTab = '/pro/survey/:surveyId/:tab',
  MaturityEvaluations = '/pro/maturity',
  MaturityEvaluationImport = '/pro/maturity/import',
  MaturityEvaluationItem = '/pro/maturity/:surveyId',
  MaturityEvaluationAssign = '/pro/maturity/:surveyId/assign',
  ConsultationRawMaterialities = '/pro/consultation/raw-materialities/:consultationId',
  ActionTemplateEdit = '/pro/settings/templates/action/:actionId/edit',
}

export function ScreensRouter() {
  const currentAuth = useCurrentAuth();
  const currentUser = useCurrentUser();

  const routes = useRoutes(useRouteConfig());

  return typeof currentAuth === 'undefined' &&
    typeof currentUser === 'undefined' ? (
    <div className="flex h-screen w-screen">
      <img className="m-auto w-24 h-24" src={logo} alt="" />
    </div>
  ) : (
    routes
  );
}

export function getActionLink(isCoach: boolean, actionId: string): string {
  if (isCoach) {
    if (actionId) {
      return `/pro/roadmap/action/${actionId}`;
    } else {
      return `/pro/roadmap`;
    }
  } else {
    if (actionId) {
      return `/diag/roadmap/action/${actionId}`;
    } else {
      return `/diag/roadmap`;
    }
  }
}
