import { ActionFieldsFragment } from '../../../graphql/generated';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useProContext } from '../../../providers/ProContextProvider';
import { EditIcon } from '../../icons';

export function UpdateActionButton({
  action,
  displayLabel = false,
}: {
  action: ActionFieldsFragment;
  displayLabel?: boolean;
}) {
  const proContext = useProContext();
  return (
    <NavLink
      to={`/pro/roadmap/action/${action.id}/edit`}
      className="button primary purple"
      onClick={() => proContext?.setIsNavCollapsed(true)}
    >
      <EditIcon />
      {displayLabel && 'Modifier'}
    </NavLink>
  );
}
