import {
  CompanyType,
  DeleteCompanyButton_CompanyFragment,
  useDeleteCompanyButtonMutation,
} from 'graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../layout/Toast';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { useModal } from '../../layout/Modal';
import { Loader } from '../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';

export function DeleteCompanyButton({
  company,
  callback,
}: {
  company: DeleteCompanyButton_CompanyFragment;
  callback?: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();

  const [deleteCompanyMutation] = useDeleteCompanyButtonMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteCompanyMutation({
      variables: {
        companyId: company.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('project.delete.success'));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  // Title is dependent on the company type (company or project or template)
  const title =
    company.type === CompanyType.Template
      ? t('project.delete.cta_template')
      : company.type === CompanyType.Pro
        ? t('project.delete.cta_pro')
        : company.type === CompanyType.EndUser
          ? t('project.delete.cta_project')
          : t('global:delete');

  return (
    <button
      className="primary danger small"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.delete.content')}
            callbackIfConfirmed={deleteCompany}
          />,
          title,
        )
      }
    >
      {isDeleting && <Loader />}
      <div>{title}</div>
    </button>
  );
}
